/*
Author       : Dreamguys
Template Name: Dreamchat - Bootstrap Admin Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Table
3.Datatable
4. Helper Class
5. Bootstrap Classes
6. Select2
7. Nav Tabs
8. Components
9. Avatar
10. Header
11. Sidebar
12. Content
13. Modal
14. Notifications
15. Dashboard
16. Language
17. Sms Setting
18. Theme Setting
19. Profile-settings
20. Personal Information
21. Website Settings
22. System Setting
23. Notification Setting
24. Localization
25. Appearance
26. Responsive

========================================*/

/*-----------------
	1. General
-----------------------*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
	src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url(../../fonts/MaterialIcons-Regular.woff2) format('woff2'),
	url(../../fonts/MaterialIcons-Regular.woff) format('woff'),
	url(../../fonts/MaterialIcons-Regular.ttf) format('truetype');
}
html {
	height: 100%;
}
body {
	background-color: #FAFAFA;
	color: #616161;
	font-family: 'Inter', sans-serif;
	font-size: 15px;
	height: 100%;
	line-height: 1.5;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Inter', sans-serif;
	margin-top: 0;
	color: #0A0A0A;
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
ul {
	padding: 0;
	margin: 0;
}
ul li {
	list-style: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
.form-control {
    border: 1px solid #ddd;
    box-shadow: none;
    color: #616161;
    font-size: 15px;
    height: 40px;
}
.form-control:focus {
	border-color: #680a83;
	box-shadow: none;
	outline: 0 none;
}
.form-control.form-control-sm {
	height: calc(1.5em + .5rem + 2px);
}

.form-control.form-control-lg {
	height: calc(1.5em + 1rem + 2px);
}
a {
	color: #009ce7;
	text-decoration: none;
}
input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
input, input:focus,
button, button:focus {
	outline: none;
}
input[type="file"] {
    height: auto;
    min-height: calc(1.5em + .75rem + 2px);
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
textarea.form-control {
	resize: vertical;
	height: auto;
}
.navbar-nav > li {
	float: left;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.form-group {
	margin-bottom: 1.25rem;
}
.input-group .form-control {
	height: 40px;
}
.input-group .input-height {
	height: auto;
}
.input-group-append, .input-group-prepend {
    display: -ms-flexbox;
    display: flex;
}
.input-group-append .btn {
	border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-color: rgba(0, 0, 0, 0.1);
}
.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}
.font-weight-600 {
	font-weight: 600;
}

/*-----------------
	2. Table
-----------------------*/

.table {
	color: #333;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
	border: 1px solid #F5F5F5;
}
.table>:not(:first-child) {
    border-top: 1px solid #e2e5e8;
}
.table>:not(caption)>*>* {
	box-shadow: unset;
	padding: 0.75rem;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}
.table.dataTable {
	border-collapse: collapse !important;
}
div.dataTables_wrapper div.dataTables_length select {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2 a {
	color: #0A0A0A;
	font-size: 16px;
}
table.table td h2 a:hover {
	color: #680A83;
}
table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead tr th {
	font-weight: 600;
	border: 0;
	background: #F5F5F5;
	white-space: nowrap;
	color: #0A0A0A;
}
.table tbody {
	white-space: nowrap;
}
.table tbody tr {
	border-bottom: 1px solid #F5F5F5;
	vertical-align: middle;
}
.table.table-center td,
.table.table-center th {
	vertical-align: middle;
}
.table-hover tbody tr:hover {
	background-color: #e5ebfd;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body {
    padding: 0;
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .card-body .table tr td:last-child,
.card-table .card-body .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
}
.checkboxs {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
}
.checkboxs input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkboxs input ~ .checkmarks {
	background: #D9D9D9;
	border-radius: 6px;
}
.checkboxs .checkmarks {
    height: 20px;
    width: 20px;
    position: absolute;
    top: -3px;
    left: 0;
}
.checkboxs input:checked ~ .checkmarks:after {
    display: block;
}
.checkboxs input:checked ~ .checkmarks {
    background-color: #680A83;
}
.checkboxs .checkmarks:after {
    left: 8px;
    top: 5px;
    width: 5px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}
.checkboxs .checkmarks:after {
    content: "";
    position: absolute;
    display: none;
}
.table-dropdown .btn-action-icon {
	color: #9E9E9E;
	font-size: 20px;
}
.count-details {
	background: #FF000A;
	padding: 5px;
	display: inline-block;
	border-radius: 10px;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
}

/*-----------------
	3.Datatable
-----------------------*/

div.dataTables_wrapper div.dataTables_info {
    padding-top: 0;
    white-space: nowrap;
    font-size: 15px;
	color: #757575;
	margin-top: 20px !important;
}
.dataTables_length {
    display: none;
}
.dataTables_paginate {
    margin-top: 20px !important;
    width: 50%;
    float: right;
}
.card-table div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:first-child {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.card-table div.dataTables_wrapper div.dataTables_paginate ul.pagination {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.card-table div.dataTables_wrapper div.dataTables_paginate ul.pagination li {
	margin-right: 10px;
}
.card-table div.dataTables_wrapper div.dataTables_paginate ul.pagination li.next {
	margin-right: 0px;
}
.card-table .paginate_button.page-item.previous a,
.card-table .paginate_button.page-item.next a {
	background: #fff;
	color: #616161;
	font-size: 14px;
}
.card-table .page-item:first-child .page-link,
.card-table .page-item:last-child .page-link {
	border-radius: 50px;
}
.card-table .page-item .page-link {
	border-radius: 50px;
	width: 29px;
	height: 29px;
}
.card-table .page-item .page-link {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: #616161;
}
.card-table .page-item.active .page-link {
	color: #fff;
}
.card-table .page-item .page-link:hover {
	color: #616161;
}
.card-table .page-item.active .page-link:hover {
	color: #fff;
}

/*-----------------
	4. Helper Class
-----------------------*/

.p-20 {
	padding: 20px !important;
}
.p-t-0 {
	padding-top: 0 !important;
}
.m-0 {
	margin: 0 !important;
}
.m-r-5 {
	margin-right: 5px !important;
}
.m-r-10 {
	margin-right: 10px !important;
}
.m-l-5 {
	margin-left: 5px !important;
}
.m-l-15 {
	margin-left: 15px !important;
}
.m-t-5 {
	margin-top: 5px !important;
}
.m-t-0 {
	margin-top: 0 !important;
}
.m-t-10 {
	margin-top: 10px !important;
}
.m-t-15 {
	margin-top: 15px !important;
}
.m-t-20 {
	margin-top: 20px !important;
}
.m-t-30 {
	margin-top: 30px !important;
}
.m-t-50 {
	margin-top: 50px !important;
}
.m-b-5 {
	margin-bottom: 5px !important;
}
.m-b-10 {
	margin-bottom: 10px !important;
}
.m-b-15 {
	margin-bottom: 15px !important;
}
.m-b-20 {
	margin-bottom: 20px !important;
}
.m-b-30 {
	margin-bottom: 30px !important;
}
.block {
	display: block !important;
}
.cal-icon {
	position: relative;
	width: 100%;
}
.all-center {
	text-align: center;
	align-items: center;
	justify-content: center;
}

/*-----------------
	5. Bootstrap Classes
-----------------------*/

.btn-block {
	width: 100%;
}
.btn-link {
	text-decoration: none;
}
.btn-link:hover {
	text-decoration: underline;
}
.rounded-circle {
    border-radius: 50%!important;
}
.breadcrumb-item.active {
    color: #7a6c7d;
}
.btn.focus, .btn:focus {
    box-shadow: unset;
}
.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #333;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary,
.badge-primary {
	background-color: #680A83 !important;
}
a.bg-primary:focus, 
a.bg-primary:hover, 
button.bg-primary:focus, 
button.bg-primary:hover {
    background-color: #0044ff !important;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}
.bg-success,
.badge-success {
	background-color: #7bb13c !important;
}
a.bg-success:focus, 
a.bg-success:hover, 
button.bg-success:focus, 
button.bg-success:hover {
    background-color: #699834 !important;
}
.bg-info,
.badge-info {
	background-color: #009efb !important;
}
a.bg-info:focus, 
a.bg-info:hover, 
button.bg-info:focus, 
button.bg-info:hover {
    background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}
a.bg-warning:focus, 
a.bg-warning:hover, 
button.bg-warning:focus, 
button.bg-warning:hover {
    background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #e84646 !important;
}
a.bg-danger:focus, 
a.bg-danger:hover, 
button.bg-danger:focus, 
button.bg-danger:hover {
    background-color: #e63333 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #2962ff !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #699834 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #e84646 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
    color: #757575 !important;
}
.btn-primary {
	background-color: #680A83;
	border: 1px solid #680A83;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #680A83;
	border: 1px solid #680A83;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #680A83;
	border: 1px solid #680A83;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #680A83;
	border-color: #680A83;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled), 
.btn-primary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: #2962ff;
    border-color: #2962ff;
    color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled), 
.btn-secondary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #7bb13c;
	border: 1px solid #7bb13c
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #699834;
	border: 1px solid #699834;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #699834;
	border: 1px solid #699834
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #699834;
	border-color: #699834;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled), 
.btn-success:active:focus:not(:disabled):not(.disabled), 
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled, .btn-success:disabled {
    background-color: #7bb13c;
    border-color: #7bb13c;
    color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled), 
.btn-info:active:focus:not(:disabled):not(.disabled), 
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled, .btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled), 
.btn-warning:active:focus:not(:disabled):not(.disabled), 
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff;
}
.btn-danger {
	background-color: #e84646;
	border: 1px solid #e84646;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #e63333;
	border: 1px solid #e63333;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #e63333;
	border-color: #e63333;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled), 
.btn-danger:active:focus:not(:disabled):not(.disabled), 
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled, .btn-danger:disabled {
    background-color: #f62d51;
    border-color: #f62d51;
    color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled), 
.btn-light:active:focus:not(:disabled):not(.disabled), 
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled), 
.btn-dark:active:focus:not(:disabled):not(.disabled), 
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-outline-primary {
	color: #2962ff;
	border-color: #2962ff;
}
.btn-outline-primary:hover {
	background-color: #2962ff;
	border-color: #2962ff;
}
.btn-outline-primary:focus, 
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled, 
.btn-outline-primary:disabled {
	color: #2962ff;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #2962ff;
	border-color: #2962ff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: #7bb13c;
	border-color: #7bb13c;
}
.btn-outline-success:hover {
	background-color: #7bb13c;
	border-color: #7bb13c;
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #7bb13c;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, 
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #7bb13c;
	border-color: #7bb13c;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, 
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	background-color: transparent;
	color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active, 
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, 
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, 
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, 
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #e84646;
	border-color: #e84646;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #e84646;
	border-color: #e84646;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	background-color: transparent;
	color: #e84646;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, 
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #e84646;
	border-color: #e84646;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, 
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ababab;
}
.card-table .pagination > .active > a, 
.card-table .pagination > .active > a:focus,
.card-table .pagination > .active > a:hover,
.card-table .pagination > .active > span,
.card-table .pagination > .active > span:focus,
.card-table .pagination > .active > span:hover {
	background-color: #680a83;
	border-color: #680a83;
}
.card-table .pagination > li > a, 
.card-table .pagination > li > span {
	color: #fff;
}
.card-table .page-link:hover {
	color: #fff;
}
.card-table .page-link:focus {
	box-shadow: unset;
}
.card-table .page-item.active .page-link {
	background-color: #680A83;
	border-color: #680A83;
	width: 29px;
    height: 29px;
    padding: 0;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.comp-section .pagination {
	margin-bottom: 15px;
}
.dropdown-menu {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	transform-origin: left top 0;
	box-shadow: inherit;
	background-color: #fff;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #680A83;
	color: #fff;
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.dropdown-menu {
	font-size: 14px;
}
.card {
    border: 1px solid #EDEDED;
    border-radius: 10px;
    box-shadow: 0px 1.5px 2px 0px #1018281A;
    margin-bottom: 24px;
	padding: 20px;
	width: 100%;
}
.card.active {
	background-color: #420BA1;
}
.card .card-body {
	padding: 0;
}
.card-header {
	border-bottom: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;margin-bottom: 20px;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
	padding: 0;
	margin-bottom: 20px;
}
.card .card-header .card-title {
    margin-bottom: 0;
	font-weight: 600;
	color: #333843;
	font-size: 20px;
}
.card .card-body .card-title {
	font-size: 20px;
}
.modal-footer.text-left {
	text-align: left;
}
.modal-footer.text-center {
	text-align: center;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #2962ff;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today:before{
	border-bottom-color:#2962ff;
}
.bg-info-light{
	background-color:rgba(2, 182, 179,0.12) !important;
	color:#1db9aa !important;
}
.bg-primary-light{
	background-color:rgba(17, 148, 247,0.12) !important;
	color:#2196f3 !important;
}
.bg-danger-light{
	background-color:#FFE8E9 !important;
	color:#e63c3c !important;
	border: 1px solid #E2B6B6;
}
.bg-danger-light:hover{
	border: 1px solid #E2B6B6;
	background-color:#E2B6B6 !important;
}
.bg-warning-light{
	background-color:rgba(255, 152, 0,0.12) !important;
	color:#f39c12 !important;
}
.bg-success-light{
	background-color:#E7FAF2 !important;
	color:#26af48 !important;
	border: 1px solid #B5D0C3;
}
.bg-success-light:hover {
	border: 1px solid #B5D0C3;
	background-color:#B5D0C3 !important;
}
.bg-purple-light{
	background-color:rgba(197, 128, 255,0.12) !important;
	color:#c580ff !important;
}
.bg-default-light{
	background-color:#F5F5F5 !important;
	color:#283447 !important;
}
.bg-gray-light{
	background-color:#F5F5F5 !important;
	color:#616161 !important;
	border: 1px solid #C2C2C2;
}
.bg-gray-light:hover{
	border: 1px solid #C2C2C2;
	background-color:#C2C2C2 !important;
}
.alert-dismissible {
    padding-right: 1rem;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    background: transparent;
    border: transparent;
}
.close:hover {
	opacity: 1;
}
.actions { 
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
}
.actions a {
	width: 30px;
	height: 30px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}

/*-----------------
	6. Select2
-----------------------*/

.select2-container .select2-selection--single {
	border: 1px solid #ddd;
	height: 40px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 38px;
	right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ddd transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ddd;
	border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #333;
	font-size: 15px;
	font-weight: normal;
	line-height: 38px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #680a83;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}
.select-pop .select2-container {
    z-index: 0;
}

/*-----------------
	7. Nav Tabs
-----------------------*/

.nav-tabs {
	border-bottom: 1px solid #e6e6e6;
}
.card-header-tabs {
    border-bottom: 0;
}
.nav-tabs > li > a {
	margin-right: 0;
	color: #888;
	border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	border-color: transparent;
	color: #333;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #333;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
	background-color: #2962ff;
	border-color: #2962ff;
	color: #fff;
}
.tab-content {
	padding-top: 20px;
}
.nav-tabs .nav-link {
	border-radius: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: transparent;
	color: #333;
}
.nav-tabs.nav-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #333;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
	background-color: #2962ff;
	border-color: #2962ff;
	color: #fff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
	border-radius: 50px;
}
.nav-tabs-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li+li > a {
	margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
	border-top-color: #680a83;
}
.nav-tabs.nav-tabs-bottom > li {
	margin-bottom: -1px;
}
.nav-tabs.nav-tabs-bottom > li > a.active, 
.nav-tabs.nav-tabs-bottom > li > a.active:hover, 
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 2px;
	border-color: transparent;
	border-bottom-color: #680a83;
	background-color: transparent;
	transition: none 0s ease 0s; 
	-moz-transition: none 0s ease 0s; 
	-o-transition: none 0s ease 0s; 
	-ms-transition: none 0s ease 0s; 
	-webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
	background-color: #f4eef6;
	border: 0;
}
.nav-tabs.nav-tabs-solid > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
	background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
	background-color: #f5f5f5;
	border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}

/*-----------------
	8. Components
-----------------------*/

.section-header {
    margin-bottom: 1.875rem;
}
.section-header .section-title {
    color: #333;
	font-size: 20px;
}
.line {
    background-color: #680a83;
    height: 2px;
    margin: 0;
    width: 60px;
}
.comp-buttons .btn {
	margin-bottom: 5px;
}
.pagination-box .pagination {
	margin-top: 0;
}
.comp-dropdowns .btn-group {
	margin-bottom: 5px;
}
.progress-example .progress {
	margin-bottom: 1.5rem;
}
.progress-xs {
	height: 4px;
}
.progress-sm {
	height: 15px;
}
.progress.progress-sm {
	height: 6px;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 18px;
}
.row.row-sm {
    margin-left: -3px;
    margin-right: -3px;
}
.row.row-sm > div {
    padding-left: 3px;
    padding-right: 3px;
}

.daterangepicker .ranges li.active {
    background-color: #680a83;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
	background-color: #680a83;
    border-color: transparent;
    color: #fff;
}

/*-----------------
	9. Avatar
-----------------------*/

.avatar {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 3rem
}
.avatar > img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #F8ECFF;
	color: #680A83;
	display: flex;
	align-items: center;
	justify-content: center;
}
.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25%;
	height: 25%;
	border-radius: 50%;
	content: '';
	border: 2px solid #fff;
}
.avatar-online:before {
	background-color: #7bb13c;
}
.avatar-offline:before {
	background-color: #e84646;
}
.avatar-away:before {
	background-color: #ffbc34;
}
.avatar .border {
	border-width: 3px !important;
}
.avatar .rounded {
	border-radius: 6px !important;
}
.avatar .avatar-title {
	font-size: 18px;
}
.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
}
.avatar-xs .border {
	border-width: 2px !important;
}
.avatar-xs .rounded {
	border-radius: 4px !important;
}
.avatar-xs .avatar-title {
	font-size: 12px;
}
.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
	border-width: 1px;
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
}
.avatar-sm .border {
	border-width: 3px !important;
}
.avatar-sm .rounded {
	border-radius: 4px !important;
}
.avatar-sm .avatar-title {
	font-size: 15px;
}
.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
	border-width: 2px;
}
.avatar-lg {
	width: 3.75rem;
	height: 3.75rem;
}
.avatar-lg .border {
	border-width: 3px !important;
}
.avatar-lg .rounded {
	border-radius: 8px !important;
}
.avatar-lg .avatar-title {
	font-size: 24px;
}
.avatar-lg.avatar-away:before,
.avatar-lg.avatar-offline:before,
.avatar-lg.avatar-online:before {
	border-width: 3px;
}
.avatar-xl {
	width: 5rem;
	height: 5rem;
}
.avatar-xl .border {
	border-width: 4px !important;
}
.avatar-xl .rounded {
	border-radius: 8px !important;
}
.avatar-xl .avatar-title {
	font-size: 28px;
}
.avatar-xl.avatar-away:before,
.avatar-xl.avatar-offline:before,
.avatar-xl.avatar-online:before {
	border-width: 4px;
}
.avatar-xxl {
	width: 5.125rem;
	height: 5.125rem;
}
.avatar-xxl .border {
	border-width: 6px !important;
}
.avatar-xxl .rounded {
	border-radius: 8px !important;
}
.avatar-xxl .avatar-title {
	font-size: 30px;
}
.avatar-xxl.avatar-away:before,
.avatar-xxl.avatar-offline:before,
.avatar-xxl.avatar-online:before {
	border-width: 4px;
}
.avatar-group {
	display: inline-flex;
}
.avatar-group .avatar + .avatar {
	margin-left: -.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
	margin-left: -.40625rem;
}
.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
	margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
	margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
	z-index: 1;
}

/*-----------------
	10. Header
-----------------------*/

.header {
	background: #fff;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1001;
	height: 70px;
	margin-left: 273px;
}
.sidebar .header-left {
	background-color: #680A83;
	float: left;
	height: 70px;
	padding: 0 15px;
	position: fixed;
	text-align: left;
	width: 273px;
	z-index: 1;
	transition: all 0.2s ease-in-out;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.sidebar .header-left .logo {
	display: inline-block;
	line-height: 70px;
}
.sidebar .header-left .logo img {
    max-height: 36px;
    width: auto;
}
.header-left .logo.logo-small {
    display: none;
}
.header .dropdown-menu > li > a {
	position: relative;
}
.header .dropdown-toggle:after {
	display: none;
}
.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #7c2296;
	border-right: 2px solid #7c2296;
	content: '';
	height: 8px;
	display: none;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.dark-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 15px;
}
.dark-option {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #EDEDED;
	padding: 5px 7px;
	border-radius: 50px;
}
.dark-option a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	width: 26px;
	height: 26px;
	
}
.dark-option a.active {
	border-radius: 50px;
	background: #680A83;
}
.dark-option a.active i {
	color: #fff;
}
.dark-option a i {
	color: #680A83;
}
.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
}
.user-menu li {
	position: relative;
}
.user-menu.nav > li > a {
	color: #fff;
	font-size: 14px;
	padding: 0 15px;
	height: 70px;
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #000;
}
.user-img {
	display: inline-block;
	margin-right: 10px;
	position: relative;
}
.user-menu.nav > li > a.mobile_btn {
	border: 0;
	position: relative;
	padding: 0;
	margin: 0;
	cursor: pointer
}
.user-menu .dropdown-menu {
    min-width: 180px;
    padding: 0;
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    border: 0;
}
.user-menu .dropdown-menu .dropdown-item {
    padding: 7px 15px;
}
.user-menu .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    padding: 10px 15px;
}
.user-menu .dropdown-menu .dropdown-item:hover {
    color: #0A0A0A;
	background-color: rgb(104 10 131 / 5%);
}
.header .dropdown-menu > li > a:focus,
.header .dropdown-menu > li > a:hover {
	background-color: #2962ff;
	color: #fff;
}
.header .dropdown-menu > li > a:focus i,
.header .dropdown-menu > li > a:hover i {
	color: #fff;
}
.header .dropdown-menu > li > a {
	padding: 10px 18px;
}
.header .dropdown-menu > li > a i {
	color: #2962ff;
	margin-right: 10px;
	text-align: center;
	width: 18px;
}
.header .user-menu .dropdown-menu > li > a i {
	color: #2962ff;
	font-size: 16px;
	margin-right: 10px;
	min-width: 18px;
	text-align: center;
}
.header .user-menu .dropdown-menu > li > a:focus i,
.header .user-menu .dropdown-menu > li > a:hover i {
	color: #fff;
}
.mobile_btn {
	display: none;
	float: left;
}
.slide-nav .sidebar {
	margin-left: 0;
}
.app-dropdown .dropdown-menu {
    padding: 0;
    width: 300px;
}
.app-dropdown-menu .app-list {
    padding: 15px;
}
.app-dropdown-menu .app-item {
    border: 1px solid transparent;
    border-radius: 3px;
    color: #737373;
    display: block;
    padding: 10px 0;
    text-align: center;
}
.app-dropdown-menu .app-item i {
    font-size: 20px;
    height: 24px;
}
.app-dropdown-menu .app-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.app-dropdown-menu .app-item:hover,
.app-dropdown-menu .app-item:focus,
.app-dropdown-menu .app-item:active, 
.app-dropdown-menu .app-item.active {
	background-color: #f9f9f9;
	border-color: #e3e3e3;
}
.app-list > div + div {
	margin-top: 5px;
}
.app-list > .row {
	margin-left: -5px;
	margin-right: -5px;
}
.app-list > .row > .col {
	padding-left: 5px;
	padding-right: 5px;
}
.user-header {
	background-color: rgb(104 10 131 / 5%);
    display: flex;
    padding: 10px 15px;
}
.user-header .user-text {
	margin-left: 10px;
}
.user-header .user-text h6 {
    margin-bottom: 2px;
	font-size: 14px;
}
.menu-title {
    color: #a3a3a3;
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    padding: 0 25px;
}
.sidebar-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 60px;
    width: 100%;
    z-index: 1000;
}
.sidebar-overlay.opened {
    display: block;
}
html.menu-opened {
	overflow: hidden;
}
html.menu-opened body {
	overflow: hidden;
}
.user-detail span {
	font-size: 14px;
}
.user-detail .drop-user-name {
	color: #680A83;
	font-weight: 600;
	display: flex;
    align-items: center;
}
.user-detail .drop-user-role {
	color: #616161;
	font-weight: 400;
	display: block;
}
.user-profile-grp {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 20px 0 0;
}
.drop-user-profile .name-profile {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.user-profile-grp .user-img  {
	width: 41px;
	height: 41px;
	position: relative;
}
.user-profile-grp .user-img::before  {
	content: "";
	width: 10px;
	height: 10px;
	background: #1BA345;
	border-radius: 50px;
	position: absolute;
	bottom: 0;
    right: 2px;
}
.user-img img {
	width: 100%;
}

/*-----------------
	10. Search
-----------------------*/

.top-nav-search {
    float: left;
    margin-left: 30px;
}
.mini-sidebar .top-nav-search {
    margin-left: 70px;
}
.top-nav-search form {
    margin-top: 15px;
	position: relative;
}
.top-nav-search .form-control {
    background-color: #F5F5F5;
    border-radius: 8px;
    color: #000;
    border: none;
    height: 40px;
    padding: 10px 15px 10px 40px;
}
.top-nav-search .btn {
    background-color: transparent;
    border-color: transparent;
    color: #9E9E9E;
    min-height: 40px;
    padding: 7px 15px;
    position: absolute;
    left: 0;
    top: 2px;
}
.top-nav-search .btn:active {
	outline: 0;
	border: 0;
	color: #9E9E9E;
}
.top-nav-search .form-control::-webkit-input-placeholder {
	color: #9E9E9E;
}
.top-nav-search .form-control::-moz-placeholder {
	color: #9E9E9E;
}
.top-nav-search .form-control:-ms-input-placeholder {
	color: #9E9E9E;
}
.top-nav-search .form-control::-ms-input-placeholder {
	color: #9E9E9E;
}
.top-nav-search .form-control::placeholder {
	color: #9E9E9E;
}
.top-nav-search.active form {
    display: block;
    left: 0;
    position: absolute;
}

/*-----------------
	11. Sidebar
-----------------------*/

.sidebar {
    background-color: #680A83;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 0px;
    transition: all 0.2s ease-in-out 0s;
    width: 273px;
    z-index: 1;
	box-shadow: 0px 4px 4px rgb(66 11 161 / 20%);
}
.sidebar .slimScrollDiv {
	height: 100vh !important;
}
.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.sidebar-inner {
	height: 100%;
	min-height: 100%;
	transition: all 0.2s ease-in-out 0s;
	background: #680A83;
}
.sidebar-menu {
	padding: 85px 0 60px;
}
.sidebar-menu ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.sidebar-menu li.flags-group .dropdown-menu a{
	color: #680A83 !important;
}
.sidebar-menu > ul > li.flags-group:hover .dropdown-menu a span {
     color: #680A83  !important; 
}
.sidebar-menu li a {
	color: #F2DDFF;
	display: block;
	font-size: 16px;
	height: auto;
	font-weight: 600;
}
.sidebar-menu > ul > li:hover {
    background-color: transparent;
}
.sidebar-menu > ul > li.active a span,
.sidebar-menu > ul > li.active:hover a span {
	color: #ffffff;
}
.sidebar-menu > ul > li:hover a span {
	color: #ffffff;
}
.sidebar-menu > ul > li.active:hover a i{
    background-color: #C00CFF;
    color: #ffffff;
}
.sidebar-menu li.active a i {
	background-color: #C00CFF;
    color: #ffffff;
}
.sidebar-menu li:hover a i {
	background-color: #C00CFF;
    color: #ffffff;
}
.sidebar-menu li:hover ul li a i {
	color: #DBB0F5;
}
.sidebar-menu li.active:hover ul li a i {
	color: #DBB0F5;
}
.sidebar-menu li.active ul li a i {
	color: #DBB0F5;
}
.sidebar-menu li.active ul li a.active i {
	color: #fff;
}
.menu-title {
    color: #F1DDFD;
    display: flex;
    font-size: 15px;
	font-weight: 500;
    opacity: 1;
    padding: 5px 15px;
    white-space: nowrap;
}
.menu-title > i {
	float: right;
	line-height: 40px;
}
.sidebar-menu li.menu-title a {
    color: #ff9b44;
    display: inline-block;
    margin-left: auto;
    padding: 0;
}
.sidebar-menu li.menu-title a.btn {
    color: #fff;
    display: block;
    float: none;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
	color: #fff;
}
.sidebar-menu ul ul li:hover a,
.sidebar-menu ul ul li:hover a i {
	color: #fff;
}
.submenu .active + ul {
    background: #680A83;
}
.mobile_btn {
	display: none;
	float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 10px;
	white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
	background: #FF000A;
	font-size: 14px;
	font-weight: 600;
	padding: 5px 5px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.sidebar-menu ul ul a {
    display: block;
    font-size: 14px;
    padding: 7px 10px 7px 62px;
    position: relative;
	color: #F2DDFF;
}
.sidebar-menu ul ul a i {
	color: #F2DDFF;
}
.sidebar-menu ul ul {
	display: none;
}
.sidebar-menu ul ul ul a {
	padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
	padding-left: 85px;
}
.sidebar-menu > ul > li {
    position: relative;
}
.sidebar-menu > ul > li:last-child {
    margin-bottom: 0;
}
.sidebar-menu .menu-arrow {
	-webkit-transition: -webkit-transform 0.15s;
	-o-transition: -o-transform 0.15s;
	transition: transform .15s;
	position: absolute;
	right: 28px;
	display: inline-block;
	font-family: 'FontAwesome';
	text-rendering: auto;
	line-height: 40px;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	line-height: 18px;
	top: 23px;
}
.sidebar-menu .menu-arrow:before {
	content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
	top: 10px;
}
.sidebar-menu > ul > li > a {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding: 12px 15px;
    position: relative;
    transition: all 0.02s ease-in-out 0s;
}
.sidebar-menu ul li a i {
	display: inline-block;
	font-size: 20px;
	line-height: 24px;
	text-align: left;
	vertical-align: middle;
	width: 38px;
	height: 38px;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	transition: all 0.2s ease-in-out 0s;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: #DBB0F5;
}
.sidebar-menu ul ul li a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.sidebar-menu ul ul li a i {
	background: transparent;
	padding: 0;
	width: auto;
	height: auto;
	font-size: 6px;
}
.sidebar-menu ul li:hover ul li a i {
	background: transparent;
}
.sidebar-menu ul li.active ul li a i {
	background: transparent;
}
.sidebar-menu ul li:hover.active ul li a i {
	background: transparent;
}
.sidebar-menu ul li.menu-title a i {
	font-size: 16px !important;
	margin-right: 0;
	text-align: right;
	width: auto;
}
.sidebar-menu li a > .badge {
    color: #fff;
}
.flags-group {
    padding: 0px 15px 15px;
    position: sticky;
    bottom: 0px;
    background: #680a83;
	width: 100%;
}
.flags-group .flag-nav {
	padding: 10px;
}
.flags-group .dropdown-toggle img {
	border-radius: 5px;
}
.flags-group .dropdown-toggle span {
	color: #fff;
	font-size: 16px;
	font-weight: 400;
}
.flags-group .dropdown-toggle:after {
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    content: "";
    height: 8px;
    display: inline-block;
    pointer-events: none;
    width: 8px;
    vertical-align: 2px;
    transform-origin: 66% 66%;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
    position: relative;
    top: -2px;
	right: -120px;
}
.flags-group .dropdown-menu {
	width: 100%;
	transform: translate(0px, -40px) !important;
}
.flags-group .dropdown-menu .dropdown-item img {
	border-radius: 6px;
	margin-right: 5px;
}
.unlimited-access {
	border-radius: 14px;
	border: 1px dashed #F8ECFF;
	background: rgba(45, 7, 57, 0.7);
	padding: 15px;
	position: relative;
}
.unlimited-access h6 {
	font-size: 16px;
	font-weight: 600;
	color: #fff;
	margin-bottom: 10px;
}
.unlimited-access p {
	font-size: 12px;
	font-weight: 400;
	color: #E0E0E0;
	margin-bottom: 24px;
}
.unlimited-access .btn-primary {
	border-radius: 8px;
	background: #DBB0F5 !important;
	border: 1px solid #DBB0F5 !important;
	color: #680A83;
	padding: 6px 16px;
	font-size: 14px;
	font-weight: 600;
}
.plane-img {
	position: absolute;
    bottom: 8px;
    right: 8px;
}

/*-----------------
	12. Content
-----------------------*/

.main-wrapper {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
	/* visibility: hidden; */
}
.page-wrapper {
	margin-left: 273px;
	padding-top: 70px;
	position: relative;
	transition: all 0.4s ease;
}
.page-wrapper > .content {
	padding: 1.875rem 1.875rem 0;
}
.page-header {
	margin-bottom: 40px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}
.page-header .breadcrumb {
    background-color: transparent;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
}
.page-header .breadcrumb a {
	color: #333;
}
.page-title {
    color: #333;
}
.page-header .btn-added {
    background: #680A83;
    padding: 11px 15px;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
	border: 0;
}
.page-header .btn i {
	font-size: 16px;
}
.page-header .btn-white {
    background: #fff;
    padding: 11px 15px;
    color: #680A83;
    font-weight: 700;
    font-size: 14px;
	border: 0;
	box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.03);
}
.page-header .btn-added:hover {
    background: #C00CFF;
	border: 0;
}
.page-header .btn-white:hover {
    background: #C00CFF;
	border: 0;
	color: #fff;
}
.center-flex {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.page-header .page-btn ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.page-header .page-btn .file-pdf {
	color: #FF000A;
}
.page-header .page-btn .file-text {
	color: #1BBE00;
}
.page-header .btn-white.comman-file {
	width: 45px;
	height: 45px;
	font-size: 24px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.page-header .btn-white.comman-file:hover {
	background: #C00CFF;
}
.page-header .btn-white.comman-file:hover i {
	color: #fff;
}
.page-header .btn-white.comman-file i {
	font-size: 24px;
}
.page-btn ul li {
	margin-right: 10px;
}
.page-btn ul li:last-child {
	margin-right: 0px;
}
.table-dropdown .dropdown-item {
	color: #616161;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.table-dropdown .dropdown-item i {
	font-size: 15px;
}
.table-dropdown .dropdown-item:active {
	color: #fff;
}
.table-dropdown  .dropdown-menu {
	padding: 0;
	box-shadow: 0px 4px 3px 0px #0000001A;
	border: 1px solid #E5E7EB;
	border-radius: 8px;
}
.table-dropdown .dropdown-menu ul li:first-child .dropdown-item:hover {
    border-radius: 5px 5px 0 0;
}
.table-dropdown .dropdown-menu ul li:last-child .dropdown-item:hover {
    border-radius: 0 0 5px 5px;
}
.blocked-btn {
	border: 1px solid #DE3E44;
	color: #DE3E44;
	padding: 2px 12px;
}
.blocked-btn:hover {
	border: 1px solid #DE3E44;
	color: #fff;
	background: #DE3E44;
}
.card-table table.dataTable thead .sorting_asc:after,
.card-table table.dataTable thead .sorting_desc:before,
.card-table table.dataTable thead .sorting:after {
	display: none;
}

/*-----------------
  Select
-----------------------*/

.select2-results__option {
	font-size: 14px;
}
.select2-container .select2-selection--single {
    border: 1px solid #E0E0E0;
    height: 40px;
	min-width: 118px;
	border-radius: 8px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #424242;
    font-size: 14px;
    font-weight: normal;
    line-height: 40px;
	font-weight: 400;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
    right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #424242;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #424242;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -5px;
}
.select2-container--default.form-control-sm .select2-selection--single .select2-selection__arrow {
    top: 7px;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: #424242;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 10px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #4B4B9F;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}
.select2-container--open .select2-dropdown--below {
	margin-top: 0px;
}
.select2-container {
	z-index: 1111;
}

/*-----------------
  13. Modal
-----------------------*/

.modal-header {
	padding: 24px;
	color: #484848;
	border-radius: 10px 10px 0 0;
	border-bottom: 0;
	justify-content: space-between;
}
.modal-content {
	border-radius: 23px;;
}
.modal-body {
	border-radius: 10px;
	padding: 0 24px 24px;
}

.modal-body .form-group label {
	font-weight: 500;
	color: #0A0A0A;
}

.modal-header .modal-title {
	font-size: 18px;
	font-weight: 600;
}

.modal-header .modal-title i {
	font-size: 20px;
	color: #420BA1;
	margin-right: 5px;
}

.modal-header .modal-title span.material-icons {
	font-size: 25px;
	color: #420BA1;
	margin-right: 5px;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.modal-header .close:hover {
	opacity: .75;
}
.modal-header .close {
	color: #DE3E44;
	border: 0;
	background-color: #FFE8E9;
	text-shadow: none;
	opacity: 1;
	width: 28px;
	height: 28px;
	border-radius: 25px;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	padding: 0;
}
.modal.mute-notify .modal-dialog {
    width: 383px;
}
.newgroup_create {
    padding: 10px 20px;
    line-height: 1.6;
    border-radius: 0.375rem;
    background-color: #680A83;
    box-shadow: none;
    border-color: #680A83;
    margin: 0px auto 15px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}
.btn-primary {
	color: #fff;
	background: #680A83 !important;
	border-color: #680A83!important;
	border: 2px solid #680A83!important;
}
.btn-primary:hover {
	box-shadow: 0 50px 0 0 #fff inset;
	color: #680A83;
	background: #680A83 !important;
}
.btn-secondary {
	color: #DE3E44;
	background: #fff!important;
	border-color: #DE3E44!important;
	border: 2px solid #DE3E44!important;
}
.btn-secondary:hover {
	box-shadow: 0 50px 0 0 #DE3E44 inset;
	color: #fff;
}
.mute-chat-btn .btn {
	font-size: 15px;
	font-weight: 600;
}
.mute-chat-btn {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.mute-chat-btn .btn-primary {
	margin-right: 10px;
}
.profile-cover {
    margin-bottom: 30px;
}
.profile-cover-avatar {
    display: -ms-flexbox;
    display: flex;
    border-radius: 50%;
    position: relative;
    width: 98px;
    height: 98px;
}
.profile-cover-avatar .avatar-img {
    display: block;
    max-width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    pointer-events: none;
    border-radius: 50%;
}
.profile-cover-avatar input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(19, 33, 68, .25);
    border-radius: 50%;
    transition: .2s;
}
.profile-cover-avatar .empty-user {
	background: #9E9E9E;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: end;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	width: 100%;
	border-radius: 50px;
	border: 4px solid #9E9E9E;
}
.profile-cover-avatar .empty-user i {
	color: #fff;
	font-size: 70px;
}
.dark-red {
    color: #DE3E44;
}
.avatar-edit {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    background-color: #680A83;
    border-radius: 50%;
    transition: .2s;
    border: 3px solid #FFFFFF;
}
.avatar-edit i {
	font-size: 20px;
}
.pass-login {
    margin-bottom: 15px;
}
.pass-login label {
	color: #0A0A0A;
	font-size: 14px;
	font-weight: 500;
}
.comp-section .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
}
.comp-section .modal-content {
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
}
.comp-section .modal-header {
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
}
.comp-section .modal-footer {
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
}
.comp-section .modal-body {
    border-radius: 10px;
    padding: 15px;
}
.comp-section .card .card-header {
    border-bottom: 1px solid #eaeaea;
	margin-bottom: 0;
}
.comp-section .card {
	padding: 0;
}
.comp-section .card-header {
    padding: 1rem 1.5rem;
}
.comp-section .card-body {
    padding: 1.5rem;
}

/*-----------------
	14. Notifications
-----------------------*/

.notifications {
	padding: 0;
}
.notifications .notification-time {
	font-size: 12px;
	line-height: 1.35;
	color: #bdbdbd;
}
.notifications .media {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
	border-bottom: none;
}
.notifications .media a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications .media a:hover {
	background-color: #f4eef6;
}
.notifications .media > .avatar {
	margin-right: 10px;
}
.notifications .media-list .media-left {
	padding-right: 8px;
}
.topnav-dropdown-header {
	border-bottom: 1px solid #eee;
	text-align: center;
}
.topnav-dropdown-header, 
.topnav-dropdown-footer {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
}
.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
	display: block;
	text-align: center;
	color: #333;
}
.user-menu.nav > li > a .badge {
	background-color: #f43f3f;
	display: block;
	font-size: 10px;
	font-weight: bold;
	min-height: 15px;
	min-width: 15px;
	position: absolute;
	right: 3px;
	top: 6px;
	border-radius: 50%;
}
.user-menu.nav > li > a > i {
	font-size: 1.5rem;
	color: #9E9E9E;
}
.chat-user-icon a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.chat-user-icon a i {
	position: relative;
}
.chat-user-icon a i:after {
	content: "";
	width: 6px;
	height: 6px;
	background: red;
	border-radius: 50px;
	position: absolute;
	right: 4px;
}
.noti-details {
	color: #989c9e;
    margin-bottom: 0;
}
.noti-title {
	color: #333;
}
.notifications .noti-content {
	height: 290px;
	width: 350px;
	overflow-y: auto;
	position: relative;
}
.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.notifications ul.notification-list > li{
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
	border-bottom: none;
}
.notifications ul.notification-list > li a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
	background-color: #f4eef6;
}
.notifications ul.notification-list > li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}
.topnav-dropdown-header .notification-title {
    color: #333;
    display: block;
    float: left;
    font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
    color: #f83f37;
    float: right;
    font-size: 12px;
    text-transform: uppercase;
}
.noti-time {
    margin: 0;
}

.notification-group.user-list .user-list-item {
    background: #fafafa;
    border: 1px solid #E1E8ED;
    border-radius: 10px;
    box-shadow: none;
    margin-bottom: 15px;
    padding: 15px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.notification-group.user-list .user-list-item .avatar {
    width: 46px;
    height: 46px;
	margin-right: 10px;
}
.notification-group.user-list .user-list-item .avatar img {
	width: 100%;;
}
.notification-group .avatar.avatar-online::before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: "";
    border: 2px solid #ffffff;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #22C550;
}
.notification-group .user-list .user-list-item .avatar::before {
    top: 0;
    bottom: inherit;
}
.user-list .user-list-item .users-list-body {
    flex-grow: 1;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.notification-group.user-list .user-list-item .users-list-body .name-list-user h5 {
    font-size: 15px;
    font-weight: 600;
}
.notification-group.user-list .user-list-item .users-list-body .name-list-user p {
    margin-bottom: 0px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.follow-btn .btn-primary {
    font-size: 12px;
    font-weight: normal;
    color: #5A078B;
    background: rgba(90, 7, 139, 0.08) !important;
    border: 1px solid rgba(90, 7, 139, 0.08) !important;
    border-radius: 7px 7px 7px 7px;
    min-width: 74px;
    max-width: 74px;
    padding: 2px 10px;
    -webkit-transition: 0.7s;
    -ms-transition: 0.7s;
    transition: 0.7s;
}
.notification-group  .chat-user-time .chats-dot a i {
    color: #6B7280;
}
.notification-group .chat-user-time .dropdown-menu .dropdown-item a {
    color: #1F2937;
    width: 100%;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.notification-group .chat-user-time .dropdown-menu .dropdown-item:hover:first-child {
    border-radius: 6px 6px 0 0;
}
.notification-group .dropdown-menu {
    padding: 0;
	border-radius: 6px;
}
.notification-group .dropdown-menu .dropdown-item {
    font-size: 13px;
    padding: 8px 15px;
}
.notification-group .dropdown-menu .dropdown-item:active a {
	color: #fff;
}

/*-----------------
	15. Dashboard
-----------------------*/

.dash-widget-icon {
    align-items: center;
    border-radius: 50%;
    color: #fff;
    display: inline-flex;
    font-size: 1.875rem;
    height: 56px;
    justify-content: center;
    line-height: 48px;
    text-align: center;
    width: 56px;
	margin-right: 10px;
	background: #F5F5F5;
}
.dash-widget-icon .fe-phone {
	font-size: 1.5rem;
}
.dash-count {
    font-size: 18px;
}
.count-title {
    font-size: 16px;
    font-weight: 500;
    color: #616161;
}
.dash-count a {
	clear: both;
	display: block;
}
.dash-count h5 {
	margin-bottom: 0px;
}
.dash-count h6 {
	margin-bottom: 5px;
}
.dash-count .count {
	font-size: 24px;
    color: #0A0A0A;
    font-weight: 600;
}
.card.active .dash-count .count {
	color: #fff;
}
.dash-widget-info h3 {
    margin-bottom: 10px;
}
.dash-widget-header {
    align-items: center;
    display: flex;
}
.card-chart .card-body {
	padding: 8px;
}
#morrisArea > svg, #morrisLine > svg  {
	width: 100%;
}
.activity-feed {
    list-style: none;
    margin-bottom: 0;
    margin-left: 5px;
    padding: 0;
}
.activity-feed .feed-item {
    border-left: 2px solid #e4e8eb;
    padding-bottom: 19px;
    padding-left: 20px;
    position: relative;
}
.activity-feed .feed-item:last-child {
	border-color: transparent;
	padding-bottom: 0;
}
.activity-feed .feed-item:after {
	content: "";
	display: block;
	position: absolute;
	top: 0;
	left: -7px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #177dff;
}
.activity-feed .feed-item .feed-date {
	display: block;
	position: relative;
	color: #777;
	text-transform: uppercase;
	font-size: 13px;
 }
.activity-feed .feed-item .feed-text {
	color: #777;
	position: relative;
}
.activity-feed .feed-item .feed-text a {
    color: #333;
    font-weight: 600;
}
.page-header h6 {
	font-size: 16px;
	color: #616161;
	font-weight: 400;
}
.page-header h4 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 0;
}
.dash-widget-group ul li .card {
	border: 0;
	box-shadow: 0px 7.5px 17.5px 0px #0000000D;
	border-radius: 20px;
}
.dash-widget-group ul li {
	margin-right: 24px;
}
.dash-widget-group ul li:last-child {
	margin-right: 0px;
}
.dash-widget-group ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	flex-wrap: wrap;
}
.invite-user-list .table-avatar a {
	color: #0A0A0A;
	font-size: 16px;
}
.invite-user-list .table-avatar a:hover {
	color: #680A83;
}
.invite-user-list ul li {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.invite-user-list ul li+li {
	margin-top: 15px;
}
.invite-check-user {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.invite-check-user span+span {
	margin-left: 6px;
}
.invite-check-user span {
	border-radius: 6px;
}
.invite-check-user span a {
	width: 30px;
	height: 30px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.invite-check-user .check-user {
	background: #E7FAF2;
	border: 1px solid #B5D0C3;
}
.invite-check-user .check-user:hover {
	background: #B5D0C3;
}
.invite-check-user .check-user:hover i {
	color: #fff;
}
.invite-check-user .check-user i {
	color: #1BA345;
	font-size: 18px;
}
.invite-check-user .uncheck-user {
	background: #FFE8E9;
	border: 1px solid #E2B6B6;
}
.invite-check-user .uncheck-user:hover {
	background: #E2B6B6;
}
.invite-check-user .uncheck-user:hover i {
	color: #fff;
}
.invite-check-user .uncheck-user i {
	color: #DE3E44;
	font-size: 18px;
}
.skin-settings {
    display: none;
}
.table-top {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	border-bottom: 1px solid #EDEDED;
}
.table-top ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.table-top ul li .pass-login {
	margin-bottom: 0;
}
.table-top ul li .select2-container {
    z-index: 0;
}
.table-top ul li,
.table-top {
	margin-bottom: 24px;
}
.table-top ul li {
	margin-right: 10px;
}
.table-top ul li i {
	font-size: 16px;
}
.table-top ul li:last-child {
	margin-right: 0px;
}
.table-top ul li .pass-login .select2-container--default {
	min-width: 171px;
}
.table-top ul li .pass-login .date-range {
	padding-left: 30px;
}
.refine-filter {
	background: #F8ECFF;
	padding: 9px 15px;
	border-radius: 6px;
	color: #680A83;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.refine-filter:hover {
	background: #680A83;
	color: #fff;
}
.refine-filter span {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.refine-filter span i {
	font-size: 16px;
}

.search-set .search-input {
    position: relative;
	margin-bottom: 24px;
}
.search-set .search-input label {
	margin-bottom: 0;
}
.search-set .search-input .btn-searchset {
    padding: 2px;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 6px;
	color: #9E9E9E;
}
.search-set .search-input input[type=search] {
    background: #F5F5F5;
    height: 40px;
    border: 1px solid #F5F5F5;
    width: 263px;
    border-radius: 5px;
    padding: 0 15px 0 30px;
}
.search-set .search-input input::placeholder {
	color: #9E9E9E;
}
.cal-icon {
    position: relative;
}
.cal-icon span {
    position: absolute;
    left: 10px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.user-filter {
	font-size: 14px;
	font-weight: 500;
	color: #0A0A0A;
	border: 1px solid #EDEDED;
	border-radius: 8px;
	padding: 8px 18px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.calendar-report {
	position: relative;
}
.select-clendear-icon {
	position: absolute;
	z-index: 9999;
	top: 11px;
	left: 10px;
}
.year-report .select2-container--default {
	max-width: 86px;
	z-index: 0;
}
.year-report .select2-container .select2-selection--single {
	max-width: 86px;
	min-width: 86px;
}
.year-report .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 20px;
    padding-left: 30px;
}
.year-report .select2-container--default .select2-selection--single .select2-selection__arrow {
	display: none;
}
.dash-widget-group ul li .card {
    min-width: 210px;
}
.table-top .cal-icon .bootstrap-datetimepicker-widget span {
	position: relative;
}
.table-top .bootstrap-datetimepicker-widget ul {
	display: block;
}
.select-users-blk {
    position: absolute;
    z-index: 2;
	top: 13px;
    left: 9px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.table-top .pass-login {
	position: relative;
}
.table-top .pass-login .select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 30px;
}

/*-----------------
	16. Language
-----------------------*/

.table-active {
	padding: 3px 9px;
	border-radius: 5px;
}
.table-active.bg-success {
	border: 1px solid #1BA345;
	color: #1BA345;
	background-color: #fff !important;
}
.table-active.bg-success:hover {
	color: #fff;
}
.action-web-list ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.action-web-list ul li {
	margin-right: 5px;
}
.action-web-list ul li a {
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	background: #F8ECFF;
	color: #0A0A0A;
	padding: 3px 9px;
}
.action-web-list ul li a:hover {
	background: #E0E0E0;
}
.action-web-list ul li a.action-download {
	background-color: #FAFBFE;
	color: #616161;
	border: 0;
}
.action-web-list ul li a.action-trash {
	background-color: transparent;
	color: #DE3E44;
	border: 0;
	padding: 0;
}
.action-web-list ul li a.action-trash:hover i {
	font-weight: 600;
}
.track-statistics {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.track-statistics .progress {
	width: 100%;
	height: 6px
}
.statistic-head p {
	font-size: 12px;
	color: #1F2937;
	margin-bottom: 0;
	padding-left: 5px;
}
.track-statistics .progress-bar.bg-info {
	background-color: #02A2B9 !important;
}
.modal.language-translate .modal-dialog {
	min-width: 890px;
}
.actions a i {
	font-size: 18px;
}
.table-avatar.language-avatar .avatar {
	width: 20px;
	height: 20px;
}
.active-switch .switch {
    position: relative;
    display: inline-block;
    width: 41px;
    height: 21px;
    margin: 0;
}
.active-switch .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.active-switch input:checked + .sliders {
    background-color: #00CC45;
}
.active-switch .sliders.round {
    border-radius: 34px;
}
.active-switch .sliders {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.active-switch input:checked + .sliders:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
.active-switch input:checked + .sliders:before {
    left: -5px;
}
.active-switch .sliders.round:before {
    border-radius: 50%;
}
.active-switch .sliders:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch .layout-link {
    position: relative;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: inline-block;
}


.refine-filter.back-transaction {
	color: #616161;
	font-weight: 600;
}
.refine-filter.back-transaction:hover {
	background: #680A83;
	color: #fff;
}
.language-blk h2 {
	margin-bottom: 0;
}
.language-blk h2 a {
	font-size: 14px;
	color: #616161;
	font-weight: 400;
	border: 1px solid #C2C2C2;
	padding: 9px 12px;
	border-radius: 6px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.layout-blk ul li a {
	background: #F8ECFF;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	color: #0A0A0A;
	font-size: 14px;
	font-weight: 400;
	padding: 4px 12px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.layout-blk ul li a.active {
	background: #680A83;
	color: #fff;
}
.layout-blk ul li a:hover {
	background: #680A83;
	color: #fff;
}
.translate-table  div.dataTables_wrapper div.dataTables_filter {
	display: none;
}
.summer-group .note-editor {
	width: 500px
}
.status-logs h6 {
	color: #0A0A0A;
	margin-bottom: 0;
	font-weight: 500;
}
.status-logs {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.progress-language {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	width: 100%;
}
.percentage-progress {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.percentage-progress .language-blk {
	margin-right: 10px;
}
.percentage-progress .track-statistics {
	min-width: 151px;
	display: block;
}
.percentage-progress .track-statistics span {
	font-size: 14px;
	font-weight: 500;
}
.table-direction {
	direction: rtl;
}
.table-direction .actions .bg-gray-light {
	margin-left: 6px !important;
	margin-right: 0 !important;
}
.table-direction .dataTables_length {
	float: right;
}

/*-----------------
	17. Sms Setting
-----------------------*/

.nav-menus {
    background: #fff;
    border: 1px solid #EDEDED;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-radius: 8px;
	box-shadow: 0px 6px 12px 0px #00000008;
	width: 100%;
}
.settings-view {
    display: flex;
    align-items: center;
}
.settings-view a {
	color: #616161;
}
.email-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	width: 100%;
}


/*-----------------
	18. Theme Setting
-----------------------*/

.settings-icon {
    z-index: 999;
    position: fixed;
    right: 0px;
    top: 50%;
	-webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.settings-icon span {
	padding: 10px 15px;
    cursor: pointer;
    color: #ffffff;
    font-size: 15px;
	font-weight: 600;
    background-color: #680a83;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	writing-mode: vertical-rl;
   transform:rotate(180deg);
   padding: 24px 7px;
   border-radius: 0px 6px 6px 0px;
}
.settings-icon span i {
	-webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
	display: -webkit-box;
    display: -ms-flexbox;
	font-size: 24px;
	margin-bottom: 10px;
}
.sidebar-headerset {
    width: 100%;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sidebar-headerset h2 {
    font-size: 24px;
    font-weight: 600;
}
.sidebar-headerset h3 {
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}
.sidebar-headerclose a {
	cursor: pointer;
}
.sidebar-headerclose a i {
	color: #DE3E44;
	font-size: 20px;
	font-weight: 600;
}
.settings-mains {
    padding: 20px 24px;
}
.settings-mains .layout-head {
    margin-bottom: 15px;
}
.settings-mains .layout-head h5 {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}
.form-check .form-check-input {
    cursor: pointer;
}
.card-radio .form-check-input {
    display: none;
}
.card-radio .form-check-label {
    border: 2px solid rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    position: relative;
	cursor: pointer;
}
.card-radio .form-check-label img {
	width: 100%;
}
.card-radio .form-check-input:checked + .form-check-label {
    border-color: #1BA345 !important;
}
.card-radio .form-check-input:checked + .form-check-label:before {
    content: "✓";
    font-family: "Line Awesome Free";
    position: absolute;
    top: 40%;
    left: 40%;
    font-size: 10px;
    background: #1BA345;
    font-weight: 900;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    color: #F4F4F9;
    transform: translate(0%);
}
.head-layout {
	color: #0A0A0A;
	font-size: 14px;
	font-weight: 400;
}
.screen-slide-bar {
	border-bottom: 1px solid #EDEDED;
	padding-bottom: 10px;
}
.screen-mode-grp {
	padding: 20px 0px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.screen-mode-grp h5 {
	font-size: 16px;
	font-weight: 500;
	color: #000000;
	margin-bottom: 5px;
}
.screen-mode-grp p {
	font-size: 14px;
	font-weight: 400;
	color: #616161;
	margin-bottom: 0;
}
.colorscheme-cardradio .card-radio .form-check-label {
	padding: 10px;
	background: #FAFAFA;
	cursor: pointer;
}
.offcanvas-footer {
	padding: 0px 24px 24px;
}
.offcanvas-footer i {
	font-size: 18px;
}
.offcanvas-footer a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.form-navbar .custom-input-file input[type="file"] {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
	z-index: 9;
}
.form-navbar .custom-radio {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.form-navbar .custom-radio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.form-navbar .custom-radio .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #fff;
	border: 1px solid #EDE2FF;
	border-radius: 50%;
}
.form-navbar .custom-radio:hover input~.checkmark {
	background-color: #fff;
}
.form-navbar .custom-radio input:checked~.checkmark {
	background-color: #680A83;
	border-color: #680A83;
}
.form-navbar .custom-radio .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
.form-navbar .custom-radio input:checked~.checkmark:after {
	display: block;
}
.form-navbar .custom-radio .checkmark:after {
	top: 5px;
	left: 5px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
.form-navbar .custom-check {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.form-navbar .custom-check input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}


[data-layout-mode=dark] .sidebar,
[data-layout-mode=dark] .sidebar-inner,
[data-layout-mode=dark] .flags-group,
[data-layout-mode=dark] .sidebar .header-left {
    background: #0A0A0A;
}
[data-layout-mode=dark] .submenu .active + ul {
    background: #0A0A0A;
}
[data-layout-mode=dark] .flags-group .unlimited-access {
    background: rgba(97, 0, 154, 0.88);
}

[data-layout-mode=light] .sidebar,
[data-layout-mode=light] .sidebar-inner,
[data-layout-mode=light] .flags-group,
[data-layout-mode=light] .sidebar .header-left {
    background: #fff;
}
[data-layout-mode=light] .flags-group .unlimited-access {
    background: rgba(97, 0, 154, 0.88);
}
[data-layout-mode=light] .header-left.logo-light {
    display: none;
}
[data-layout-mode=light] .header-left.logo-dark {
    display: block;
}
[data-layout-mode=light] .sidebar-menu > ul > li.active a span,
[data-layout-mode=light] .sidebar-menu > ul > li.active:hover a span {
    color: #0A0A0A;
}
[data-layout-mode=light] .sidebar-menu ul li a i {
    background: rgba(65, 65, 65, 0.1);
	color: rgba(45, 7, 57, 1);
}
[data-layout-mode=light] .sidebar-menu li.active a i {
    background: #680A83;
	color: #ffffff;
}
[data-layout-mode=light] .sidebar-menu li a {
	color: #616161;
}
[data-layout-mode=light] .sidebar-menu > ul > li:hover a span {
	color: #0A0A0A;
}
[data-layout-mode=light] .flags-group .flag-nav {
	background: rgba(65, 65, 65, 0.05);
}
[data-layout-mode=light] .flags-group .dropdown-toggle span {
	color: #616161;
}
[data-layout-mode=light] .flags-group .dropdown-toggle:after {
    border-bottom: 2px solid #0A0A0A;
    border-right: 2px solid #0A0A0A;
}
[data-layout-mode=light] .sidebar .sidebar-menu > ul > li:hover a span.badge {
	color: #fff;
}
[data-layout-mode=light] .menu-title {
	color: #757575;
}

[data-layout-mode=light] .sidebar-menu ul ul li a i {
	background: transparent;
	padding: 0;
	width: auto;
	height: auto;
	font-size: 6px;
}
[data-layout-mode=light] .sidebar-menu ul li:hover ul li a i {
	background: transparent;
}
[data-layout-mode=light] .sidebar-menu ul li.active ul li a i {
	background: transparent;
}
[data-layout-mode=light] .sidebar-menu ul li:hover.active ul li a i {
	background: transparent;
}
[data-layout-mode=light] .sidebar-menu ul ul li a i {
    color: #616161;
}
[data-layout-mode=light] .sidebar-menu ul ul a.active,
[data-layout-mode=light] .sidebar-menu ul ul a.active i {
	color: #0A0A0A;
}
[data-layout-mode=light] .sidebar-menu ul li ul li:hover a,
[data-layout-mode=light] .sidebar-menu ul li ul li:hover a i {
    color: #0A0A0A;
}
[data-layout-mode=light] .sidebar-menu > ul > li.active ul li i {
	color: #616161;
}
[data-layout-mode=light] .sidebar-menu > ul > li.active ul li a.active i {
	color: #0A0A0A;
}
[data-layout-mode=light] .submenu .active + ul {
    background: #fff;
}


[data-layout-mode=gray] .sidebar,
[data-layout-mode=gray] .sidebar-inner,
[data-layout-mode=gray] .flags-group,
[data-layout-mode=gray] .sidebar .header-left {
    background: #ededed;
}
[data-layout-mode=gray] .flags-group .unlimited-access {
    background: rgba(97, 0, 154, 0.88);
}
[data-layout-mode=gray] .header-left.logo-light {
    display: none;
}
[data-layout-mode=gray] .header-left.logo-dark {
    display: block;
}
[data-layout-mode=gray] .sidebar-menu > ul > li.active a span,
[data-layout-mode=gray] .sidebar-menu > ul > li.active:hover a span {
    color: #0A0A0A;
}
[data-layout-mode=gray] .sidebar-menu ul li a i {
    background: rgba(65, 65, 65, 0.1);
	color: rgba(45, 7, 57, 1);
}
[data-layout-mode=gray] .sidebar-menu li.active a i {
    background: #680A83;
	color: #ffffff;
}
[data-layout-mode=gray] .sidebar-menu li a {
	color: #616161;
}
[data-layout-mode=gray] .sidebar-menu > ul > li:hover a span {
	color: #0A0A0A;
}
[data-layout-mode=gray] .flags-group .flag-nav {
	background: rgba(65, 65, 65, 0.05);
}
[data-layout-mode=gray] .flags-group .dropdown-toggle span {
	color: #616161;
}
[data-layout-mode=gray] .flags-group .dropdown-toggle:after {
    border-bottom: 2px solid #0A0A0A;
    border-right: 2px solid #0A0A0A;
}
[data-layout-mode=gray] .sidebar .sidebar-menu > ul > li:hover a span.badge {
	color: #fff;
}
[data-layout-mode=gray] .menu-title {
	color: #757575;
}

[data-layout-mode=gray] .sidebar-menu ul ul li a i {
	background: transparent;
	padding: 0;
	width: auto;
	height: auto;
	font-size: 6px;
}
[data-layout-mode=gray] .sidebar-menu ul li:hover ul li a i {
	background: transparent;
}
[data-layout-mode=gray] .sidebar-menu ul li.active ul li a i {
	background: transparent;
}
[data-layout-mode=gray] .sidebar-menu ul li:hover.active ul li a i {
	background: transparent;
}
[data-layout-mode=gray] .sidebar-menu ul ul li a i {
    color: #616161;
}
[data-layout-mode=gray] .sidebar-menu ul ul a.active,
[data-layout-mode=gray] .sidebar-menu ul ul a.active i {
	color: #0A0A0A;
}
[data-layout-mode=gray] .sidebar-menu ul li ul li:hover a,
[data-layout-mode=light] .sidebar-menu ul li ul li:hover a i {
    color: #0A0A0A;
}
[data-layout-mode=gray] .sidebar-menu > ul > li.active ul li i {
	color: #616161;
}
[data-layout-mode=gray] .sidebar-menu > ul > li.active ul li a.active i {
	color: #0A0A0A;
}
[data-layout-mode=gray] .submenu .active + ul {
    background: #ededed;
}

[data-topbar=static] .header {
    position: relative;
}
[data-topbar=static] .page-wrapper {
	padding-top: 0;
}

[data-topbar=hidden] .header {
    background: transparent;
	box-shadow: none;
}
[data-topbar=hidden] .page-wrapper {
	padding-top: 0;
}
[data-topbar=hidden] .user-menu,
[data-topbar=hidden] .top-nav-search {
	display: none;
}
[data-topbar=hidden] .mini-sidebar #toggle_btn {
    left: 50px;
}

[data-layout-style="small"] #toggle_btn {
	left: 90px;
}
[data-layout-style="small"] #toggle_btn i {
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
}
[data-layout-style="small"] .header-left .logo img {
	height: auto;
	max-height: 40px;
	width: auto;
}
[data-layout-style="small"] .sidebar .header-left .logo {
	display: none;
}
[data-layout-style="small"] .header-left .logo.logo-small {
	display: block;
}
[data-layout-style="small"] .sidebar .header-left {
	padding: 0;
	width: 78px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
[data-layout-style="small"] .sidebar .header-left.logo-dark {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
[data-layout-style="small"] .sidebar .header-left.logo-light {
	display: none;
}
[data-layout-style="small"] .sidebar {
	width: 78px;
}
[data-layout-style="small"] .menu-title {
	display: none;
	white-space: nowrap;
}
[data-layout-style="small"] .unlimited-access {
	display: none;
}
[data-layout-style="small"] .flags-group .dropdown-toggle span {
	display: none;
}
[data-layout-style="small"] .menu-title a {
	visibility: hidden;
}
[data-layout-style="small"] .sidebar-menu > ul > li > a {
	justify-content: center;
}
[data-layout-style="small"] .sidebar .sidebar-menu ul > li > a span {
	display: none;
	transition: all 0.2s ease-in-out;
	opacity: 0;
}
[data-layout-style="small"] .expand-menu .sidebar .sidebar-menu ul > li > a span {
	display: inline;
	opacity: 1;
}
[data-layout-style="small"] .sidebar-menu ul  ul {
	display: none !important;
}
[data-layout-style="small"] .page-wrapper {
	margin-left: 78px;
}
[data-layout-style="small"] .flags-group .flag-nav {
	width: 42px;
}
[data-layout-style="small"] .top-nav-search {
    margin-left: 70px;
}
[data-layout-style="small"] .sidebar:hover {
	width: 273px;
	z-index: 9;
}
[data-layout-style="small"] .sidebar:hover .unlimited-access {
	display: block;
}
[data-layout-style="small"] .sidebar:hover .flags-group .dropdown-toggle span {
	display: inline-block;
}
[data-layout-style="small"] .sidebar:hover .menu-title {
	display: block;
}
[data-layout-style="small"] .sidebar:hover .menu-title a {
	visibility: visible;
}
[data-layout-style="small"] .sidebar:hover .sidebar-menu > ul > li > a {
	justify-content: start;
}
[data-layout-style="small"] .sidebar:hover .sidebar-menu ul > li > a span {
	display: inline;
	opacity: 1;
}
[data-layout-style="small"] .sidebar:hover .sidebar-menu > ul > li > a i {
	font-size: 24px;
}
[data-layout-style="small"] .sidebar:hover .flags-group .flag-nav {
	width: auto;
}
[data-layout-style="small"] #toggle_btn i {
    transform: inherit;
}
[data-layout-style="small"] .header {
    margin-left: 78px;
}


.header-left-mobile {
	display:none;
}
.card-table table.dataTable thead th:first-child.sorting_asc:before, 
.card-table table.dataTable thead th:first-child.sorting_desc:after {
	opacity: 0;
}
/*-----------------
	19. Profile-settings
-----------------------*/

.profile-card .card-details {
    padding: 34px 24px;
    border-radius: 15px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    box-shadow: none;
}
.profile-photo {
    border-radius: 160px;
    border: 1px dashed #C2C2C2;
    background: #FFF;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 24px;
}
.profile-photo p {
    font-size: 12px;
    font-weight: 400;
    color: #616161;
    margin-bottom: 0;
}
.profile-upload {
    background: #680A83;
    border-radius: 8px;
    color: #fff;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: ease all 0.5s;
    -webkit-transition: ease all 0.5s;
    -ms-transition: ease all 0.5s;
    display: inline-flex;
    display: -webkit-inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}
.profile-upload:hover {
	background: #921fb3;
}
.profile-upload span img {
    margin-right: 4px;
}
.profile-upload input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.profile-content p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: #616161;
}

/*-----------------
	20. Personal Information
-----------------------*/

.profile-card .person-info {
    padding: 24px 24px 0 24px;
}
.person-profile  {
    margin-bottom: 24px;
}
.person-profile h4 {
    font-size: 20px;
    font-weight: 600;
    color: #0A0A0A;
    margin-bottom: 0px;
}
.inform-content {
    margin-bottom: 24px;
}
.inform-content label {
    font-size: 14px;
    font-weight: 500;
    color: #0A0A0A;
    margin-bottom: 8px;
}
.profile-card .add-details {
    margin-bottom: 40px;
}

.acc-submit {
    text-align: right;
	margin-bottom: 30px;
}
.acc-submit .btn-secondary {
    border-radius: 10px;
    padding: 6px 17px;
}
.acc-submit .btn-primary {
    border-radius: 8px;
    padding: 6px 16px;
}
.acc-submit :nth-child(1) {
    margin-right: 15px;

}

/*-----------------
	21. Website Settings
-----------------------*/

.company-content {
    margin-bottom: 24px;
}
.company-content h6 {
    font-size: 14px;
    font-weight: 500;
    color: #0A0A0A;
    margin-bottom: 8px;
}
.company-content p {
    font-size: 14px;
    font-weight: 400;
    color: #616161;
    margin-bottom: 0;
}
.logo-content {
    position: relative;
    text-align: right;
}
.bg-logo {
	background: #161819;
}
.logo-space {
	max-width: 210px;
    padding: 11px 15px;
    margin-left: auto;
	border-radius: 10px;
	border: 1px solid #919EAB4D;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.upload-space {
	border-radius: 10px;
	border: 1px solid #919EAB4D;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	width: 60px;
	height: 60px;
}
.upload-space i {
	font-size: 20px;
	color: #616161;
}
.logo-delete {
    position: absolute;
    top: -10px;
    right: -5px;
}
.logo-delete a {
    border-radius: 50px;
    background:  #F00;
    width: 26px;
	height: 26px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.logo-delete a i {
	color: #fff;
}

/*-----------------
	22. System Setting
-----------------------*/

.system-set-wrrapper {
    padding: 24px;
    border-radius: 10px;
    border: 1px solid #EDEDED;
    background: #FFF;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.03);
    margin-bottom: 24px;
}
.system-load-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}
.system-set-logo span {
    border-radius: 8px;
    background:  #F8ECFF;
    margin-right: 8px;
    padding: 10px;
}
.system-set-logo span {
    padding: 10px 13px;
}
.system-set-logo h6 {
    margin-bottom: 0;
}
.system-set-logo h6 a {
    font-size: 16px;
    font-weight: 500;
    color: #0A0A0A;
    margin-bottom: 0;
}
.active-switch .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 0;
}
.active-switch .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.active-switch input:checked + .sliders {
    background-color: #00CC45;
	border: 1px solid #00CC45;
}
.active-switch .sliders.round {
    border-radius: 34px;
}
.active-switch .sliders {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #EDEDED;
	border: 1px solid #C2C2C2;
    -webkit-transition: .4s;
    transition: .4s;
}
.active-switch input:checked + .sliders:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
	background-color: #fff;
}
.active-switch input:checked + .sliders:before {
    left: -5px;
}
.active-switch .sliders.round:before {
    border-radius: 50%;
}
.active-switch .sliders:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 1px;
    background-color: #C2C2C2;
    -webkit-transition: .4s;
    transition: .4s;
}
.system-set-wrrapper p {
    font-size: 14px;
    color: #616161;
    font-weight: 400;
    margin-bottom: 24px;
}
.set-view a {
	display: inline-flex;
	font-size: 15px;
	font-weight: 600;
}
.set-view i {
	transform: rotate(-90deg);
    font-size: 18px;
}
.set-view span img {
    margin-right: 4px;
}
.set-view span {
    color:#616161;
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0;
}
.set-view .btn-secondary {
	border: 1px solid #E0E0E0 !important;
	color: #616161;
    padding: 8px 21px;
}
.set-view .btn-secondary:hover {
	border: 1px solid #680a83 !important;
	background: #680a83 !important;;
	box-shadow: 0 50px 0 0 #680a83 inset;
	color: #fff;
}
.submit-set-system {
    margin-top: 26px;
}
.Set-google-capt {
    padding: 40px;
    background: #fff;
    border-radius: 23px;
}
.captcha-header {
    margin-bottom: 32px;
}
.captcha-header h4 {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 0;
}
.captcha-header span a {
    border-radius: 38px;
    background: #FFE8E9;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal {
    background: rgba(0, 0, 0, 0.23);
    backdrop-filter: blur(7px);
}
.captcha-header span a i {
    color: #DE3E44;
}
.modaled-body form label {
    font-size: 14px;
    font-weight: 500;
    color: #0A0A0A;
    margin-bottom: 8px;
}
.modaled-body form label span {
    color: #DE3E44;
}
.modaled-body form input {
    margin-bottom: 14px;
}
.acc-submited {
    margin-top: 18px;
}
.acc-submited .btn-secondary {
    margin-left: 15px;
    border-radius: 10px;
    padding: 5px 20px;
	min-height: 40px;
}
.acc-submited .btn-primary {
    border-radius: 8px;
    padding: 6px 20px;
}

/*-----------------
	23. Notification Setting
-----------------------*/

.noti-header {
    border-radius: 15px;
    margin-bottom: 24px;
}
.noti-header.card {
	border: 1px solid #E0E0E0;
	box-shadow: none;
}
.noti-wrapper {
    margin-bottom: 12px;
}
.noti-wrapper h6 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
}
.table-content h6 {
    margin-bottom: 0;
}
.customize-select .select2-container  {
    width: 320px !important;
    border-radius: 10px;
    z-index: 0;
}
.otp-custom .select2-container  {
    width: 245px !important;
}

.wrapp-set-system {
    margin-top:40px ;
}
.appearance-settings {
	display: inline-block;
}

/*-----------------
	24. Localization
-----------------------*/

.local-wrapper .local-set-head {
    margin-bottom: 20px;
}
.local-wrapper .local-set-head h6{
  font-weight: 500;
  margin-bottom: 4px;
}
.local-wrapper .local-set-head p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: #616161;
}

.social-connect  {
    border-radius: 5px;
    border: 1px solid #B5D0C3;
    background: #E7FAF2;
    padding: 3px 6px;
}
.social-connect.dis-conect  {
    border: 1px solid #E2B6B6;
    background: #FFE8E9;
}
.social-connect.dis-conect p {
	color: #DE3E44;
}
.social-connect p {
    font-size: 14px;
    font-weight: 500;
    color: #1BA345;
    margin-bottom: 0;
}
.fac-key {
    margin-bottom: 8px !important;
}
p.fac-goo {
    margin-bottom: 14px;
    color: #616161;
    font-size: 12px;
    font-weight: 400;
}
p.fac-goo span {
    color: #680A83;
    cursor: pointer;
}

/*-----------------
	25. Appearance
-----------------------*/

.theme-image .theme-image-set h6 {
    margin: 20px 0 0 0;
    color: #333;
    font-size: 16px;
    font-weight: 500;
}
.appear-set {
    margin-bottom: 24px;
}
.form-group-set {
    margin-bottom: 24px;
}
.form-group-set h6 {
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.form-group-set p {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 400;
    color: #616161;
}
.appearance-settings-wrapper {
    border-radius: 15px;
    border: 1px solid #E0E0E0;
    background:  #FFF;
    padding: 24px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #680A83;
}
.themecolorset {
    position: relative;
    cursor: pointer;
}
.themecolor ul {
    display: flex;
    flex-wrap: wrap;
}
.themecolor ul li {
    margin-right: 5px;
    width: 27px;
}
.themecolorset {
    position: relative;
    border: 1px solid transparent;
    width: 27px;
    height: 27px;
    display: block;
    border-radius: 50px;
}
.themecolorset::after {
    background: #4C40ED;
    width: 25px;
    height: 25px;
    display: block;
    border-radius: 50px;
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
}
.themecolorset.active {
	border: 1px solid #475467;
}
.active.themecolorset::after {
    width: 23px;
    height: 23px;
    top: 1px;
    left: 1px;
}
.theme-greens::after {
    background: #22FF9D;;
}
.theme-yellow::after {
    background: #FFC33B;
}
.theme-pink::after {
    background: #FF5982;
}
.theme-viloets::after {
    background: #8431D9;
}
.theme-orange::after {
    background: #D26400;
}
.theme-blue::after {
    background: #26A7FF;
}
.theme-dark-green::after {
    background: #1BBE00;
}
.theme-skyblue::after {
    background: #0FF;
}
.theme-gradient::after {
    background: linear-gradient(177deg, #40BE54 -13.04%, #00AEEB 123.56%);
}
.theme-purple::after {
    background: #95A4FC;
}
.theme-light-blue::after {
    background: #00AEEB;
}
.theme-image {
    position: relative;
}
.theme-image-set img {
    width: 100%;
    border-radius: 20px;
    border: 2px solid transparent;
}
.active .theme-image-set img {
    border: 3px solid #00AEEB;
    background: #F3F5F7;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	
}
.theme-image-set img { 
	cursor: pointer;
}
.auth-set-content {
    margin-bottom: 28px;
}
.auth-set-content .local-set-head {
    margin-bottom: 0;
}
.auth-set-content .local-set-head h6 {
    margin-bottom: 0;
    font-size: 16px;
}
.nav-store {
    padding: 24px;
}
.nav-store .store-logo span {
    margin-right: 10px;
}
.settings-view a {
    border-radius: 50px;
    padding: 8px;
    align-items: center;
    justify-content: center;
}
.settings-view a:hover {
	background: #F8ECFF;
}
.settings-view a i {
	font-size: 18px;
}
.aws-setting {
    display: flex;
    align-items: center;
}
.aws-setting .aws-act-set {
    margin: 0 10px;
}
.header-left.logo-dark {
	display: none;
}

/*-----------------
  4. Login
-----------------------*/

.login-form {
    padding-left: 0;
	background: #F8F6FA;
}
.login-form::before {
	content: "";
    /* background-image: url(../../assets/img/login-bg.png); */
    background-repeat: no-repeat, no-repeat;
    position: absolute;
    width: 100%;
    height: 462px;
    bottom: 0;
	z-index: -1;
	background-size: cover;
}
.main-wrapper.register-surv {
    overflow: auto;
}
.login-form .main-wrapper {
	height: 100vh;
	display: flex;
	display: -webkit-flex;
	overflow: hidden;
	visibility: visible;
}

.login-wrapper {
  	padding: 70px 0;
	width: 100%;
	margin: 0 auto;
	padding: 30px 0;
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	height: 100%;
}

.login-group-left {
	height: 100vh;
    overflow-y: auto;
    width: 100%;
    padding-bottom: 20px;
}

.login-wrapper .logo-header {
  	width: 100%;
  	margin: 0 0 40px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-wrapper .logo-header a.logo-brand .logo-dark {
 	 margin: 0px 12px 0 0;
}

.login-wrapper .logo-header a.logo-brand h5 {
  	margin: 0;
  	color: #680A83;
  	text-transform: capitalize;
}

.login-wrapper .login-inbox {
 	margin: 0 auto;
	max-width: 542px;
}
.login-wrapper .login-inbox.admin-login {
	margin: 0 auto;
   	min-width: 498px;
}

.login-wrapper .login-inbox .log-auth {
	border-radius: 20px;
	background: #ffffff;
	box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.03);
	padding: 40px;
	margin-bottom: 30px;
}

.login-wrapper.lock-screen .login-inbox .log-auth {
	background: transparent;
	box-shadow: none;
	padding: 0;
}

.lock-screen-user h6 {
	color: #0A0A0A;
	font-size: 16px;
	font-weight: 500;
}

.lock-screen-user span {
	color: #424242;
	font-size: 16px;
	font-weight: 500;
}

.login-wrapper .login-inbox .log-auth .login-auth-wrap {
  margin: 0 0 30px 0;
}

.login-wrapper .login-inbox .log-auth .login-auth-wrap .login-content-head h3 {
  margin: 0 0 12px 0;
  text-align: center;
}

.login-wrapper .login-inbox .log-auth .login-auth-wrap .login-content-head p {
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.login-wrapper .login-inbox .log-auth .phone-login span {
  color: #1F2937;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.login-wrapper .login-inbox .log-auth .phone-login span a {
  margin: 0 0 0 5px;
  color: #53BDEB;
}

.login-wrapper .login-inbox .log-auth .phone-login span a:hover {
	color: #650681;
}

.login-wrapper .login-inbox .log-auth form .form-group {
  margin: 0 0 18px 0;
}

.login-wrapper .login-inbox .log-auth form .form-group label {
  margin: 0 0 8px 0;
  color: #0A0A0A;
  font-size: 14px;
  font-weight: 500;
}

.login-wrapper .login-inbox .log-auth form .form-group label span {
  color: #DE3E44;
}

.login-wrapper .login-inbox .log-auth form .form-group .pass-group {
  position: relative;
}

.login-wrapper .login-inbox .log-auth form .form-group .pass-group .toggle-password {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  font-weight: 500;
}

.login-wrapper .login-inbox .log-auth form .form-group .pass-group .toggle-passwords {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    font-weight: 500;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-control {
  min-height: 40px;
  font-size: 14px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-control:focus {
  box-shadow: none;
  outline: 0 none;
  border-color: #E0E0E0;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check {
  margin: 20px 0 0;
  padding: 0 0 0 28px;
  color: #8A909A;
  display: inline-block;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  user-select: none;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check input:checked ~ .checkmark {
  background-color: #680A83;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check input:checked ~ .checkmark::after {
  display: block;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check .remember-me {
  color: #0A0A0A;
  font-size: 16px;
  font-weight: 400;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid #E3E3E3;
  background-color: #FFF;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: -1px;
  left: 3px;
  display: none;
  color: #FFF;
  font-size: 12px;
}

.login-wrapper .login-inbox .log-auth form .form-group .forget-pass a {
  color: #650681;
}

.login-wrapper .login-inbox .log-auth form .form-group .forget-pass a:hover {
	color: #53BDEB;
}

.login-wrapper .login-inbox .log-auth form .form-group:nth-child(2) {
  margin-bottom: 14px;
}

.login-wrapper .login-inbox .log-auth form .form-group:nth-child(3) {
  margin-bottom: 30px;
}

.login-wrapper .login-inbox .login-or {
  margin: 20px 0;
  text-align: center;
  position: relative;
  z-index: 1;
}

.login-wrapper .login-inbox .login-or .span-or-log {
  color: #424242;
  font-size: 15px;
  font-weight: 400;
  background-color: #ffffff;
  padding: 0 18px;
}

.login-wrapper .login-inbox .login-or .span-or-log::after {
  content: "";
  border-top: 1px solid #E0E0E0;
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  top: 11px;
  z-index: -1;
}

.login-wrapper .login-inbox .social-login {
  margin-bottom: 50px;
}

.login-wrapper .login-inbox .social-login li {
  border-radius: 6px;
  border: 2px solid #E0E0E0;
  padding: 14px 16px;
  width: 100%;
}

.login-wrapper .login-inbox .social-login li + li {
  margin-left: 12px;
}

.login-wrapper .login-inbox .social-login li a img {
  margin-right: 10px;
}

.login-wrapper .login-inbox .bottom-text p {
  color: #0A0A0A;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-right: 5px;
}

.login-wrapper .login-inbox .bottom-text p a {
  color: #650681;
  font-weight: 500;
  text-decoration: underline;
}

.login-wrapper .login-inbox .bottom-text p a:hover {
	color: #53BDEB;
}

.css-equal-heights, .css-equal-content {
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}
  
.css-equal-content {
	color: red;
	float: left;
}
  
.login-wrapper .logo-header a.logo-brand, .align-center {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}
  
.login-wrapper .login-inbox .log-auth .login-auth-wrap, .btn-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
}
  
.login-wrapper .login-inbox .log-auth .login-auth-wrap {
	-webkit-box-pack: space-between;
	-ms-flex-pack: space-between;
	justify-content: space-between;
}

.login-content-info {
	width: 100%;
}




/*-----------------
  5. Login Otp
-----------------------*/

.login-verify-img {
	margin-bottom: 25px;
  }
  
  .login-title {
	margin-bottom: 40px;
  }
  
  .login-title h3 {
	margin-bottom: 20px;
  }
  
  .login-title p {
	color:#424242;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 0;
  }
  
  .login-title p span {
	color: #0A0A0A;
  }
  
  form .otp-box {
	margin-bottom: 34px;
  }
  
  form .otp-box input {
	width: 74px;
	height: 74px;
	background: #F5F5F5 ;
	border: 2px solid #680A83;
	border-radius: 8px;
	text-align: center;
	margin-right: 15px;
	font-weight: 700;
	font-size: 26px;
	color: #680A83;
	outline: none;
  }
  
  form .otp-box input::placeholder {
	color: #680A83;
  }
  
  form .otp-box input:last-child {
	margin-right: 0px;
  }
  
  .forms-block .otp-info {
	margin-bottom: 50px;
  }
  
  .forms-block .otp-info .otp-code p {
	margin-right: 8px;
	font-size: 16px;
	font-weight: 400;
	color: #0A0A0A;
  }
  
  .forms-block .otp-info .otp-code a {
	color: #53BDEB;
  }
  
  .forms-block .otp-info .otp-sec p i.feather-clock {
	margin-right: 5px;
  }
  
  .forms-block .otp-info .otp-sec p {
	background: #F8ECFF;
	color: #680A83;
	border-radius: 6px;
	margin-bottom: 0px;
  }
  .theme-image [data-bs-theme="dark"] h6 {
	color: #C2C2C2;
  }
  
  .reset-group {
	  margin-bottom: 30px !important;
  }
  
  .form-group .password-strength {
	  display: flex;
	  margin-top: 14px;
  }
  
  .form-group .password-strength span {
	  width: 100%;
	  height: 5px;
	  background: #E3E3E3;
	  margin-right: 10px;
	  border-radius: 5px;
  }
  
  .form-group .password-strength span:last-child {
	  margin-right: 0;
  }
  
  .poor-active span.active#poor, 
  .poor-active span.active#poors {
	  background: #FF0000;
  }
  
  .avg-active span.active#poor, .avg-active span.active#weak,
  .avg-active span.active#poors, .avg-active span.active#weaks {
	  background: #FFB54A;
  }
  
  .strong-active span.active#poor, .strong-active span.active#weak, .strong-active span.active#strong,
  .strong-active span.active#poors, .strong-active span.active#weaks, .strong-active span.active#strongs {
	  background: #1D9CFD;
  }
  
  .heavy-active span.active#poor, .heavy-active span.active#weak, .heavy-active span.active#strong, .heavy-active span.active#heavy,
  .heavy-active span.active#poors, .heavy-active span.active#weaks, .heavy-active span.active#strongs, .heavy-active span.active#heavys {
	  background: #159F46;
  }
  
  input[type=password]::-ms-reveal,
  input[type=password]::-ms-clear
  {
	  display: none;
  }
  .success-pass {
    margin-bottom: 40px;
    text-align: center;
}

/*-----------------
	26. Darkmode
-----------------------*/

[data-bs-theme="dark"] body  {
	background: #1c1d1e;
}
[data-bs-theme="dark"] .mobile_btn i {
	color: #fff;
}
[data-bs-theme="dark"] .notification-group.user-list .user-list-item {
	background: #EDEDED0D;
    border: 1px solid #EDEDED0D;
}
[data-bs-theme="dark"] .notification-group.user-list .user-list-item .users-list-body .name-list-user h5 {
	color: #fff;
}
 [data-bs-theme="dark"] .card {
	box-shadow: 0px 7.5px 17.5px 0px #0000000D;
	background: #252728;
	border: 0;
}
 [data-bs-theme="dark"] .header,
 [data-bs-theme="dark"] .dash-widget-icon {
	background: #161819;
}
 [data-bs-theme="dark"] .top-nav-search .form-control {
	background: #343637;
	color: #E0E0E0;
}
 [data-bs-theme="dark"] .top-nav-search .btn {
	color: #9E9E9E;
}
 [data-bs-theme="dark"] .user-detail .drop-user-name,
 [data-bs-theme="dark"] .user-menu.nav > li > a > i {
	color: #fff;
}
 [data-bs-theme="dark"] .user-detail .drop-user-role {
	color: #c2c2c2;
}
 [data-bs-theme="dark"] .user-menu.nav > li > a > i:hover {
	color: #E0E0E0;
}
 [data-bs-theme="dark"] .dark-option {
	background: #252728;
}
 [data-bs-theme="dark"] .dark-option a i,
 [data-bs-theme="dark"] .count-title,
 [data-bs-theme="dark"] h6,
 [data-bs-theme="dark"] .user-menu .dropdown-menu .dropdown-item,
 [data-bs-theme="dark"] .invite-user-list .table-avatar a {
    color: #C2C2C2 !important;
}
 [data-bs-theme="dark"] .dropdown-menu {
	background: #161819;
	border: 0;
	box-shadow: 0px 10px 8px 0px #0000000A;
	
}
 [data-bs-theme="dark"] .dash-count .count,
 [data-bs-theme="dark"] .page-header h4,
 [data-bs-theme="dark"] .card .card-header .card-title,
 [data-bs-theme="dark"] table.table td h2 a {
	color: #fff;
}
 [data-bs-theme="dark"] .user-menu .dropdown-menu .dropdown-item {
	border-top: 1px solid rgba(78, 80, 114, 0.3);
}
 [data-bs-theme="dark"] .card .card-header {
	background: #252728;
}
 [data-bs-theme="dark"] .table thead tr th {
	background: #1C1D1E;
	color: #fff;
}
 [data-bs-theme="dark"] .table {
	border: 1px solid #424242;
}
 [data-bs-theme="dark"] .table tbody tr td {
	background: #252728;
	color: #C2C2C2;
}
 [data-bs-theme="dark"] .table tbody tr,
 [data-bs-theme="dark"] .table thead tr,
 [data-bs-theme="dark"] .table thead tr th,
 [data-bs-theme="dark"] .notifications ul.notification-list > li,
 [data-bs-theme="dark"] .topnav-dropdown-header {
	border-bottom: 1px solid #424242;
}
 [data-bs-theme="dark"] .invite-check-user .check-user {
	border: 1px solid #34A853;
	background: rgba(52, 168, 83, 0.2);
}
 [data-bs-theme="dark"] .invite-check-user .check-user:hover {
	background: #34A853;
}
 [data-bs-theme="dark"] .invite-check-user .uncheck-user {
    background: rgba(255, 0, 10, 0.2);
    border: 1px solid #C8102E;
}
 [data-bs-theme="dark"] .invite-check-user .uncheck-user:hover {
	background: #C8102E;
}
 [data-bs-theme="dark"] .select2-container--default .select2-selection--single {
    background-color: #343637;
	border: 0;
}
 [data-bs-theme="dark"] .select-clendear-icon,
 [data-bs-theme="dark"] .topnav-dropdown-header .notification-title,
 [data-bs-theme="dark"] .noti-title,
 [data-bs-theme="dark"] .topnav-dropdown-footer a {
	color: #FFFFFF;
}
 [data-bs-theme="dark"] .select2-container--default .select2-results>.select2-results__options {
	background: #1C1D1E;
}
 [data-bs-theme="dark"] .select2-dropdown {
	border: 1px solid rgba(78, 80, 114, 0.3);
	border-top: 0;
	background-color: transparent;
}
 [data-bs-theme="dark"] .sidebar,
 [data-bs-theme="dark"] .sidebar-inner,
 [data-bs-theme="dark"] .flags-group,
 [data-bs-theme="dark"] .sidebar .header-left {
    background: #161819;
}
 [data-bs-theme="dark"] .submenu .active + ul {
    background: #161819;
}
 [data-bs-theme="dark"] .flags-group .unlimited-access {
    background: rgba(97, 0, 154, 0.88);
}
 [data-bs-theme="dark"] .notifications ul.notification-list > li a:hover {
	background: #252728;
}
 [data-bs-theme="dark"] #toggle_btn {
	background: #6B0887;
}
 [data-bs-theme="dark"] #toggle_btn i {
	color: #fff;
}
 [data-bs-theme="dark"] .table-top {
	border-bottom: 1px solid rgba(78, 80, 114, 0.3);
}
 [data-bs-theme="dark"] .select2-container--default .select2-selection--single .select2-selection__rendered,
 [data-bs-theme="dark"] .table-top ul li i {
	color: #9E9E9E;
}
 [data-bs-theme="dark"] .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #9E9E9E;
}
 [data-bs-theme="dark"] .page-header .btn-white {
	border: 1px solid #6B0887;
	background: rgba(104, 10, 131, 0.2);
	color: #fff;
}
 [data-bs-theme="dark"] .page-header .btn-white:hover { 
	background: #6B0887;
}
 [data-bs-theme="dark"] .page-header .btn-white.file-pdf {
	border: 1px solid #C8102E;
	background: rgba(255, 0, 10, 0.1);
}
 [data-bs-theme="dark"] .page-header .btn-white.file-text {
	border: 1px solid #34A853;
	background: rgba(52, 168, 83, 0.1);
}
 [data-bs-theme="dark"] .page-header .btn-white.file-text i {
	color: #34A853;
}
 [data-bs-theme="dark"] .page-header .btn-white.file-pdf i {
	color: #C8102E;
}
 [data-bs-theme="dark"] .form-control,
 [data-bs-theme="dark"] .search-set .search-input input[type=search] {
	background: #343637;
	border: 0;
	color: #E0E0E0;
}
 [data-bs-theme="dark"] .form-control::-webkit-input-placeholder {
	color: #9E9E9E;
	opacity: 1;
}
 [data-bs-theme="dark"] .table-top ul li .pass-login .date-range {
	color: #9E9E9E;
}
 [data-bs-theme="dark"] .refine-filter {
	background: #343637;
	color: #9E9E9E;
}
 [data-bs-theme="dark"] .btn-light {
	color: #616161;
}
 [data-bs-theme="dark"] .checkboxs input ~ .checkmarks {
	background: #424242;
}
 [data-bs-theme="dark"] .checkboxs input:checked ~ .checkmarks {
    background-color: #680A83;
}
 [data-bs-theme="dark"] .table-dropdown .dropdown-item,
 [data-bs-theme="dark"] .profile-content p,
 [data-bs-theme="dark"] .profile-photo p,
 [data-bs-theme="dark"] .company-content p,
 [data-bs-theme="dark"] .system-set-wrrapper p,
 [data-bs-theme="dark"] .local-wrapper .local-set-head p,
 [data-bs-theme="dark"] .appear-set p,
 [data-bs-theme="dark"] .form-group-set p,
 [data-bs-theme="dark"] p.fac-goo,
 [data-bs-theme="dark"] .settings-view a i,
 [data-bs-theme="dark"] .sidebar-headerset h3,
 [data-bs-theme="dark"] .screen-mode-grp p,
 [data-bs-theme="dark"] .form-navbar .custom-radio {
	color: #9E9E9E;
}
 [data-bs-theme="dark"] p.fac-goo span {
    color: #680A83;
}
 [data-bs-theme="dark"] .inform-content label,
 [data-bs-theme="dark"] .modaled-body form label,
 [data-bs-theme="dark"] .settings-mains .layout-head h5,
 [data-bs-theme="dark"] .head-layout,
 [data-bs-theme="dark"] .screen-mode-grp h5,
 [data-bs-theme="dark"] .settings-mains .layout-head h5 {
	color: #E0E0E0;
}
 [data-bs-theme="dark"] .table-dropdown .dropdown-item:hover {
	background: #343637;
}
 [data-bs-theme="dark"] .paginate_button.page-item.previous a, 
 [data-bs-theme="dark"] .paginate_button.page-item.next a {
	background: #252728;
	border: 1px solid rgba(78, 80, 114, 0.3);
}
 [data-bs-theme="dark"] .profile-photo {
	background: #252728;
	border: 1px dashed #9E9E9E;
}
 [data-bs-theme="dark"] .person-profile h4 {
	color: #fff;
}
 [data-bs-theme="dark"] .btn-secondary {
    color: #C8102E;
    background: rgba(255, 0, 10, 0.1)!important;
    border-color: #C8102E!important;
    border: 2px solid #C8102E!important;
}
 [data-bs-theme="dark"] .btn-secondary:hover {
    box-shadow: 0 50px 0 0 #C8102E inset;
	background: #C8102E!important;
    color: #fff;
}
 [data-bs-theme="dark"] .system-set-wrrapper {
	background: #252728;
	border: 1px solid rgba(78, 80, 114, 0.3);
}
 [data-bs-theme="dark"] .system-set-logo h6 a,
 [data-bs-theme="dark"] .pass-login label {
	color: #E0E0E0;
}
 [data-bs-theme="dark"] .set-view .btn-secondary {
    border: 1px solid rgba(78, 80, 114, 0.3) !important;
    color: #9E9E9E;
    padding: 8px 21px;
	background: #343637!important;
}
 [data-bs-theme="dark"] .set-view .btn-secondary:hover {
	box-shadow: 0 50px 0 0 rgba(78, 80, 114, 0.3) inset;
    background: rgba(78, 80, 114, 0.3)!important;
    color: #fff;
}
 [data-bs-theme="dark"] .Set-google-capt,
 [data-bs-theme="dark"] .appearance-settings-wrapper,
 [data-bs-theme="dark"] .nav-menus,
 [data-bs-theme="dark"] .modal-content {
	background: #252728;
	border: 1px solid rgba(78, 80, 114, 0.3);
}
 [data-bs-theme="dark"] .captcha-header h4,
 [data-bs-theme="dark"] .modal-header .modal-title {
	color: #fff;
}
 [data-bs-theme="dark"] .captcha-header span a,
 [data-bs-theme="dark"] .modal-header .close {
    background: rgba(255, 0, 10, 0.1);
    color: #C8102E;
}
 [data-bs-theme="dark"] .settings-view a:hover {
    background: #161819;
}
 [data-bs-theme="dark"] .system-set-logo span {
	background: #1C1D1E;
}
 [data-bs-theme="dark"] .social-connect {
	border: 1px solid #34A853;
	background:  rgba(52, 168, 83, 0.1);
}
 [data-bs-theme="dark"] .social-connect p {
	color: #34A853;
}
[data-bs-theme="dark"] .social-connect.dis-conect  {
    border: 1px solid #E2B6B6;
    background: #FFE8E9;
}
[data-bs-theme="dark"] .social-connect.dis-conect p {
	color: #DE3E44;
}
 [data-bs-theme="dark"] .table-active.bg-success {
	background-color: rgba(52, 168, 83, 0.1) !important;
}
 [data-bs-theme="dark"] .action-web-list ul li a,
 [data-bs-theme="dark"] .action-web-list ul li a.action-download {
	border: 1px solid #4E50724D;
	background: rgba(158, 158, 158, 0.1);
	color: #9E9E9E;
}
 [data-bs-theme="dark"] .action-web-list ul li a:hover {
	background: #1c1d1e;
}
 [data-bs-theme="dark"] .action-web-list ul li a.action-trash {
    background-color: transparent;
    color: #C8102E;
    border: 0;
}
 [data-bs-theme="dark"] .language-blk h2 a,
 [data-bs-theme="dark"] .layout-blk ul li a {
	background: #343637;
	border: 1px solid rgba(78, 80, 114, 0.3);
	color: #9E9E9E;
}
 [data-bs-theme="dark"] .bg-danger-light {
    background-color: rgba(255, 0, 10, 0.1) !important;
    color: #C8102E !important;
    border: 1px solid #C8102E;
}
 [data-bs-theme="dark"] .bg-danger-light:hover {
	background-color: #C8102E!important;
	color: #fff!important;
}
 [data-bs-theme="dark"] .bg-gray-light {
    background-color: rgba(104, 10, 131, 0.2) !important;
    color: #680A83 !important;
    border: 1px solid #680A83;
}
 [data-bs-theme="dark"] .bg-gray-light:hover {
	background-color: #680A83!important;
	color: #fff!important;
}
 [data-bs-theme="dark"] .layout-blk ul li a.active {
    background: rgba(104, 10, 131, 0.2);
    color: #fff;
}
 [data-bs-theme="dark"] .bg-success-light {
    background-color: rgba(52, 168, 83, 0.2) !important;
    color: #34A853 !important;
    border: 1px solid #34A853;
}
 [data-bs-theme="dark"] .bg-success-light:hover {
	background-color: #34A853!important;
	color: #fff!important;
}
 [data-bs-theme="dark"] .offcanvas {
	background: #252728;
	box-shadow: 0px 0px 29px 0px #00000017;
}
 [data-bs-theme="dark"] h2 {
	color: #fff;
}
 [data-bs-theme="dark"] .flags-group .flag-nav .dropdown-item {
	color: #e0e0e0;
}
 [data-bs-theme="dark"] .flags-group .flag-nav .dropdown-item:hover {
	background: #343637;
}
 [data-bs-theme="dark"] .flags-group .flag-nav .dropdown-menu {
	border: 1px solid rgba(78, 80, 114, 0.3);
	border-top: 0;
}
 [data-bs-theme="dark"] .daterangepicker {
	background: #161819;
	border: 1px solid rgba(78, 80, 114, 0.3);
	border-top: 0;
}
 [data-bs-theme="dark"] .daterangepicker .ranges li {
	color: #e0e0e0;
}
 [data-bs-theme="dark"] .daterangepicker .ranges li:hover {
	background-color: #343637;
}
 [data-bs-theme="dark"] .daterangepicker:after {
	border-bottom: 6px solid #161819;
}
 [data-bs-theme="dark"] .daterangepicker:before {
    border-bottom: 7px solid rgba(78, 80, 114, 0.3);
}
 [data-bs-theme="dark"] .daterangepicker.show-ranges.ltr .drp-calendar.left {
    border-left: 1px solid rgba(78, 80, 114, 0.3);
}
 [data-bs-theme="dark"] .daterangepicker .calendar-table {
    border: 1px solid rgba(78, 80, 114, 0.3);
    border-radius: 4px;
    background-color: #343637;
}
 [data-bs-theme="dark"] div.dataTables_wrapper div.dataTables_info {
	color: #e0e0e0;
}
 [data-bs-theme="dark"] .daterangepicker td.off, 
 [data-bs-theme="dark"] .daterangepicker td.off.in-range, 
 [data-bs-theme="dark"] .daterangepicker td.off.start-date, 
 [data-bs-theme="dark"] .daterangepicker td.off.end-date {
    background-color: #161819;
    border-color: transparent;
    color: #e0e0e0;
}
[data-bs-theme="dark"] .section-header .section-title {
	color: #fff;
}
[data-bs-theme="dark"] .comp-section .card .card-header,
[data-bs-theme="dark"] .comp-section .modal-header,
[data-bs-theme="dark"] .nav-tabs {
    border-bottom: 1px solid #424242;
}
[data-bs-theme="dark"] .card-footer {
	background: #252728;
	border-top: 1px solid #424242;
}
[data-bs-theme="dark"] .comp-section .modal-footer {
	border-top: 1px solid #424242;
}
[data-bs-theme="dark"] .nav-tabs.nav-tabs-solid {
    background-color: #161819;
}
[data-bs-theme="dark"] .nav-tabs.nav-tabs-solid > li > a {
	color: #fff;
}
[data-bs-theme="dark"] .nav-tabs .nav-link:focus, 
[data-bs-theme="dark"] .nav-tabs .nav-link:hover {
    color: #333;
}
[data-bs-theme="dark"] .comp-section h4,
[data-bs-theme="dark"] .comp-section h1,
[data-bs-theme="dark"] .comp-section h3,
[data-bs-theme="dark"] .comp-section h5 {
	color: #fff;
}
[data-bs-theme="dark"] .input-group-text {
	border: 0;
}
[data-bs-theme="dark"] .btn-white {
    background-color: #2b3035;
    border: 0;
    color: #fff;
}

[data-layout-mode=light][data-bs-theme="dark"] .flags-group .flag-nav .dropdown-menu {
    border: 1px solid #9e9e9e;
    border-top: 0;
	background: #fff;
	box-shadow: none;
}
[data-layout-mode=light][data-bs-theme="dark"] .flags-group .flag-nav .dropdown-item {
    color: #0A0A0A;
}
[data-layout-mode=light][data-bs-theme="dark"] .flags-group .flag-nav .dropdown-item:hover {
    background: #9e9e9e;
}

[data-layout-mode=light][data-bs-theme="dark"] .sidebar,
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-inner,
[data-layout-mode=light][data-bs-theme="dark"] .flags-group,
[data-layout-mode=light][data-bs-theme="dark"] .sidebar .header-left {
    background: #fff;
}
[data-layout-mode=light][data-bs-theme="dark"] .flags-group .unlimited-access {
    background: rgba(97, 0, 154, 0.88);
}
[data-layout-mode=light][data-bs-theme="dark"] .header-left.logo-light {
    display: none;
}
[data-layout-mode=light][data-bs-theme="dark"] .header-left.logo-dark {
    display: block;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu > ul > li.active a span,
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu > ul > li.active:hover a span {
    color: #0A0A0A;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu ul li a i {
    background: rgba(65, 65, 65, 0.1);
	color: rgba(45, 7, 57, 1);
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu li.active a i {
    background: #680A83;
	color: #ffffff;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu li a {
	color: #616161;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu > ul > li:hover a span {
	color: #0A0A0A;
}
[data-layout-mode=light][data-bs-theme="dark"] .flags-group .flag-nav {
	background: rgba(65, 65, 65, 0.05);
}
[data-layout-mode=light][data-bs-theme="dark"] .flags-group .dropdown-toggle span {
	color: #616161;
}
[data-layout-mode=light][data-bs-theme="dark"] .flags-group .dropdown-toggle:after {
    border-bottom: 2px solid #0A0A0A;
    border-right: 2px solid #0A0A0A;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar .sidebar-menu > ul > li:hover a span.badge {
	color: #fff;
}
[data-layout-mode=light][data-bs-theme="dark"] .menu-title {
	color: #757575;
}

[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu ul ul li a i {
	background: transparent;
	padding: 0;
	width: auto;
	height: auto;
	font-size: 6px;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu ul li:hover ul li a i {
	background: transparent;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu ul li.active ul li a i {
	background: transparent;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu ul li:hover.active ul li a i {
	background: transparent;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu ul ul li a i {
    color: #616161;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu ul ul a.active,
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu ul ul a.active i {
	color: #0A0A0A;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu ul li ul li:hover a,
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu ul li ul li:hover a i {
    color: #0A0A0A;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu > ul > li.active ul li i {
	color: #616161;
}
[data-layout-mode=light][data-bs-theme="dark"] .sidebar-menu > ul > li.active ul li a.active i {
	color: #0A0A0A;
}
[data-layout-mode=light][data-bs-theme="dark"] .submenu .active + ul {
    background: #fff;
}

[data-layout-mode=blue][data-bs-theme="dark"] .sidebar,
[data-layout-mode=blue][data-bs-theme="dark"] .sidebar-inner,
[data-layout-mode=blue][data-bs-theme="dark"] .flags-group,
[data-layout-mode=blue][data-bs-theme="dark"] .sidebar .header-left {
    background: #650681;
}
[data-layout-mode=blue][data-bs-theme="dark"] .submenu .active + ul {
    background: #650681;
}
[data-layout-mode=blue][data-bs-theme="dark"] .flags-group .unlimited-access {
	background: rgba(45, 7, 57, 0.7);
}
[data-layout-mode=hidden][data-bs-theme="dark"] .header {
	background: transparent;
    box-shadow: none;
}
[data-layout-mode=hidden][data-bs-theme="dark"] .page-wrapper {
	padding-top: 70px;
}


/*-----------------
	26. Responsive
-----------------------*/

@media only screen and (min-width:768px) {
	.avatar-xxl {
		width: 8rem;
		height: 8rem;
	}
	.avatar-xxl .border {
		border-width: 4px !important;
	}
	.avatar-xxl .rounded {
		border-radius: 12px !important;
	}
	.avatar-xxl .avatar-title {
		font-size: 42px;
	}
	.avatar-xxl.avatar-away:before,
	.avatar-xxl.avatar-offline:before,
	.avatar-xxl.avatar-online:before {
		border-width: 4px;
	}
}

@media only screen and (min-width: 992px) {
	#toggle_btn {
		align-items: center;
		color: #680A83;
		display: inline-flex;
		float: left;
		font-size: 20px;
		justify-content: center;
		margin-left: 15px;
		width: 26px;
		height: 26px;
		background: #F2DDFF;
		border-radius: 8px;
		position: fixed;
		top: 23px;
		left: 215px;
		z-index: 9;
	}
	#toggle_btn i {
		font-size: 16px;
		color: #680A83;
	}
	#toggle_btn:hover i {
		color: #a222c7;
	}
	.mini-sidebar #toggle_btn {
		left: 90px;
	}
	.mini-sidebar #toggle_btn i {
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
	}
	.toggle-group {
		position: relative;
		display: flex;
	}
	.mini-sidebar .header-left .logo img {
		height: auto;
		max-height: 40px;
		width: auto;
	}
	.mini-sidebar .sidebar .header-left .logo {
		display: none;
	}
	.mini-sidebar .header-left .logo.logo-small {
		display: block;
	}
	.mini-sidebar .sidebar .header-left {
		padding: 0;
		width: 78px;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.mini-sidebar .sidebar .header-left.logo-dark {
		display: none;
	}
	[data-layout-mode=light] .mini-sidebar .sidebar .header-left.logo-dark {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.mini-sidebar .sidebar {
		width: 78px;
	}
	.mini-sidebar.expand-menu .sidebar {
		width: 273px;
		z-index: 9;
	}
	.mini-sidebar .menu-title {
		display: none;
		white-space: nowrap;
	}
	.mini-sidebar .unlimited-access {
		display: none;
	}
	.mini-sidebar.expand-menu .unlimited-access {
		display: block;
	}
	.mini-sidebar .flags-group .dropdown-toggle span {
		display: none;
	}
	.mini-sidebar.expand-menu .flags-group .dropdown-toggle span {
		display: inline-block;
	}
	.mini-sidebar.expand-menu .menu-title {
		display: block;
	}
	.mini-sidebar .menu-title a {
		visibility: hidden;
	}
	.mini-sidebar.expand-menu .menu-title a {
		visibility: visible;
	}
	.mini-sidebar .sidebar-menu > ul > li > a {
		justify-content: center;
	}
	.mini-sidebar.expand-menu .sidebar-menu > ul > li > a {
		justify-content: start;
	}
	.modal-open.mini-sidebar .sidebar {
		z-index: 1051;
	}
	.mini-sidebar .sidebar .sidebar-menu ul > li > a span {
		display: none;
		transition: all 0.2s ease-in-out;
		opacity: 0;
	}
	.mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
		display: inline;
		opacity: 1;
	}
	.mini-sidebar.expand-menu .sidebar .sidebar-menu > ul > li > a i {
		font-size: 24px;
	}
	.mini-sidebar .page-wrapper {
		margin-left: 78px;
	}
	.mini-sidebar .header {
		margin-left: 78px;
	}
	.mini-sidebar .flags-group .flag-nav {
		width: 42px;
	}
	.mini-sidebar.expand-menu .flags-group .flag-nav {
		width: auto;
	}
}
@media only screen and (max-width: 1400px) {
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 180px;
	}
}
@media only screen and (max-width: 1399px) {
	.dash-widget-group ul li .card {
		min-width: 182px;
	}
	.dash-widget-icon {
		width: 42px;
		height: 42px;
	}
	.count-title {
		font-size: 14px;
	}
	.dash-widget-group ul {
		flex-wrap: wrap;
	}
}
@media only screen and (max-width: 1199px) {
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 150px;
	}
	.chat-window .chat-cont-left {
		flex: 0 0 40%;
		max-width: 40%;
	}
	.chat-window .chat-cont-right {
		flex: 0 0 60%;
		max-width: 60%;
	}


	.profile-set-wrapper .profile-set-content {
        padding-bottom: 80px;
    }
    .system-set-wrrapper {
        padding: 16px;
    }
    .themecolor ul li {
        margin-bottom: 5px;
    }
}
@media only screen and (max-width: 991.98px) {
	.dash-count .count {
	    font-size: 20px;
	}
	.sidebar .header-left {
		position: absolute;
		width: 100%;
	}
	.mobile_btn {
		color: #680a83 !important;
		cursor: pointer;
		display: block;
		font-size: 24px;
		height: 70px;
		left: 0;
		line-height: 70px;
		padding: 0 15px;
		position: absolute;
		text-align: center;
		top: 0;
		z-index: 10;
	}
	#toggle_btn {
		display: none;
	}
	.top-nav-search {
		display: none;
	}
	.login-wrapper .loginbox .login-left {
		padding: 80px 50px;
		width: 50%;
	}
	.sidebar {
		margin-left: -228px;
		width: 225px;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		z-index: 1041;
	}
	.page-wrapper {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.chat-window .chat-scroll {
		max-height: calc(100vh - 255px);
	}
	.chat-window .chat-cont-left, .chat-window .chat-cont-right {
		flex: 0 0 100%;
		max-width: 100%;
		transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
		width: 100%;
	}
	.chat-window .chat-cont-left {
		border-right: 0;
	}
	.chat-window .chat-cont-right {
		position: absolute;
		right: calc(-100% + -1.875rem);
		top: 0;
	}
	.chat-window .chat-cont-right .chat-header {
		justify-content: start;
	}
	.chat-window .chat-cont-right .chat-header .back-user-list {
		display: block;
	}
	.chat-window .chat-cont-right .chat-header .chat-options {
		margin-left: auto;
	}
	.chat-window.chat-slide .chat-cont-left {
		left: calc(-100% + -1.875rem);
	}
	.chat-window.chat-slide .chat-cont-right {
		right: 0;
	}
	.table-top {
		display: block;
	}
	.settings-icon {
		display: none;
	}
	.modal.language-translate .modal-dialog {
		min-width: 95%;
	}
	.user-menu .dropdown-menu {
		box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
	}
	.header {
		margin-left: 0;
	}
	.header-left-mobile {
		display: block;
		position: absolute;
		top: 20px;
		left: 50px;
	}
	.header-left-mobile .logo.logo-small {
		display: none;
	}
	.flags-group .dropdown-toggle:after {
		right: -75px;
	}
	[data-layout-style="small"] .page-wrapper {
		margin-left: 0;
	}
	[data-layout-style="small"] .header {
		margin-left: 0;
	}
}


@media only screen and (max-width: 767.98px) {
	body {
		font-size: 0.9375rem;
	}
	h1, .h1 {
		font-size: 2rem;
	}
	h2, .h2 {
		font-size: 1.75rem;
	}
	h3, .h3 {
		font-size: 1.5rem;
	}
	h4, .h4 {
		font-size: 1.125rem;
	}
	h5, .h5 {
		font-size: 1rem;
	}
	h6, .h6 {
		font-size: 0.875rem;
	}
	.header .has-arrow .dropdown-toggle:after {
		display: none;
	}
	.user-menu.nav > li > a > span:not(.user-img) {
		display: none;
	}
	.navbar-nav .open .dropdown-menu {
		float: left;
		position: absolute;
	}
	.navbar-nav.user-menu .open .dropdown-menu {
		left: auto;
		right: 0;
	}
	.sidebar .header-left {
		padding: 0 15px;
	}
	.login-wrapper .loginbox .login-left {
		display: none;
	}
	.login-wrapper .loginbox {
		max-width: 450px;
		min-height: unset;
	}
	.login-wrapper .loginbox .login-right {
		float: none;
		padding: 1.875rem;
		width: 100%;
	}
	.invoice-container {
		padding: 20px;
	}
	.left-action {
		text-align: center;
		margin-bottom: 15px;
	}
	.right-action {
		text-align: center;
	}
	.top-action-left .float-left {
		float: none !important;
	}
	.top-action-left .btn-group {
		margin-bottom: 15px;
	}
	.top-action-right {
		text-align: center;
	}
	.top-action-right a.btn.btn-white {
		margin-bottom: 15px;
	}
	.mail-sent-time {
		float: left;
		margin-top: 10px;
		width: 100%;
	}
	.profile-btn {
		flex: 0 0 100%;
		margin-top: 20px;
	}
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 250px;
	}
	.app-dropdown {
		display: none;
	}
	.edit-link {
		font-size: 0.875rem;
		margin-top: 0;
	}
	.product_price {
		font-size: 1.5rem;
	}
	.login-wrapper .loginbox .login-right h1 {
		font-size: 22px;
	}
	.error-box h1 {
		font-size: 6em;
	}
	.error-box .btn {
		font-size: 15px;
		min-width: 150px;
		padding: 8px 20px;
	}
	.dash-count {
		font-size: 16px;
	}
	.dash-widget-group ul li {
		margin-right: 0;
		width: 100%;
	}
	.page-header {
		display: block;
	}
	.page-header .page-title {
		margin-bottom: 15px;
	}
	.page-btn ul {
		flex-wrap: wrap;
	}
	.page-btn ul li {
		margin-bottom: 15px;
	}
	.table-top {
		display: block;
	}
	.table-top ul {
		display: block;
	}
	.table-top ul li {
		margin-right: 0px;
	}
	.table-top ul li .pass-login .date-range {
		max-width: 100%;
	}
	.search-set .search-input input[type=search] {
		width: 100%;
	}
	.progress-language {
		display: block;
	}
	.percentage-progress {
		margin-bottom: 10px;
	}
	.profile-set-wrapper .profile-set-content {
        padding-bottom: 60px;
    }
    .profile-card .add-details {
        margin-bottom: 24px;
    }
    .logo-content {
        text-align: left;
    }
    .logo-cont {
        display: flex;
    }
    .theme-image {
        margin-bottom: 15px;
    }
    .wrapp-set-system {
        margin-top:24px ;
    }
    .nav-store {
        margin-bottom: 24px;
    }
	.drop-user-profile {
		display: none;
	}
	.header-left-mobile {
		top: 20px;
		left: 50px;
	}
	.header-left-mobile .logo {
		display: none;
	}
	.header-left-mobile .logo.logo-small {
		display: block;
	}
	.login-wrapper .login-inbox.admin-login {
		min-width: 100%;
	}
	.form-remember {
		display: block !important;
	}
	.form-remember .form-check {
		margin-bottom: 14px;
	}
	.flags-group .dropdown-toggle:after {
		right: -75px;
	}
}

@media only screen and (max-width: 575.98px) {
	.card {
		margin-bottom: 0.9375rem;
	}
	.page-wrapper > .content {
		padding: 0.9375rem 0.9375rem 0;
	}
	.chat-window {
		margin-bottom: 0.9375rem;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.card-chart .card-body {
		padding: 5px;
	}
	.page-header {
		margin-bottom: 0.9375rem;
	}
	.account-wrapper {
		padding: 0.9375rem;
	}
	.pagination-lg .page-link {
		font-size: 1.2rem;
		padding: 0.5rem 0.625rem;
	}
	.profile-image {
		flex: 0 0 100%;
		margin-bottom: 20px;
		text-align: center;
	}
	.profile-user-info {
		text-align: center;
	}
	.profile-btn {
		text-align: center;
	}
	.invoice-details, .invoice-payment-details > li span {
		float: left;
		text-align: left;
	}
	.fc-toolbar .fc-right {
		display: inline-block;
		float: none;
		margin: 10px auto 0;
		width: 200px;
		clear: both;
	}
	.fc-toolbar .fc-left {
		float: none;
		margin: 0 auto;
		width: 200px;
	}
	.fc-toolbar .fc-center {
		display: inline-block;
		width: 100%;
		text-align: center;
	}
	.fc-toolbar .fc-center h2 {
		width: 100%;
	}
	.profile-tab-cont {
		padding-top: 1.25rem;
	}
	.chat-window .chat-cont-right .chat-header .media .media-body {
		display: none;
	}
	.table-search input {
		width: 130px;
	}
	.profile-set-wrapper .profile-set-content {
        padding-bottom: 40px;
    }
    .profile-photo-wrapper {
        display: block !important;
        text-align: center;
    }
    .profile-photo {
        margin: 14px  auto;
    }   
    .acc-submited .btn-secondary {
        margin-left: 5px;
        padding: 10px 12px;
    }
    .acc-submited .btn-primary {
        padding: 11px 12px;
    }
    .Set-google-capt {
        padding: 24px;
    }
    .captcha-header {
        margin-bottom: 24px;
    }
    .local-wrapper {
        display: block !important;
        margin-bottom: 24px;
    }
    .local-wrapper .local-set-head {
        margin-bottom: 14px;
    }
    .customize-select .select2-container  {
        width: 200px !important;
    }
    .aws-setting .aws-act-set {
        margin: 0 5px;
    }
}

@media only screen and (max-width: 479px) {
	.add-btn {
		font-size: 14px;
		padding: 6px 7px;
	}
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 160px;
	}
	.page-header .breadcrumb {
		display: none;
	}
	.notifications .noti-content {
		width: 300px;
	}
}


[data-bs-theme="dark"] .css-13cymwt-control{

	border-color: #343637 !important;
    background-color: #343637 !important;

}
[data-bs-theme="dark"] .css-t3ipsp-control{

	border-color: #343637 !important;
    background-color: #343637 !important;

}
[data-bs-theme="dark"] .css-1nmdiq5-menu{

	border-color: #343637 !important;
    background-color: #343637 !important;

}
.pass-login .css-hlgwow {
    padding-left: 30px !important;
}
.calendar-report.css-hlgwow {
    padding-left: 30px !important;
}
.css-hlgwow {
    padding-left: 30px !important;
}