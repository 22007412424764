body .main-wrapper {
    visibility: visible;
}
.mob-scrn{
    overflow: hidden !important; 
    width: 100% !important; 
    height: 313px !important;
}
.PhoneInputInput{
    border: none !important;
}
/* react datepicker */
div.react-datepicker-wrapper {
    display: block;
}
.cal-icon span {
    z-index: 1;
}
span.react-datepicker__navigation-icon--next::before {
    transform: rotate(45deg);
    left: 0;
}
/* group page */
.animate-typing-col span.dot {
    margin-right: 1px;
}
/* react-select */
.css-1u9des2-indicatorSeparator{
    display: none;
}
.pass-login  {
	margin-bottom: 15px;
	position: relative;

}
.settingselect{
    min-width: 320px;
}
.otpselect{
    width: 250px;
}
.summer-group .rsw-editor{
    width: 500px;
    min-height: 173px;
}

.languageselect  .css-1dimb5e-singleValue{
padding-left:20px;
width: 200px;

}
.rtl-layout {
    direction: rtl;
}
.wordset .css-b62m3t-container .css-1fdsijx-ValueContainer{
    padding-left:30px;
}
.input-range{
    padding-left:30px;
}
/* .select.css-b62m3t-container {
    width: 207px !important;
    height: 41px !important;
} */
.admin-select{
    width: 90px;
    height: 40px;
}
.admin-select .css-1fdsijx-ValueContainer {
    padding-left: 25px;
}

.editable-container {
    display: inline-block;
    position: relative;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
  }
  
  .editable-container.editing {
    padding: 0.5rem 0;
  }
  
  .edit-input-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .editable-container.editing .edit-input-container {
    opacity: 1;
    transform: translateY(0);
  }
  
  .edit-icon,
  .update-icon,
  .cancel-icon {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  
  .editable-container.editing .edit-icon {
    opacity: 0;
  }
  
  .editable-container:not(.editing) .update-icon,
  .editable-container:not(.editing) .cancel-icon {
    opacity: 0;
  }

  .check{
    color: blue !important;
  }
  
