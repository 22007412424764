/*
Author       : Dreamguys
Template Name: Dreamschat New
Version      : 1.0
*/


/*============================
 [Table of CSS]

1. General
2. Right Sidebar
3. Sidebar Menu
4. Login
5. Login Otp
6. Register
7. Avatar
8. Chat 
9. Settings 
10. Contact
11. Video Call
12. Join Call
13. Meeting Call
14. Status
15. Call
16. Modal
17. Group
18. Responsive

========================================*/


/*-----------------
	1. General
-----------------------*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Regular.eot);
	src: local('Material Icons'), local('MaterialIcons-Regular'), url(../fonts/MaterialIcons-Regular.woff2) format('woff2'), url(../fonts/MaterialIcons-Regular.woff) format('woff'), url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: 16px;
	/* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	vertical-align: middle;
	-webkit-font-smoothing: antialiased;
	/* Support for all WebKit browsers. */
	text-rendering: optimizeLegibility;
	/* Support for Safari and Chrome. */
	-moz-osx-font-smoothing: grayscale;
	/* Support for Firefox. */
	font-feature-settings: 'liga';
	/* Support for IE. */
}

.bg-primary {
	background: #0a80ff !important;
	color: #fff !important;
}

.bg-success {
	background: #0abb87 !important;
	color: #fff !important;
}

.bg-danger {
	background: #fd397a !important;
	color: #fff !important;
}

.bg-warning {
	background: #ffb822 !important;
	color: #fff !important;
}

.text-primary {
	color: #0a80ff !important;
}

.text-info {
	color: #00bcd4 !important;
}

.text-success {
	color: #0abb87 !important;
}

.text-danger {
	color: #fd397a !important;
}

.text-warning {
	color: #ffb822 !important;
}

.text-light {
	color: #e6e6e6 !important;
}

a {
	color: #424242;
	cursor: pointer;
	text-decoration: none;
	-webkit-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
a:hover {
	-webkit-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
	color: #680A83;
}
a:focus {
	outline: 0;
}
  
p {
	margin-bottom: 20px;
}
  
strong {
	font-weight: 700;
}
  
ul {
	margin: 0;
	padding: 0;
}
ul li {
	list-style-type: none;
}
.down-emoji-circle {
	position: absolute;
	left: 10px !important;
	top: -38px !important;
}
.emoj-action .down-emoji-circle ul,
.emoj-action-foot .down-emoji-circle ul {
	padding: 10px;
}
.down-emoji-circle ul li + li {
	margin-left: 14px	;
}
.emoj-action .down-emoji-circle ul li:last-child a,
.emoj-action-foot .down-emoji-circle ul li:last-child a  {
	width: 34px;
    height: 34px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.card {
	border: 0;
	border-radius: 10px;
	padding: 24px;
	margin-bottom: 24px;
}

a:hover {
	text-decoration: none;
}

a.btn:hover,
a.btn:active,
a.btn:focus {
	text-decoration: none !important;
}

.btn-primary:focus-within {
	color: #fff !important;
}
.profile_button {
	font-size: 14px !important;
	width: 100% !important;
}

.profile_form {
	margin: 20px;
}
.btn {
	font-size: 15px;
	width: auto;
	display: inline-flex;
	padding: 10px 20px;
	line-height: 14px;
	font-weight: 600;
}

body {
	background-color: #fff;
	font-family: 'Inter', sans-serif;
	font-size: 15px;
	overflow-x: hidden;
	padding-left: 72px;
	color: #424242;
}

.btn {
	border-color: transparent;
}
h1 {
	font-weight: 700;
	font-size: 36px;
}
h2 {
	font-weight: 600;
	font-size: 32px;
}
h3 {
	font-weight: 600;
	font-size: 28px;
}
h4 {
	font-weight: 600;
	font-size: 24px;
}
h5 {
	font-weight: 600;
	font-size: 20px;
}
h6 {
	font-weight: 500;
	font-size: 15px;
}
a:hover,
a:focus {
	text-decoration: none;
	outline: none;
	color: #424242;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.form-control {
	font-size: 14px;
}
textarea.form-control {
    height: auto;
}
.form-group {
    margin-bottom: 1rem;
}
.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
.btn-block {
	width: 100%;
}
.btn-icon {
	margin-right: .5rem;
}
.tablist {
	display: flex;
	display: -webkit-flex;
	cursor: pointer;
}
.first_list {
	display: flex;
	display: -webkit-flex;
	margin: 0px;
	width: 100%;
	height: 100%;
}
.carousel-caption {
	background-color: rgba(0, 0, 0, 0.5);
	padding: 20px;
	border-radius: .5rem;
}
.dropdown-menu {
	padding: 0;
}

.dropdown-menu .dropdown-divider {
	margin: 0;
}

.dropdown-menu .dropdown-item:hover:first-child {
	border-radius: 5px 5px 0 0;
}

.dropdown-menu .dropdown-item:hover:last-child {
	border-radius: 0px 0px 5px 5px;
}


/*-----------------
  2. Right Sidebar
-----------------------*/

.header_image {
	width: 195px;
}

.chat_avator_img {
	position: relative;
}

.group_img {
	width: 40px;
	height: 40px;
	object-fit: cover;
}

.dreams_chat {
	width: 40px;
	height: 40px;
	object-fit: cover;
}

.voice_pop {
	padding-right: 17px;
}

.status_carousel {
	margin-top: 50px;
}

.carousel_img {
	width: 650px;
	height: 434px;
}

.video_content {
	min-height: 200px;
	margin-top: 70px;
}

.voice-call-content {
	min-height: 200px;
}

.chatimage img {
	width: 120px;
	height: 120px;
}

.send-btn_status {
	width: 52px;
	height: 52px;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: #fff !important;
	background-color: #FFA977 !important;
	margin-left: 15px;
	font-size: 22px;
}

.status_telegram {
	color: #fff;
}

.media-lists {
	display: flex;
	justify-content: flex-start;
	margin: 0 -5px 0px;
	flex-wrap: wrap;
}

.media-lists .media-image {
	margin: 5px;
	flex-basis: 74px;
	min-width: 74px;
}

.media-lists .media-image img {
	max-width: 100%;
}

.avatar-group .avatar+.avatar {
	margin-left: -.75rem;
}

.list-group-item {
	background-color: inherit;
	border-color: #ebebeb;
}

ul.list-inline .list-inline-item {
	margin-bottom: .5rem;
}

.form-control {
	border-radius: 5px;
}

.form-control:focus {
	box-shadow: none;
	border-color: #e6e6e6;
}

.custom-control-label {
	line-height: 25px;
}

.custom-control-input:checked~.custom-control-label::before {
	background-color: #0a80ff;
}

.dropdown-menu .dropdown-item {
	font-size: 14px;
	cursor: pointer;
	padding: 7px 14px;
	font-weight: 500;
	color: #424242;
}
.dropdown-menu .dropdown-item i {
	font-size: 16px;
	color: #424242;
}

.dropdown-menu .dropdown-item span {
	float: right;
	font-size: 18px;
	color: #424242;
}

.dropdown-menu .dropdown-item.active {
	background: none;
	color: inherit;
}

.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:active {
	background-color: #e6e6e6;
}

.open_drop {
	color: #fff !important;
}

.send-btn_status:focus {
	box-shadow: none !important;
	border-color: #ffa977 !important;
}

.avatar {
	display: inline-block;
	margin-bottom: 0;
	height: 48px;
	width: 48px;
	border-radius: 50%;
}

.avatar .avatar-title {
	color: #fff;
	background: #680A83;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-size: 19px;
	font-weight: 600;
}

.avatar > a {
	width: 100%;
	height: 100%;
	display: block;
	transition: color .3s;
	color: #0a80ff;
}

.avatar > a > img,
.avatar > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.avatar.avatar-sm {
	height: 1.3rem;
	width: 1.3rem;
}

.avatar.avatar-sm .avatar-title {
	font-size: 14px;
}

.avatar.avatar-lg {
	height: 3.8rem;
	width: 3.8rem;
}

.avatar.avatar-lg .avatar-title {
	font-size: 29px;
}

.avatar.avatar-xl {
	height: 100px;
	width: 100px;
}

.header_drop_icon {
	border-radius: 6px;
}

.accordion-col .accordion-title {
	cursor: pointer;
	padding: 15px 0 8px;
}

.accordion-col .accordion-title .primary-title i {
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
	transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
}

.accordion-col .accordion-title.active .primary-title i {
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
	transform: rotate(270deg);
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
}

.accordion-col .accordion-content {
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 15px;
}

.accordion-col .accordion-content:last-child {
	border-bottom: 0;
}

.accordion-col .accordion-content h6 {
	font-size: 14px;
}

.dropdown-menu {
	background: #FFFFFF;
	border: 1px solid #E5E7EB;
	box-sizing: border-box;
	box-shadow: 0px 4px 3px 0px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	min-width: 173px;
	z-index: 999;
}

.dropdown-menu .dropdown-item {
	margin: 0;
	position: relative;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.dropdown-menu.chat-drop-menu .dropdown-item i {
	font-size: 16px;
	color: #424242;
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active {
	background-color: #F8ECFF;
}

.dropdown-menu .dropdown-item span {
	margin-right: 10px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.status-right {
	font-weight: 500;
	color: #444444;
	font-size: 16px;
	text-align: center;
}

.status-right p {
	margin-top: 20px;
	margin-bottom: 0;
}

.star-drop .btn-outline-light {
	color: #424242;
	border: 0;
	padding: 0;
}

.star-drop .btn-outline-light i {
	font-size: 20px;
}

.star-drop .btn.btn-outline-light:hover {
	background: transparent;
	border: 0;
}

.chat.star-chat-group {
	background-image: none;
}

.chat.star-chat-group .chat-body {
	background-image: none;
}

.chat.star-chat-group .chat-body .messages .chats {
    max-width: 100%;
}

.chat.star-chat-group .chat-body .messages .chats .message-content {
	background: #F5F5F5;
}

.chat.star-chat-group .chat-body .messages .chats .message-content.award-link a {
    word-break: break-all;
}

.chat.star-chat-group .chat-body .messages .chats .message-content.award-link img {
    max-width: 100%;
}
.chat .chat-body .messages .chats.forward-chat-msg {
	display: flex;
    position: absolute;
    bottom: 138px;
    right: 24px;
    left: 24px;
    max-width: 100%;
}
.chats.forward-chat-msg {
	background: #EDEDED;
	border-radius: 10px;
	padding: 5px;
	border-left: 2px solid #680A83;
	z-index: 9;
	position: relative;
}
.chats.forward-chat-msg .chat-avatar {
	padding: 0;
	padding-right: 15px;
}
.chats.forward-chat-msg .contact-close_call {
    position: absolute;
    right: 15px;
    top: 10px;
}
.chats.forward-chat-msg .contact-close_call .close_replay {
	color: #424242;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.chats.forward-chat-msg .contact-close_call .close_replay i {
	font-size: 18px;
}

/*-----------------
  3. Sidebar Menu
-----------------------*/

.menus-col {
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	height: calc(100vh - 60px);
}

.sidebar-menu {
	position: fixed;
	left: 0;
	top: 0;
	width: 72px;
	background-color: #fff;
	z-index: 999;
	padding: 15px;
    border-right: 1px solid rgba(224, 224, 224, 1)
}

.sidebar-menu .logo-col {
	text-align: center;
}

.chat-menus {
	margin-top: 40px;
}

.chat-menus ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.chat-menus ul li {
	display: block;
	position: relative;
}

.chat-menus ul li+li {
	margin-top: 10px;
}

.chat-menus ul li a {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	width: 42px;
	height: 42px;
	border-radius: 5px;
}
.chat-menus ul li a i {
	font-size: 20px;
	color: #424242;
}

.dark-mode-toggle {
    width: 42px;
    height: 42px;
    color: #424242;
    font-size: 16px;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(251, 251, 251);
    border-image: initial;
    border-radius: 5px;
    margin: 0px auto;
}

.dark-mode-toggle:hover {
	color: rgb(255, 255, 255);
}

.dark-mode-toggle i {
	color: #424242;
	font-size: 20px;

}
.chat-menus ul li a.active {
	background: #F9F1FF;
}
.chat-menus ul li a.active i {
	color: #680A83;
}
.chat-menus ul li a:hover {
	background: #F9F1FF;
}
.chat-menus ul li a:hover i {
	color: #680A83;
}

.chat-menus ul li a span.material-icons,
.bottom-menus ul li a span.material-icons {
	color: #420BA1;
	font-size: 22px;
	padding: 0;
	position: static;
	opacity: 1;
	visibility: visible;
	background-color: transparent;
	box-shadow: none;
	border-radius: 0;
	transform: none;
	-webkit-transform: none;
	-ms-transform: none;
	min-width: inherit;
}

.chat-menus > ul > li > a > span,
.bottom-menus > ul > li > a > span {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 120%;
	top: 50%;
	background-color: #420BA1;
	box-shadow: 4px 4px 4px rgba(200, 198, 198, 0.25);
	border-radius: 2px;
	display: inline-block;
	padding: 6px 19px;
	color: #fff;
	font-size: 12px;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
}

.chat-menus > ul > li > a > span:before,
.bottom-menus > ul > li > a > span:before {
	content: "";
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-right: 7px solid #420BA1;
	border-bottom: 7px solid transparent;
	position: absolute;
	left: -6px;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
}

.chat-menus > ul > li > a:hover > span,
.bottom-menus > ul > li > a:hover > span {
	opacity: 1;
	visibility: visible;
}

.bottom-menus > ul > li > a > span {
	min-width: 130px;
}

.bottom-menus {
	width: 100%;
	display: flex;
	display: -webkit-flex;
	align-self: flex-end;
	-webkit-align-self: flex-end;
	justify-content: center;
	-webkit-justify-content: center;
}

.bottom-menus > ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.bottom-menus > ul > li {
	display: block;
	text-align: center;
	position: relative;
}

.bottom-menus ul li+li {
	margin-top: 10px;
}

.bottom-menus > ul > li > a {
	text-align: center;
}

.bottom-menus .chat-profile-icon img {
	max-width: 100%;
	border-radius: 36px;
	border: 2px solid #EEEEEE;
}

.left-chat-title {
	padding: 22px 24px;
	border-bottom: 1px solid #E0E0E0;
	min-height: 73px;
}
.left-chat-title.with-bg {
	background-color: #FAFBFF;
}

.chat-title h4 {
	font-size: 14px;
	font-weight: 600;
	color: #4B0973;
	margin-bottom: 0;
}
.chat-title h4 img {
	margin-right: 10px;
}

.chat-title h4 a {
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	color: #4B0973;
	margin-bottom: 0;
}

.left-chat-title .add-section > a {
	width: 30px;
	height: 30px;
	background-color: #680A83;
	border-radius: 30px;
	color: #fff;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	font-size: 13px;
}

.add-section ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.add-section ul li {
	float: left;
	border-radius: 50px;
	width: 28px;
	height: 28px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;	
    justify-content: center;
    -webkit-justify-content: center;
}

.add-section ul li+li {
	margin-left: 10px;
}

.add-section ul li a {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	font-size: 20px;
}
.all-chats .add-section ul li:hover{
  background-color: #F5F5F5;
  border-color: #F5F5F5;
}

.online {
	color: #008024;
	font-weight: 500;
}

.top-online-contacts {
	padding: 24px 24px 0px;
}
.fav-title {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 15px;
}
.fav-title h6 {
	margin-bottom: 0;
	font-weight: 600;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.fav-title h6 i {
	font-size: 22px;
}
.fav-title a {
	margin-bottom: 0;
	color: #680A83;
	font-weight: 500;
}
.fav-title a:hover {
	color: #0A0A0A;
}

.top-contacts-box .profile-img {
	position: relative;
	width: 46px;
	height: 46px;
}

.top-contacts-box .profile-img img {
	max-width: 100%;
	height: auto;
	border-radius: 50px;
}

.profile-name span {
	color: #585858;
	font-size: 12px;
	display: inline-block;
	margin-top: 9px;
}

.top-contacts-box .profile-img.online:before {
	content: "";
	position: absolute;
	right: 0;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	top: 0px;
	background-color: #49E073;
	width: 10px;
	height: 10px;
	border: 2px solid #fff;
	border-radius: 50px;
}

.dropdown-menu .dropdown-item span.edit-profile-icon,
.dropdown-menu .dropdown-item span.profile-icon-col {
	font-size: 15px;
}

/*-----------------
  4. Login
-----------------------*/

.login-form {
    padding-left: 0;
	background: #F8F6FA;
}

.main-wrapper.register-surv {
    overflow: auto;
}

.login-wrapper {
  	padding: 70px 0;
  	background: #F8F6FA;
	width: 100%;
	margin: 0 auto;
	padding: 30px 0;
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	height: 100%;
}

.login-group-left {
	height: 100vh;
    overflow-y: auto;
    width: 100%;
    padding-bottom: 20px;
}

.login-wrapper .logo-header {
  	width: 100%;
  	margin: 0 0 40px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.login-wrapper .logo-header a.logo-brand .logo-dark {
 	 margin: 0px 12px 0 0;
}

.login-wrapper .logo-header a.logo-brand h5 {
  	margin: 0;
  	color: #680A83;
  	text-transform: capitalize;
}

.login-wrapper .login-inbox {
 	margin: 0 auto;
 	max-width: 542px;
	min-width: 542px;
}

.login-wrapper .login-inbox .log-auth {
	border-radius: 20px;
	background: #ffffff;
	box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.03);
	padding: 40px;
	margin-bottom: 30px;
}

.login-wrapper.lock-screen .login-inbox .log-auth {
	background: transparent;
	box-shadow: none;
	padding: 0;
}

.lock-screen-user h6 {
	color: #0A0A0A;
	font-size: 16px;
	font-weight: 500;
}

.lock-screen-user span {
	color: #424242;
	font-size: 16px;
	font-weight: 500;
}

.login-wrapper .login-inbox .log-auth .login-auth-wrap {
  margin: 0 0 40px 0;
}

.login-wrapper .login-inbox .log-auth .login-auth-wrap .login-content-head h3 {
  margin: 0 0 12px 0;
}

.login-wrapper .login-inbox .log-auth .login-auth-wrap .login-content-head p {
  color: #424242;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.login-wrapper .login-inbox .log-auth .phone-login span {
  color: #1F2937;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.login-wrapper .login-inbox .log-auth .phone-login span a {
  margin: 0 0 0 5px;
  color: #53BDEB;
}

.login-wrapper .login-inbox .log-auth .phone-login span a:hover {
	color: #650681;
}

.login-wrapper .login-inbox .log-auth form .form-group {
  margin: 0 0 18px 0;
}

.login-wrapper .login-inbox .log-auth form .form-group label {
  margin: 0 0 8px 0;
  color: #0A0A0A;
  font-size: 14px;
  font-weight: 500;
}

.login-wrapper .login-inbox .log-auth form .form-group label span {
  color: #DE3E44;
}

.login-wrapper .login-inbox .log-auth form .form-group .pass-group {
  position: relative;
}

.login-wrapper .login-inbox .log-auth form .form-group .pass-group .toggle-password {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  font-weight: 500;
}

.login-wrapper .login-inbox .log-auth form .form-group .pass-group .toggle-passwords {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    font-weight: 500;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-control {
  min-height: 40px;
  font-size: 14px;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-control:focus {
  box-shadow: none;
  outline: 0 none;
  border-color: #E0E0E0;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check {
  margin: 20px 0 0;
  padding: 0 0 0 28px;
  color: #8A909A;
  display: inline-block;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  user-select: none;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check input:checked ~ .checkmark {
  background-color: #680A83;
  border: 1px solid #680A83;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check input:checked ~ .checkmark::after {
  display: block;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check .remember-me {
  color: #0A0A0A;
  font-size: 16px;
  font-weight: 400;
}
.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check .remember-me:hover a {
	color: #680A83;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  font-size: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid #E3E3E3;
  background-color: #FFF;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.login-wrapper .login-inbox .log-auth form .form-group .form-check .custom-check .checkmark::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: -1px;
  left: 3px;
  display: none;
  color: #FFF;
  font-size: 12px;
}

.login-wrapper .login-inbox .log-auth form .form-group .forget-pass a {
  color: #650681;
}

.login-wrapper .login-inbox .log-auth form .form-group .forget-pass a:hover {
	color: #53BDEB;
}

.login-wrapper .login-inbox .log-auth form .form-group:nth-child(2) {
  margin-bottom: 14px;
}

.login-wrapper .login-inbox .log-auth form .form-group:nth-child(3) {
  margin-bottom: 30px;
}

.login-wrapper .login-inbox .login-or {
  margin: 20px 0;
  text-align: center;
  position: relative;
  z-index: 1;
}

.login-wrapper .login-inbox .login-or .span-or-log {
  color: #424242;
  font-size: 15px;
  font-weight: 400;
  background-color: #ffffff;
  padding: 0 18px;
}

.login-wrapper .login-inbox .login-or .span-or-log::after {
  content: "";
  border-top: 1px solid #E0E0E0;
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  right: 0;
  top: 11px;
  z-index: -1;
}

.login-wrapper .login-inbox .social-login {
  margin-bottom: 50px;
}

.login-wrapper .login-inbox .social-login li {
  border-radius: 6px;
  border: 2px solid #E0E0E0;
  padding: 14px 16px;
  width: 100%;
}

.login-wrapper .login-inbox .social-login li + li {
  margin-left: 12px;
}

.login-wrapper .login-inbox .social-login li a img {
  margin-right: 10px;
}

.login-wrapper .login-inbox .bottom-text p {
  color: #0A0A0A;
  font-size: 16px;
  font-weight: 400px;
  margin-bottom: 0px;
  margin-right: 5px;
}

.login-wrapper .login-inbox .bottom-text p a {
  color: #650681;
}

.login-wrapper .login-inbox .bottom-text p a:hover {
	color: #53BDEB;
}

.css-equal-heights, .css-equal-content {
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}
  
.css-equal-content {
	color: red;
	float: left;
}
  
.login-wrapper .logo-header a.logo-brand, .align-center {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}
  
.login-wrapper .login-inbox .log-auth .login-auth-wrap, .btn-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
}
  
.login-wrapper .login-inbox .log-auth .login-auth-wrap {
	-webkit-box-pack: space-between;
	-ms-flex-pack: space-between;
	justify-content: space-between;
}

.login-content-info {
	width: 100%;
}

/*-----------------
  5. Login Otp
-----------------------*/

.login-verify-img {
  margin-bottom: 25px;
}

.login-title {
  margin-bottom: 40px;
}

.login-title h3 {
  margin-bottom: 20px;
}

.login-title p {
  color:#424242;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.login-title p span {
  color: #0A0A0A;
}

form .otp-box {
  margin-bottom: 34px;
}

form .otp-box input {
  width: 74px;
  height: 74px;
  background: #F5F5F5 ;
  border: 2px solid #680A83;
  border-radius: 8px;
  text-align: center;
  margin-right: 15px;
  font-weight: 700;
  font-size: 26px;
  color: #680A83;
  outline: none;
}

form .otp-box input::placeholder {
  color: #680A83;
}

form .otp-box input:last-child {
  margin-right: 0px;
}

.forms-block .otp-info {
  margin-bottom: 50px;
}

.forms-block .otp-info .otp-code p {
  margin-right: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #0A0A0A;
}

.forms-block .otp-info .otp-code a {
  color: #53BDEB;
}

.forms-block .otp-info .otp-sec p i.feather-clock {
  margin-right: 5px;
}

.forms-block .otp-info .otp-sec p {
  background: #F8ECFF;
  color: #680A83;
  border-radius: 6px;
  margin-bottom: 0px;
}

.reset-group {
    margin-bottom: 30px !important;
}

.form-group .password-strength {
    display: flex;
    margin-top: 14px;
}

.form-group .password-strength span {
    width: 100%;
    height: 5px;
    background: #E3E3E3;
    margin-right: 10px;
    border-radius: 5px;
}

.form-group .password-strength span:last-child {
    margin-right: 0;
}

.poor-active span.active#poor, 
.poor-active span.active#poors {
	background: #FF0000;
}

.avg-active span.active#poor, .avg-active span.active#weak,
.avg-active span.active#poors, .avg-active span.active#weaks {
	background: #FFB54A;
}

.strong-active span.active#poor, .strong-active span.active#weak, .strong-active span.active#strong,
.strong-active span.active#poors, .strong-active span.active#weaks, .strong-active span.active#strongs {
	background: #1D9CFD;
}

.heavy-active span.active#poor, .heavy-active span.active#weak, .heavy-active span.active#strong, .heavy-active span.active#heavy,
.heavy-active span.active#poors, .heavy-active span.active#weaks, .heavy-active span.active#strongs, .heavy-active span.active#heavys {
	background: #159F46;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
	display: none;
}

/*-----------------
  6. Register
-----------------------*/

.register-wrapper {
    padding: 44px 0;
}

.register-wrapper .login-inbox .register-auth {
    padding: 40px;
}

.register-wrapper .login-inbox .register-auth  form .form-group {
    margin: 0 0 14px 0;
}

.register-wrapper .login-inbox .register-auth form .form-group:nth-child(3) {
    margin-bottom: 14px;
}

.register-wrapper .login-inbox .register-auth form .form-group:nth-child(5) {
    margin-bottom: 20px;
}

.register-wrapper .login-inbox .register-auth form .form-group:nth-child(6) {
    margin-bottom: 30px;
}

/*-----------------
  7. Avatar
-----------------------*/

.avatar {
	position: relative;
	display: inline-block;
	width: 46px;
	height: 46px;
	flex-shrink: 0;
}

.avatar > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #650681;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25%;
	height: 25%;
	border-radius: 50%;
	content: '';
	border: 2px solid #fff;
}

.avatar-online::before {
	background-color: #00e65b;
}

.avatar-offline::before {
	background-color: #ff0100;
}

.avatar-away::before {
	background-color: #ffbc34;
}

.avatar .border {
	border-width: 3px !important;
}

.avatar .rounded {
	border-radius: 6px !important;
}

.avatar .avatar-title {
	font-size: 18px;
}

.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
}

.avatar-xs .border {
	border-width: 2px !important;
}

.avatar-xs .rounded {
	border-radius: 4px !important;
}

.avatar-xs .avatar-title {
	font-size: 10px;
}

.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
	border-width: 1px;
}

.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
}

.avatar-sm .border {
	border-width: 3px !important;
}

.avatar-sm .rounded {
	border-radius: 4px !important;
}

.avatar-sm .avatar-title {
	font-size: 15px;
}

.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
	border-width: 2px;
}

.avatar-lg {
	width: 3.75rem;
	height: 3.75rem;
}

.avatar-lg .border {
	border-width: 3px !important;
}

.avatar-lg .rounded {
	border-radius: 8px !important;
}

.avatar-lg .avatar-title {
	font-size: 24px;
}

.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
	border-width: 3px;
}

.avatar-xl {
	width: 5rem;
	height: 5rem;
}

.avatar-xl .border {
	border-width: 4px !important;
}

.avatar-xl .rounded {
	border-radius: 8px !important;
}

.avatar-xl .avatar-title {
	font-size: 28px;
}

.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
	border-width: 4px;
}

.avatar-xxl {
	width: 5.125rem;
	height: 5.125rem;
}

.avatar-xxl .border {
	border-width: 6px !important;
}

.avatar-xxl .rounded {
	border-radius: 8px !important;
}

.avatar-xxl .avatar-title {
	font-size: 30px;
}

.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
	border-width: 4px;
}

.avatar-group {
	display: flex;
	display: -webkit-flex;
	padding-left: 15px;
	margin-left: auto;
	margin-right: 15px;
	cursor: pointer;
}

.avatar-group .avatar+.avatar {
	margin-left: -.75rem;
}

.avatar-group .avatar-xs+.avatar-xs {
	margin-left: -15px;
}

.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -.625rem;
}

.avatar-group .avatar-lg+.avatar-lg {
	margin-left: -1rem;
}

.avatar-group .avatar-xl+.avatar-xl {
	margin-left: -1.28125rem;
}

.avatar-group .avatar:hover {
	z-index: 1;
}

.avatar-group.group-col {
	display: block;
}

.avatar-group.group-col > div {
	display: flex;
	display: -webkit-flex;
	padding-bottom: 5px;
}

.avatar-group.group-col .avatar {
	margin-right: 0;
}

.contact-close {
	float: right;
	left: 95%;
	right: 0px;
	position: relative;
	top: 5%;
}

.status_contactclose {
	float: right;
	left: 95%;
	right: 0px;
	position: relative;
	top: 1%;
}

.status-active {
	border: 4px solid #650681;
}

.avatar.avatar-xl .avatar-title {
	font-size: 39px;
}

.nav.nav-tabs {
	border: none;
	line-height: 2;
}

.badge {
	padding: 5px 10px;
	font-size: 11px;
}

.badge.badge-success {
	background: #0abb87;
}

.badge.badge-danger {
	background: #fd397a;
}

.badge.badge-secondary {
	background: dimgray;
}

.badge.badge-info {
	background: #00bcd4;
}

.badge.badge-warning {
	background: #ffb822;
}

.badge.badge-dark {
	background: #3a3f51;
}

.badge.badge-primary {
	background: #0a80ff;
}

.badge.badge-light {
	background: #e6e6e6;
}

.btn {
	position: relative;
}

.btn .badge {
	padding: 2px 6px;
	right: 7px;
	top: -7px;
	position: absolute;
}

.input-group .input-group-text {
	font-size: 14px;
}
.sticky-top {
	z-index: auto;
}

.tab-content {
	padding-top: 30px;
}

.tab-content .form-item {
	margin-bottom: .8rem;
}

.main-wrapper {
	height: 100vh;
	display: flex;
	display: -webkit-flex;
	overflow: hidden;
	visibility: hidden;
}
.group .main-wrappers {
	/* height: 100vh; */
	display: flex;
	display: -webkit-flex;
	overflow: hidden;
	visibility: hidden;
}

.group.main-wrappers .content {
	display: flex;
	display: -webkit-flex;
}
.main-wrapper .content {
	display: flex;
	display: -webkit-flex;
}

.main-wrapper .content .sidebar-group {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	position: relative;
	z-index: 99;
	/* width: 340px; */
	width: 420px;
}

.main-wrapper .content .sidebar-group .sidebar {
	background: #fff;
	overflow: hidden;
	width: 420px;
	/* width: 340px; */
	display: flex;
	flex-direction: column;
	flex: 1;
	border-right: 1px solid rgba(224, 224, 224, 1);
}

.main-wrapper .content .sidebar-group .sidebar:not(.active) {
	display: none;
}

.header-top {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	font-weight: 600;
	background-color: #680A83;
}

.header-top .logo {
	margin-top: 0 !important;
}

.header-top > span {
	font-size: 22px;
}

.header-top ul {
	margin-bottom: 0;
	display: flex;
}

.header-top ul.header-action {
	margin-top: 14px !important;
}

.chat-header ul {
	margin-bottom: 0;
}

.chat-header ul li:not(.list-inline-item) {
	margin-bottom: 0;
	width: 48px;
	margin-right: 15px !important;
}

.chat-header ul li:last-child {
	margin-right: 0 !important;
}

.sidebar-body {
	flex: 1;
	overflow: auto;
	padding: 24px 24px 10px;
	width: 100%;
}
.pin-chat img {
	margin-right: 6px;
}
.sidebar-body .profile-name {
	font-weight: 600;
	color: #4B0973;
	font-size: 18px;
	margin-bottom: 0;
}

.online-profile {
	position: relative;
}

.online-profile span {
	position: relative;
	color: #565656;
	font-size: 13px;
	padding-left: 10px;
}

.online-profile span:before {
	content: "";
	position: absolute;
	left: 0;
	top: 7px;
	background-color: #49E073;
	width: 8px;
	height: 8px;
	border-radius: 50px;
}

.close_profile4 {
	color: #000;
	border-color: #e6e6e6;
}

.sidebar .user-list li a,
.sidebar .user-list li .list-user-blk {
	padding: 10px;
	display: flex;
	display: -webkit-flex;
	cursor: pointer;
	margin-bottom: 15px;
	border: 1px solid #E0E0E0;
	box-shadow: none;
	border-radius: 5px;
	position: relative;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
}

.sidebar .user-list li a:hover,
.sidebar .user-list li .list-user-blk:hover {
	background-color: #F8ECFF;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
}

.sidebar .user-list li.item-typing {
	background-color: #F1F6FF;
}

.sidebar .user-list li figure {
	margin-right: 1rem;
}

.sidebar .user-list li .users-list-body {
	flex: 1;
	position: relative;
	min-width: 0px;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.lock-icon {
	color: #000000;
}

.sidebar .user-list li .users-list-body > div:first-child {
	min-width: 0;
	display: flex;
	flex-direction: column;
	flex: 1;
}
.parti-notify-check {
	margin-bottom: 0 !important;
	display: flex;
	align-items: center;
}

.sidebar .user-list li .users-list-body h5 {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 3px;
	color: #0A0A0A;
}

.sidebar .user-list li .users-list-body p {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 0;
	color: #232323;
	font-size: 14px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.sidebar .user-list li .users-list-body p .bx-map {
	font-size: 16px;
}
.sidebar .user-list li .users-list-body p.missed-call-col {
	color: #DE3E44;
}
.sidebar .user-list li .users-list-body .last-chat-time {
	padding-left: 15px;
	position: relative;
}

.sidebar .user-list li .users-list-body .last-chat-time .text-muted {
	color: #424242 !important;
	font-weight: 400;
	font-size: 12px;
	margin-bottom: 5px;
	display: block;
	text-align: right;
}

.sidebar .user-list li .users-list-body .last-chat-time .contact-toggle {
	color: #680A83;
}

.sidebar .user-list li .users-list-body .last-chat-time .new-message-count {
	width: 20px;
	height: 20px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	line-height: 0;
	font-size: 11px;
	background-color: #680A83;
	color: #fff;
	border-radius: 50%;
	margin-left: auto;
	margin-top: 10px;
	position: absolute;
	right: 0;
	bottom: 0;
}

.sidebar .user-list li .users-list-body .last-chat-time .chat-toggle {
	position: absolute;
	right: 0;
	bottom: 0;
	opacity: 0;
	text-align: right;
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
	width: 18px;
	height: 24px;
}

.sidebar .user-list li.unread h5 {
	color: #fff;
}

.sidebar .user-list li.unread p {
	color: #fff;
}

.sidebar .user-list li.unread .text-muted {
	color: rgba(255, 255, 255, 0.4) !important;
}

.sidebar .user-list li.user-list-item.item-typing .users-list-body p {
	color: #0D9B35;
}

.rightside_tab {
	padding-top: 5px !important;
}
.chat-pin {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: flex-end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
}
.chat-pin img {
	width: 10px;
}
.chat-pin .check {
	color: #53BDEB;
}
.chat-pin .count-message {
	background: #680A83;
	width: 20px;
	height: 20px;
	border-radius: 50px;
	font-size: 12px;
	font-weight: 400;
	color: #fff;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.select-message-box {
	margin-top: 50px;
}
.select-message-box h4 {
	margin-bottom: 5px;
}
.select-message-box p {
	margin-top: 0;
	margin-bottom: 24px;
	color: #424242;
	font-size: 16px;
	font-weight: 400;
}
.select-message-box .btn i {
	font-size: 15px;
}

/*-----------------
  8. Chat
-----------------------*/

.chat {
	flex: 1;
	display: flex;
	flex-direction: column;
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
	width: 100%;
	height: 100%;
    background-image: url(../../../public/assets/img/chat-bg.jpg);
    background-repeat: no-repeat;
    background-position: center;
	background-size: cover;
}
.chat.setting-chat {
	filter: blur(8px);
    -webkit-filter: blur(8px);
}
.chat .chat-header .chat-options.chat-small ul li a {
	width: 35px;
	height: 35px;
}
.chat-options ul.list-inline .list-inline-item {
    margin-bottom: 0;
}
.last-seen {
	color:#424242;
	font-size: 14px;
}
.user_callog {
	display: flex;
	margin-top: 0px;
}
.chev-icon i {
	font-size: 18px;
}
.calllog_p {
	margin-left: 5px;
}
.chat-options.chat-option-profile .list-inline-item a {
	background: #F5F5F5;
	width: 38px;
	height: 38px;
	border-radius: 50px;
	color: #424242;
	padding: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-options.chat-option-profile .list-inline-item a:hover {
	background: #680A83;
	color: #fff;
}
.chat-options.chat-option-profile .list-inline {
	border-bottom: 1px solid #EDEDED;
	margin-bottom: 0;
	padding-bottom: 15px;
}
.last-seen-profile span {
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 12px;
	display: block;
}

.header_button {
	position: absolute;
	right: 5px;
	width: 40px;
	height: 40px;
	border-radius: 20px;
	font-size: 14px;
	color: white;
	text-align: center;
	background: #ffa977;
	bottom: 40px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}

.btn-warning:hover {
	color: #fff;
	background-color: #ffa977;
	border-color: #ffa977;
}

.right_sidebar_profile {
	margin-top: 0px;
}

.account_details,
.security_details {
	background-color: #edeef6;
	border-color: #d9d7d8;
	border-radius: 5px !important;
	border-bottom: 0 !important;
}

.close_icon {
	font-size: 18px;
}

.button_plus {
	margin-left: 0px;
}

.chat .chat-header {
	display: flex;
	display: -webkit-flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	padding: 13px 24px;
	align-items: center;
	background: #FFFFFF;
	border-bottom: 1px solid #E0E0E0;
	position: relative;
}

.chat-search {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
	opacity: 0;
	visibility: hidden;
}

.chat-search.visible-chat {
	opacity: 1;
	visibility: visible;
	top: 100%;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
	z-index: 9;
}
.form-control-feedback {
    z-index: 2;
    display: block;
    text-align: center;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
}
.form-control-feedback i {
	font-size: 16px;
	color: #0A0A0A;
}

.user-chat-search {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
	opacity: 0;
	visibility: hidden;
}

.user-chat-search.visible-chat {
	opacity: 1;
	visibility: visible;
	top: 100%;
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
	z-index: 9;
}

.chat-search input[type="text"],
.user-chat-search input[type="text"] {
	padding: 8px 8px 8px 35px;
	width: 100%;
	min-height: 47px;
	border-radius: 0px 0px 5px 5px;
	border: 1px solid #E5E7EB;
	font-size: 13px;
	background-color: #fff;
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
}

.chat-search input[type="text"]:hover,
.user-chat-search input[type="text"]:hover,
.chat-search input[type="text"]:focus,
.user-chat-search input[type="text"]:focus,
.chat-search input[type="text"]:focus-visible,
.user-chat-search input[type="text"]:focus-visible {
	border-color: #F3F3F3;
}
.all-chats {
	position: relative;
}
.close-btn-chat,
.user-close-btn-chat {
	width: 25px;
	height: 25px;
	border-radius: 25px;
	color: #0A0A0A;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	position: absolute;
	right: 12px;
	top: 9px;
	cursor: pointer;
}

.main_content {
	width: 100% !important;
}

.chat .chat-header .user-details {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
}

.avatar-main {
	height: 50px;
    width: 50px;
}

.chat .chat-header .user-details figure {
	margin-right: 1rem;
}

.chat .chat-header .user-details h5 {
	font-size: 15px;
	font-weight: 600;
	margin-bottom: 4px;
	line-height: 1;
	color: #424242;
}

.archive-btn {
	border: 1px solid #008024;
    color: #008024;
    border-radius: 4px;
    font-size: 11px;
    font-weight: 500;
    padding: 2px 6px;
	margin-right: 1.5rem;
}

.archive-btn-grey {
	border: 1px solid #909090;
    color: #909090;
    border-radius: 4px;
    font-size: 9px;
    font-weight: 500;
    padding: 2px 6px;
	margin-top: .25rem;
}

.right_sidebar_icon {
	list-style: none;
}

.chat .chat-header .chat-options ul {
	margin-bottom: 0;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
}

.btn.btn-outline-light:hover {
	background: #e6e6e6;
}

.chat .chat-header .chat-options ul > li > a {
	font-size: 14px;
	color: #424242;
	cursor: pointer;
	width: 40px;
	height: 40px;
	padding: 0;
	border-radius: 50px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
}
.chat .chat-header .chat-options ul > li > a i {
	font-size: 20px;
}

.chat .chat-header .chat-options ul > li > a:not(.no-bg):hover {
	background-color: #F5F5F5;
	border-color: #F5F5F5;
}

.chat .chat-body {
	flex: 1;
	padding: 24px;
}

.chat .chat-body:not(.no-message) {
	overflow: auto;
}

.chat .chat-body {
	padding-bottom: 100px;
}

.chat .chat-body.no-message {
	user-select: none;
}

.chat .chat-body .messages {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	align-items: flex-start;
	-webkit-align-items: flex-start;
}

.chat .chat-body .messages .chats {
	max-width: 75%;
	margin-bottom: 30px;
	display: flex;
	display: -webkit-flex;
}

.chat-avatar {
	padding: 8px 15px 0 8px;
	display: flex;
	display: -webkit-flex;
	align-items: flex-start;
	-webkit-align-items: flex-start;
}

.chat .chat-body .messages .chat-profile-name h6 {
	margin-bottom: 0;
	color: #0A0A0A;
	font-weight: 600;
	font-size: 15px;
}

.chat .chat-body .messages .chat-profile-name h6 span {
	font-size: 12px;
	color: #9E9E9E;
	font-weight: 400;
	padding-left: 10px;
}
.chats.chats-right .chat-profile-name {
	justify-content: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
}
.chat-profile-name {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 5px;
}
.replay-forms .chat-profile-name h6 {
	margin-bottom: 0;
}
.replay-forms .chat-profile-name h6 span {
	color: #9E9E9E;
	font-size: 12px;
	margin-left: 10px;
}
.chat .chat-body .messages .chats:last-child {
	margin-bottom: 0;
}

.chat .chat-body .messages .chats .chat-time {
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	margin-bottom: 0;
}

.chat .chat-body .messages .chats .chat-time .avatar {
	margin-right: 1rem;
}

.chat .chat-body .messages .chats .chat-time h5 {
	font-size: 15px;
	margin-bottom: 0;
}

.chat .chat-body .messages .chats .chat-time .time {
	color: #fff;
	margin-top: 2px;
	font-size: 12px;
	font-weight: 400;
}

.chat .chat-body .messages .chats .chat-time .time i {
	color: rgba(247, 247, 247, 0.5);
}

.chat .chat-body .messages .chats .chat-time .time i img {
	width: 14px;
	height: auto;
	margin-left: 2px;
	vertical-align: 0px;
}

.chat .chat-body .messages .chats .message-content {
	background-color: #fff;
	border-radius: 20px 20px 20px 0;
	padding: 14px 20px;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	box-shadow: 0px 6px 12px 0px #00000008;
}
.chat .chat-body .messages .chats .message-content.award-link a {
	color: #424242;
	margin-bottom: 2px;
	display: block;
}
.chat .chat-body .messages .chats .message-content.chat-award-link a {
	display: flex;
	margin-bottom: 10px;
}
.chat .chat-body .messages .chats .message-content.chat-award-link .emoj-group li a {
	margin-bottom: 0px;
}
.delete-pop-btn .btn-primary {
	margin:0 0 0 10px;
}
.delete-model-head {
	padding: 40px;
}
.delete-model-head .modal-header{
	padding: 0;
	margin-bottom: 32px;
}
.delete-model-head .modal-body {
	padding: 0;
}
.delete-chat-notify {
	margin-bottom: 32px;
}
.delete-chat-notify .form-check {
	margin-bottom: 14px;
}
.chat .chat-body .messages .chats .message-content.award-link img {
	border-radius: 10px;
}

.chats.chats-right {
    flex-direction: row-reverse;
    -webkit-flex-direction: row-reverse;
}
.group-set-wrap {
	cursor: pointer;
}

.message-star {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.message-star .avatar {
	width: 26px;
	height: 26px;
	margin-right: 10px;
}

.message-star .avatar img {
	width: 100%;
}

.message-star h6 {
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0;
}
.message-star h6 span i {
	color: #FEC001;
}

.chat .chat-body .messages .chats .message-content a {
	color:#53BDEB;
}
.chat .chat-body .messages .chats.chats-right {
	margin-left: auto;
}
.chat-voice-group ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	padding: 0;
	margin-bottom: 0;
}
.chat-voice-group ul li span {
	width: 28px;
	height: 28px;
	background: #680A83;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-voice-group li {
	margin-bottom: 0;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	list-style: none;
}
.chat-voice-group li+li {
	padding-left: 10px;
}

.chat .chat-body .messages .chats.chats-right .chat-time {
	justify-content: flex-end;
}

.chat .chat-body .messages .chats.chats-right .message-content {
	background-color: #F8ECFF;
	color: #232323;
	border-radius: 15px 0px 15px 15px;
	padding: 15px;
}

.chat .chat-body .messages .chats.chats-right .chat-action-btns {
	order: 2;
	-webkit-order: 2;
	display: flex;
	display: -webkit-flex;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	justify-content: center;
	-webkit-justify-content: center;
}

.chat .chat-body .messages .chats.chats-right .chat-action-btns .chat-action-col {
	width: 20px;
	text-align: center;
}

.chat .chat-body .messages .chats.chats-right .chat-action-btns .chat-read-col {
	display: flex;
	display: -webkit-flex;
	align-items: flex-end;
	-webkit-align-items: flex-end;
	padding-bottom: 22px;
	color: #008024;
}

.chat .chat-body .messages .chats.chats-right .chat-content {
	order: 1;
	-webkit-order: 1;
}

.chat .chat-body .messages .chats.chats-right .chat-avatar {
	padding: 8px 0px 0 15px;
}

.chat .chat-body .messages .chats.chats-right .message-content .chat-time {
	justify-content: flex-start;
	-webkit-justify-content: flex-start;
}

.chat .chat-body .messages .chats.chats-right .message-content .chat-time .time {
	color: #909090;
}

.chat .chat-body .messages .chats.chats-right .message-content .chat-time .time i {
	color: #A3C2FF;
}

.chat .chat-body .messages .download-col {
	position: relative;
}

.chat .chat-body .messages .download-col ul {
	list-style: none;
	padding: 0;
	margin: 0 0 5px 0;
	display: flex;
	display: -webkit-flex;
}

.chat .chat-body .messages .download-col ul li+li {
	margin-left: 5px;
}

.chat .chat-body .messages .download-col .image-download-col {
	position: relative;
}

.chat .chat-body .messages .download-col .image-download-col a > img {
	border: 1px solid transparent;
	width: 80px;
	border-radius: 8px;
}
.chat .chat-body .messages .chats .message-content.fancy-msg-box .image-not-download span {
    position: absolute;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
} 
.chat .chat-body .messages .download-col .image-download-col.image-not-download a > img {
	-webkit-filter: blur(1px);
	filter: blur(1px);
}

.chat .chat-body .messages .download-col .download-action,
.chat .chat-body .messages .file-download .download-action,
.file-download-col .download-action {
	position: absolute;
	right: 8px;
	bottom: 8px;
	line-height: 0;
}

.chat .chat-body .messages .file-download .download-action {
	bottom: 14px;
}

.chat .chat-body .messages .download-col .download-action a,
.chat .chat-body .messages .file-download .download-action a,
.file-download-col .download-action a {
	color: #fff;
	font-size: 10px;
}

.chat .chat-body .messages .download-col .download-action div+div,
.chat .chat-body .messages .file-download .download-action div+div,
.file-download-col .download-action div+div {
	margin-left: 8px;
}

.chat .chat-body .messages .download-col .download-action div img,
.chat .chat-body .messages .file-download .download-action div img,
.file-download-col .download-action div img {
	width: 13px;
}

.chat .chat-body .messages .file-download {
	position: relative;
	background: #F5F5F5;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 7px;
}

.chat .chat-body .messages .file-download .file-type {
	width: 54px;
	height: 54px;
	background-color: #fff;
	border-radius: 4px;
}
.chat .chat-body .messages .file-download .file-type i {
	color: #9E9E9E;
	font-size: 24px;
}

.chat .chat-body .messages .file-download .file-details span.file-name {
	color: #0A0A0A;
	display: inline-block;
	width: 100%;
	font-size: 15px;
	font-weight: 500;
}

.chat .chat-body .messages .file-download .file-details span.file-size {
	color: #909090;
	display: inline-block;
	width: 100%;
}

.chat .chat-body .messages .file-download .file-details ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0;
	margin-bottom: 0;
}
.chat .chat-body .messages .file-download .file-details ul li {
	list-style: none;
	color: #424242;
	font-size: 14px;
	position: relative;
}
.chat .chat-body .messages .file-download .file-details ul li::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 13px;
	background: #C2C2C2;
	right: -8px;
    top: 5px;
}
.chat .chat-body .messages .file-download .file-details ul li:last-child::before {
	display: none;
}
.chat .chat-body .messages .file-download .file-details ul li+li {
	padding-left: 15px;
}

.chat .chat-body .messages .file-download .file-details ul li a {
	color: #680A83;
}

.chat .chat-body .messages .file-download .download-action a {
	color: #680A83;
}

.chat-select .select2-container .select2-selection--single {
    border: 0;
    height: 24px;
}
.chat-select .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #344357;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
	font-weight: 600;
}
.chat-select .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 24px;
    right: 7px;
}
.success-pass {
	margin-bottom: 40px;
	text-align: center;
}
.chat-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #344357;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.chat-select .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #344357;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -5px;
}
.chat-select .select2-container--default.form-control-sm .select2-selection--single .select2-selection__arrow {
    top: 7px;
}
.chat-select .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: #344357;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
}
.chat-select .select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 40px;
	padding-left: 0px;
}
.chat-select .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #4B4B9F;
}
.chat-select .select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}
.chat-select .select2-container--open .select2-dropdown--below {
	margin-top: 10px;
}

.review-files p {
	margin-bottom: 5px;
	color: #424242;
	font-size: 14px;
}
.like-chat-grp ul {
	padding: 0;
	margin-bottom: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	margin-top: 10px;
}
.like-chat-grp ul li {
	list-style: none;
}
.like-chat-grp ul li+li {
	margin-left: 10px;
}
.like-chat-grp ul li a {
	border: 1px solid #E0E0E0;
	border-radius: 50px;
	padding: 3px 12px;
	display: block;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: #0A0A0A;
	font-weight: 400;
	font-size: 14px;
}
.like-chat-grp ul li.like-chat a {
	background: #FEF6DC;
}
.like-chat-grp ul li.comment-chat a {
	background: #FFE8E9;
}
.like-chat-grp ul li a img {
	margin-left: 5px;
}
.chat-line {
	margin: 0px 0 20px;
	position: relative;
	text-align: center;
	width: 100%;
}

.chat-line:before {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	background-color: #EEEEEE;
	width: 100%;
	height: 1px;
}

.chat-date {
	background-color: #fff;
	color: #0A0A0A;
	font-size: 14px;
	padding: 4px 20px;
	border-radius: 50px;
	display: inline-block;
	font-weight: 400;
	position: relative;
	border: 1px solid #E0E0E0;
}

.chat .chat-footer {
	position: relative;
}

.chat .chat-footer form {
	display: flex;
	display: -webkit-flex;
	align-items: flex-end;
	-webkit-align-items: end;
	position: absolute;
    bottom: 0px;
    left: 0;
	right: 0;
	padding: 24px;
	background-color: #F5F5F5;
}

.chat .chat-footer form .form-buttons .btn {
	margin-left: 0px;
	color: #BDBFC7;
	font-size: 20px;
	padding: 0;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.chat .chat-footer form .form-buttons .btn:last-child {
	margin-right: 0;
}

.chat .chat-footer form .specker-col span {
	color: #680A83;
	font-size: 25px;
}

.chat .chat-footer form .attach-col i,
.chat .chat-footer form .smile-col i {
	color: #680A83;
	font-size: 20px;
}

.chat .chat-footer form .smile-col i {
	font-size: 22px;
}

.chat .chat-footer form .smile-col {
	right: inherit;
	left: 15px;
}

.chat .chat-footer form .attach-col {
	right: inherit;
	left: 60px;
}

.chat+.sidebar-group {
	margin-right: 0px;
}

.chat+.sidebar-group .sidebar {
	margin-right: 0px;
}
.group-comman-theme {
	padding: 0 24px;
}
.group-comman-theme h6 {
	margin-bottom: 15px;
}
.group-comman-theme ul {
	padding: 0;
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #EDEDED;
}

.group-comman-theme ul li {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	list-style: none;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	padding: 5px;
	margin-bottom: 10px;
}
.group-comman-theme ul li:last-child {
	margin-bottom: 0;
}
.group-comman-theme ul li .avatar {
	flex-shrink: 0;
	margin-right: 10px;
}
.theme-content h6 {
	margin-bottom: 6px;
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
}
.theme-content p {
	margin-bottom: 0;
	text-align: start;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
	color: #424242;
	font-size: 14px;
}
.chat-message-grp {
	padding: 24px;
	padding-top: 0;
}
.chat-message-grp ul {
	padding: 0;
}
.chat-message-grp ul li {
	list-style: none;
	margin-bottom: 15px;
}
.chat-message-grp ul li:last-child {
	margin-bottom: 0px;
}
.chat-message-grp ul li a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.chat-message-grp ul li a:hover .stared-group h6 {
	color: #680A83;
}
.stared-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.stared-group span {
	width: 30px;
	height: 30px;
	border-radius: 6px;
	margin-right: 10px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.star-message {
	background: #FEF6DC;
}
.star-message i {
	color: #FEC001;
}
.mute-message {
	background: #EBFDFFFA;
}
.mute-message i {
	color: #02A2B9;
}
.block-message {
	background: #FFE8E9;
}
.block-message i {
	color: #DE3E44;
}
.report-message {
	background: #F8ECFF;
}
.report-message i {
	color: #680A83;
}
.delete-message {
	background: #FFE8E9;
}
.delete-message i {
	color: #DE3E44;
}
.count-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.count-group span {
	width: 30px;
	height: 30px;
	border-radius: 50px;
	color: #424242;
	font-size: 14px;
	background: #F5F5F5;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	margin-right: 5px;
}
.count-group i {
	color: #424242;
	font-size: 16px;
}
.stared-group h6 {
	font-size: 15px;
	font-weight: 500;
	color: #0A0A0A;
	margin-bottom: 0;
}
.header {
	/* background-image: url('../img/top-curve-bg.png'); */
	position: relative;
	width: 100%;
	height: 190px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	overflow: initial;
	padding: 20px 20px;
}

.nav.nav-tabs.chat-tabs .nav-item .nav-link {
	font-size: 16px;
	font-weight: bold;
	color: #fff;
	background-color: transparent;
	border: 0;
}

.nav.nav-tabs.chat-tabs .nav-item .nav-link.active {
	font-size: 16px;
	font-weight: bold;
	color: #FFA977;
}

.header-top ul li a {
	color: #000;
	font-size: 18px;
}

.sidebar .list-group-item.unread h5 {
	color: #fff;
}

.sidebar .list-group-item.unread p {
	color: #fff;
}

.sidebar .list-group-item.unread .text-muted {
	color: rgba(255, 255, 255, 0.4) !important;
}

.chat .chat-footer form .form-buttons .btn.send-btn {
	width: 40px;
	height: 40px;
	border-radius: 6px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: #fff !important;
	background-color: #680A83;
	margin-left: 10px;
}
.set-search {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	border-radius: 50px;
	width: 28px;
	height: 28px;
}
.set-search:hover {
	background: #F5F5F5;
	border-color: #F5F5F5;
}
.chat .chat-footer form .form-buttons .btn.send-btn:hover {
	background-color: #fff;
	border-color: #680A83;
}
.chat .chat-footer form .form-buttons .btn.send-btn:hover i {
	color: #680A83;
}

.chat .chat-footer form .form-buttons .btn.send-btn:focus {
	box-shadow: none;
}
.chat .chat-footer form .smile-foot {
	margin-right: 10px;
}
.chat .chat-footer form .smile-foot .action-circle {
	width: 40px;
	height: 40px;
	background: #fff;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat .chat-footer form .smile-foot .action-circle:hover {
	background-color: #EDEDED;
	border-color: #EDEDED;
}
.chat .chat-footer form .smile-foot .action-circle i {
	color: #424242;
	font-size: 18px;
}
.chat .slimScrollDiv {
	height: 100% !important;
}
.dropdown-menu .dropdown-item span.star-msg,
.dropdown-menu .dropdown-item span.star-msg-one,
.dropdown-menu .dropdown-item span.star-msg-three,
.dropdown-menu .dropdown-item span.star-msg-four,
.dropdown-menu .dropdown-item span.star-msg-five {
	font-size: 14px;
    cursor: pointer;
    font-weight: 500;
    color: #424242;
}

.header-action {
	list-style: none;
	margin: 0;
	padding: 0;
}

.header-action li {
	display: inline-block;
	margin-left: 10px;
}

.header-action li a {
	color: #1b1a1a;
	font-size: 18px;
}

.user-list {
	border-radius: .25rem;
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}

.user-list li a {
	padding: 17px 20px;
	display: flex;
	display: -webkit-flex;
	cursor: pointer;
	border-radius: 6px;
	background-color: #fff;
	margin-bottom: 10px;
	border: 0;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.user-list li .avatar {
	margin-right: 0.8rem;
	display: inline-block;
	margin-bottom: 0;
	height: 46px;
	width: 46px;
	border-radius: 50%;
	position: relative;
	z-index: 0;
}

.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
	content: "";
	position: absolute;
	display: block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	top: 0px;
	right: 2px;
	border: 2px solid #fff;
	z-index: 9;
}

.avatar-online::before {
	background-color: #34D859;
}

.avatar-away::before {
	background-color: #FFE600;
}
.animate-typing-col {
	color: #0D9B35;
}
.animate-typing-col .dot {
	display: inline-block;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	margin-right: -1px;
	background: #0D9B35;
	-webkit-animation: wave 1.3s linear infinite;
	animation: wave 1.3s linear infinite;
	opacity: .6
}

.animate-typing-col .dot:nth-child(2) {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s
}

.animate-typing-col .dot:nth-child(3) {
	-webkit-animation-delay: -.9s;
	animation-delay: -.9s
}

@-webkit-keyframes wave {
	0%,
	100%,
	60% {
		-webkit-transform: initial;
		transform: initial
	}
	30% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px)
	}
}

@keyframes wave {
	0%,
	100%,
	60% {
		-webkit-transform: initial;
		transform: initial
	}
	30% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px)
	}
}
.user-list li .avatar .letter-avatar {
	width: 45px;
	height: 45px;
	border-radius: 45px;
	margin-right: 0.8rem;
	margin-bottom: 0;
	position: relative;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	background-color: #E8DBFF;
	font-size: 16px;
	font-weight: 600;
	color: #420BA1;
}

.chat_form {
	border-radius: 20px;
	margin-right: 0px;
	background: #FFFFFF;
	border: 1px solid #C2C2C2;
	box-shadow: 0px 4px 4px #F5F8FF;
	border-radius: 6px;
	height: 40px;
	padding: 10px 10px;
}

.chat .chat-footer form .form-control::-webkit-input-placeholder {
	color: #424242;
	opacity: 1;
}

.chat .chat-footer form .form-control::-moz-placeholder {
	color: #424242;
	opacity: 1;
}

.chat .chat-footer form .form-control:-ms-input-placeholder {
	color: #424242;
	opacity: 1;
}

.chat .chat-footer form .form-control:-moz-placeholder {
	color: #424242;
	opacity: 1;
}

.user-list .avatar > a > img,
.user-list .avatar > img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.contact-close a {
	color: #292F4C;
	opacity: 0.5;
	border-color: #e6e6e6;
}

.main-wrapper .content .sidebar-group.right-sidebar .sidebar {
	width: 310px;
}

.primary-title {
	color: #FFA977;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0;
}

.primary-title i {
	font-size: 14px;
}

.contact-action {
	padding: 0;
	margin: 0;
	list-style: none;
}

.contact-action li {
	padding: 11px 0;
	border-bottom: 1px solid #f1f1f1;
}

.contact-action li a {
	color: #181C2F;
}

.contact-action li a {
	color: #181C2F;
	font-size: 14px;
	font-weight: bold;
}

.contact-action li.report-contact a,
.contact-action li.delete-chat a,
.contact-action li.sign-out a {
	color: #f00;
}

.left-sidebar {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
}

.left-sidebar-wrap {
	background: #FBFBFB;
	overflow: hidden;
	width: 448px;
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	flex: 1;
}

.right-sidebar {
	display: flex;
	display: -webkit-flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	background-color: #FAFBFF;
	opacity: 100%;
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	-ms-transition: all .5s ease;
	position: relative;
	width: 320px;
}

.right-sidebar.video-right-sidebar {
	width: 371px;
}
.video-call-chat .left-chat-title.with-bg {
	background: transparent;
}
.video-call-chat .left-chat-title {
    border: 0;
}
.chat.video-call-chat .chat-body {
	background-image: none;
}
.video-call-chat .chat-title h4 {
    font-size: 18px;
    font-weight: 600;
    color: #424242;
}
.video-call-chat .chat-title span {
    font-size: 12px;
    font-weight: 400;
    color: #424242;
}
.chat.video-call-chat .chat-body .messages .chats .message-content {
	background: #F5F5F5;
}
.chat.video-call-chat .chat-body .messages .chats.chats-right .message-content {
	background: #F8ECFF;
}
.right-sidebar.video-right-sidebar .chat .chat-body {
	padding-bottom: 110px;
}
.member-details {
	margin-bottom: 10px;
}
.chat-member-details {
	padding: 0 24px;
}
.chat-member-details .member-details ul {
	padding: 0;
	border-bottom: 1px solid #EDEDED;
}
.chat-member-details .member-details ul li {
	list-style: none;
	margin-bottom: 15px;
}
.chat-member-details .member-details ul li h6 {
	font-size: 15px;
	font-weight: 400;
	color: #424242;
	margin-bottom: 5px;
}
.chat-member-details .member-details ul li h5 {
	font-size: 15px;
	font-weight: 500;
	color: #0A0A0A;
	margin-bottom: 0px;
}
.chat-member-details .member-details ul li span {
	font-size: 16px;
	font-weight: 400;
	color: #0A0A0A;
}
.chat-member-details .member-details ul li a {
	font-size: 15px;
	font-weight: 500;
	color: #680A83;
	border-radius: 100px;
	padding: 5px;
    background: #F5F5F5;
	display: flex;
	align-items: center;
	justify-content: center;
}
.chat-member-details .member-details ul li a+a {
	margin-left: 10px;
}
.chat-member-details .member-details ul li a:hover {
	color: #0A0A0A;
}
.right-sidebar .right-sidebar-wrap {
	background-color: #fff;
	border-left: 1px solid #E0E0E0;
	border-radius: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
	height: calc(100vh - 0px);
}

.right-sidebar .right-sidebar-wrap .sidebar-body {
	padding: 0;
}

.right-sidebar .right-sidebar-wrap .right-sidebar-profile {
	padding: 24px;
	padding-bottom: 0;
	margin-bottom: 15px;
}

.right-sidebar.show-right-sidebar {
	margin-right: 0;
	z-index: 9999;
}

.right-sidebar.video-right-sidebar.show-right-sidebar {
	margin-right: 0;
}

.right-sidebar.video-right-sidebar .chat {
	margin-left: 0 !important;
	display: block;
}

.right-sidebar.hide-right-sidebar {
	margin-right: -320px;
}

.right-sidebar.video-right-sidebar.hide-right-sidebar {
	margin-right: -371px;
}

.right-sidebar .contact-close_call .close_profile,
.status-modal .custom-status-close {
	width: 25px;
	height: 25px;
	border-radius: 25px;
	color: #0A0A0A;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	background-color: #F5F5F5;
    border-color: #F5F5F5;
}
.group-description .description-sub h5 span a {
	width: 25px;
	height: 25px;
	border-radius: 25px;
	color: #0A0A0A;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
    border-color: #F5F5F5;
}

.list_group_notread {
	color: #ffa977 !important;
}
.tooltip > .tooltip-inner {
    text-align: left;
    max-width: 500px;
    --bs-tooltip-bg: #680A83;
}
.tooltip {
    --bs-tooltip-bg: #680A83;
}
.tooltip .arrow {
	display: none;
	background-color: #680A83;
}
.tooltip .tooltip-inner {
	background-color: #680A83;
}
.chat-cont-type {
   display: flex;
   align-items: center;
   justify-content: center;
}
.chat-cont-type .chat-type-wrapper {
   margin-bottom: 0;
}
.chat-cont-type .chat-type-wrapper p {
	color: #9E9E9E;
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 0;
}

.group-type-radio {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  
  .group-type-radio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
  }
  .group-type-radio {
	font-size: 16px;
	font-weight: 400;
	color: #424242;
	margin-right: 24px;
  }
  

  .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 22px;
	width: 22px;
	background-color: #fff;
	border: 1px solid #E4E4E4;
	border-radius: 50%;
  }
  .pass-login label {
	color: #0A0A0A;
	font-size: 14px;
	font-weight: 500;
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #680A83 !important;
  }
  
  .group-type-radio input:checked ~ .checkmark {
	background-color: #680A83;
  }
  
  
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  .group-type-radio input:checked ~ .checkmark:after {
	display: block;
  }

  .group-type-radio .checkmark:after {
	   top: 4px;
	  left: 4px;
	  width: 12px;
	  height: 12px;
	  border-radius: 50%;
	  background: white;
  }

.right-sidebar-profile > .avatar {
	background: rgba(66, 11, 161, 0.1);
}
.right-sidebar-profile .profile-name span i {
	font-size: 16px;
}

.group_img {
	position: relative;
	display: inline-block;
}
.group-name-search {
	margin-bottom: 14px;
}
.parti-wrapper {
	margin-bottom: 14px;
	display: flex;
	align-items: center;
}

.group_header {
	width: 38px !important;
	height: 38px !important;
	border: 2px solid #FFFFFF;
}

.last-chat-time i.missed-col {
	width: 17px;
	display: inline-block;
	margin-top: 10px;
}

.last-chat-time i.missed-col img {
	max-width: 100%;
	height: auto;
}
.right-sidebar-head {
	padding: 0px 24px;
}
.right-sidebar-head.share-media .share-media-blk {
    margin-bottom: 20px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	
}
.right-sidebar-head h5 {
    font-size: 15px;
    font-weight: 600;
    color: #1F2937;
    margin-bottom: 0px;
}
.right-sidebar-head.share-media .about-media-tabs .nav-tabs {
	margin-bottom: 15px;
	background: #EDEDED;
	padding: 5px;
	border-radius: 8px;
	display: flex;
    justify-content: space-between;
}
.right-sidebar-head.share-media .about-media-tabs {
	border-bottom: 1px solid #EDEDED;
	padding-bottom: 10px;
	margin-bottom: 15px;
}
.right-sidebar-head.share-media .about-media-tabs .nav .nav-item.active {
    background: #fff;
    border-radius: 6px;
    color: #424242;
}
.right-sidebar-head.share-media .about-media-tabs .nav .nav-item {
	padding: 2px 10px;
    font-size: 14px;
    color: #424242;
    font-weight: 500;
    border: 0;
	margin-right: 10px;
}
.right-sidebar-head.share-media .about-media-tabs .nav .nav-item:last-child {
	margin-right: 0px;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li {
    margin: 0px 6px 6px 0px;
	position: relative;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li img {
    border-radius: 8px;
	width: 84px;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li span i {
	font-size: 20px;
	color: #fff;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li:hover span i {
	color: #eee;
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li.blur-media img {
    filter: blur(2px);
}
.right-sidebar-head.share-media .about-media-tabs .share-media-img li span {
    position: absolute;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.media-file {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 15px;
}
.media-file:last-child {
	margin-bottom: 0;
}
.media-doc-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.media-doc-blk span {
	/* width: 50px; */
	height: 50px;
	border-radius: 5px;
	background: #F5F5F5;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	margin-right: 10px;
	padding: 10px;
}
.media-doc-blk span i {
	font-size: 24px;
}
.document-detail ul  {
	padding: 0;
	margin-bottom: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.document-detail ul li {
	list-style: none;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	position: relative;
}
.document-detail ul li::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 13px;
	background: #C2C2C2;
	right: -8px;
    top: 5px;
}
.document-detail ul li:last-child::before {
	display: none;
}
.document-detail ul li+li {
	padding-left: 15px;
}
.document-detail h6 {
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
}
.media-download a {
	color: #424242;
}
.social-media-col {
	margin-top: 35px;
}
.media-link-grp {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 15px;
}
.media-link-detail h6 a {
	color: #0A0A0A;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 5px;
}
.media-link-detail span a {
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	word-break: break-all;
}
.media-link-detail a:hover {
	color: #680A83;
}
.share-media-blk a {
	color: #680A83;
	font-weight: 500;
}
.link-img img {
	border-radius: 4px;
	margin-right: 10px;
}

.social-media-col:after {
	content: "";
	display: table;
	clear: both;
}

.social-media-col ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.social-media-col ul li {
	float: left;
}

.social-media-col ul li+li {
	margin-left: 10px;
}

.social-media-col ul li a {
	color: #420BA1;
	font-size: 18px;
}

.social-media-col h6,
.settings-col h6 {
	font-weight: 600;
	color: #000;
	font-size: 15px;
	margin-bottom: 20px;
}

.settings-col {
	margin-top: 35px;
}

.settings-col:after {
	content: "";
	display: table;
	clear: both;
}

.settings-col ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.settings-col ul li {
	color: #939393;
	font-weight: 500;
}

.settings-col ul li+li {
	margin-top: 15px;
}

.settings-col .switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 23px;
	margin-bottom: 0;
	margin-right: 10px;
}

.settings-col .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.settings-col .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-col .slider:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 15px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-col input:checked+.slider {
	background-color: #2196F3;
}

.settings-col input:focus+.slider {
	box-shadow: 0 0 1px #2196F3;
}

.settings-col input:checked+.slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
}

.settings-col .slider.round {
	border-radius: 34px;
}

.settings-col .slider.round:before {
	border-radius: 50%;
}

.report-col {
	margin-top: 35px;
	padding: 12px;
	padding-bottom: 50px;
}

.report-col:after {
	content: "";
	display: table;
	clear: both;
}

.report-col ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.report-col ul:after {
	content: "";
	display: table;
	clear: both;
}

.report-col ul li {
	display: block;
	width: 100%;
	font-weight: 500;
	position: relative;
}

.report-col ul li a {
	color: #FF0000;
	padding-left: 25px;
}

.report-col ul li a span {
	position: absolute;
	left: 0;
	top: 0px;
	font-size: 16px;
}

.report-col ul li+li {
	margin-top: 19px;
}

.file-download-col {}

.file-download-col:after {
	content: "";
	display: table;
	clear: both;
}

.file-download-col ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.file-download-col ul li {
	float: left;
	width: 50%;
	padding: 5px;
}

.file-download-col ul li.full-width {
	width: 100%;
}

.file-download-col ul li .image-download-col {
	position: relative;
}

.file-download-col ul li .image-download-col a > img {
	width: 100%;
}

.load-more-btn {
	font-weight: 600;
	margin-top: 10px;
}

.load-more-btn i {
	background: #0057FF;
	border-radius: 50%;
	height: 12px;
	width: 12px;
	color: #fff;
	line-height: 8px;
	font-size: 12px;
	vertical-align: middle;
}
.chat-message-grp.chat-encrypt {
	padding-bottom: 0;
}
.chat-message-grp.chat-encrypt ul {
	border-bottom: 1px solid #EDEDED;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.participants-list {
	padding: 0px 24px;
}
.participants-list-group {
	border-bottom: 1px solid #EDEDED;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.participants-list-group h6 {
	margin-bottom: 15px;
	font-size: 15px;
	font-weight: 500;
	color: #0A0A0A;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.participants-list .participants-list-group h6.parti-search {
	font-size: 20px !important;
	display: flex;
	align-items: center;
}
.participants-list ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.participants-list ul li {
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	background-color: #fff;
	padding: 10px;
}

.participants-list ul li+li {
	margin-top: 10px;
}

.participants-list .users-list-body h5 {
	/* white-space: nowrap; */
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 5px;
	color: #0A0A0A;
}

.participants-list .users-list-body p {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 0;
	color: #424242;
	font-size: 14px;
	font-weight: 400;
}

.participants-list .users-list-body {
	flex: 1;
	position: relative;
	min-width: 0px;
	display: flex;
	display: -webkit-flex;
	width: 100%;
}
.user-list li a.admin-shade {
	box-shadow: none;
}
.participants-list .admin-profiles {
	display: inline-block;
	color: #680A83;
	border-radius: 6px;
	font-size: 12px;
	padding: 2px 10px;
	font-weight: 500;
	background: #F8ECFF;
}
.participants-list .admin-profiles:hover {
	background: #680A83;
	color: #fff;
}
.chat-message-grp.group-exits {
	padding: 0px 24px;
	padding-bottom: 30px;
}
.grp-delete .chat-action-col {
	display: flex;
	align-items: center;
	height: 100%;
}

.grp-delete .chat-action-col > a {
	color: #C8C8D8;
}

.chat-action-col > a {
	color: #424242;
}

.chat-profile-name .chat-action-col  a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.darkmode-btn {
	width: 35px;
	height: 32px;
	background-color: #0057FF;
	border: 1px solid #FBFBFB;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	margin: 0 auto;
}

.darkmode-btn:hover,
.darkmode-btn:focus,
.add-contacts-btn:hover,
.add-contacts-btn:focus {
	color: #fff;
}

.add-contacts-btn {
	width: 25px;
	height: 25px;
	border-radius: 30px;
	background-color: #680A83;
	color: #fff;
	font-size: 10px;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	margin: 0 auto;
}

.group-call-tab .nav-tabs .nav-link {
	color: #680A83;
	border: 0;
	border-radius: 10px;
	font-weight: 500;
	padding: 6px 25px;
}

.group-call-tab .nav-tabs .nav-link:hover {
	color: #680A83;
}

.group-call-tab .nav-tabs .nav-link+.nav-link {
	margin-left: 10px;
}

.group-call-tab .nav-tabs .nav-link.active {
	background-color: rgba(90, 7, 139, 0.1);
	color: #680A83;
}

.group-call-tab nav {
	padding: 0 15px 20px;
	position: relative;
}

.group-call-tab nav:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: #E2E2E2;
	height: 1px;
}

/*-----------------
  9. Settings
-----------------------*/

.profile-cover-avatar {
	display: -ms-flexbox;
	display: flex;
	margin: auto;
	border-radius: 50%;
	position: relative;
	width: 144px;
	height: 144px;
}

.profile-cover-avatar .avatar-img {
	display: block;
	max-width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	pointer-events: none;
	border-radius: 50%;
}

.profile-cover-avatar input[type="file"] {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: -1;
	opacity: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(19, 33, 68, .25);
	border-radius: 50%;
	transition: .2s;
}
.profile-cover {
	margin-bottom: 30px;
}
.avatar-edit {
	position: absolute;
	bottom: 0;
	right: 0;
	cursor: pointer;
	border-radius: 50%;
	width: 42px;
	height: 42px;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	background-color: #680A83;
	border-radius: 50%;
	transition: .2s;
	border: 3px solid #FFFFFF;
}
.profile-cover-avatar .avatar-edit i {
	font-size: 20px;
}

.avatar-edit svg {
	width: 15px;
	height: 15px;
}

.profile-email {
	color: #9B9B9B;
	font-size: 13px;
}

.profile-country {
	color: #680A83;
	font-size: 1rem;
}

.profile-name {
	color: #680A83;
	font-size: 20px;
	font-weight: 600;
}

.info-title {
	color: #424242;
	font-weight: 400;
	font-size: 14px;
}

.info-text {
	font-weight: 600;
	color: #0A0A0A;
	font-size: 16px;
}
.profile-listout {
	margin-bottom: 20px;
}
.social-icons {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.social-icons a:hover i {
	color: #680A83;
}
.social-icons a i {
	font-size: 20px;
	color: #0A0A0A;
}
.social-nav li {
	list-style: none;
	display: inline-block;
	padding-right: 15px;
}

.social-nav li:last-child {
	padding-right: 0;
}

.social-nav li a i {
	font-size: 16px;
	color: #420BA1;
}

.social-nav li a i:hover {
	color: #680A83;
}

.settings-card {
	padding: 24px;
	background: #fff;
}

.settings-control ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.settings-control ul li {
	margin-bottom: 15px;
}
.settings-control ul li:last-child {
	margin-bottom: 0px;
}
.settings-control ul li a {
	font-weight: 500;
	color: #424242;
	font-size: 15px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.settings-control ul li a:hover {
	color: #680A83;
}
.settings-control ul li a i {
	font-size: 16px;
}
.setting-card-list {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.setting-card-list img,
.setting-card-list i {
	margin-right: 5px;
}

.settings-control .switch {
	position: relative;
	display: inline-block;
	width: 35px;
	height: 17px;
	margin-bottom: 0;
}

.settings-control .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.settings-control .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-control .slider:before {
	position: absolute;
	content: "";
	height: 10px;
	width: 10px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-control input:checked+.slider {
	background-color: #2196F3;
}

.settings-control input:focus+.slider {
	box-shadow: 0 0 1px #2196F3;
}

.settings-control input:checked+.slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
}

.settings-control .slider.round {
	border-radius: 34px;
}

.settings-control .slider.round:before {
	border-radius: 50%;
}
.right-sidebar.full-width {
	width: 900px;
	background: #fff;
	position: relative;
}

.page-header {
	padding: 12px 15px;
	align-items: center;
	box-shadow: 0px 4px 4px #f5f8ff;
	border-radius: 5px;
	background: #FFFFFF;
	border: 1px solid #F4EEFF;
}

.page-header p {
	font-size: 13px;
}

.page-header h5 {
	font-weight: 700;
	color: #420BA1;
	margin-bottom: .25rem;
}

.full-width .right-sidebar-wrap {
	border: 0;
}

.settings-tab .nav-tabs .nav-link.active {
	color: #0057FF;
	font-weight: 500;
	border-color: #0057FF;
}

.settings-tab .nav-tabs .nav-link {
	border: 0;
	color: #000;
	border-bottom: 2px solid transparent;
	padding: 3px 9px;
	font-size: 15px;
	font-weight: 500;
	margin-right: 30px;
}

.settings-tab .nav.nav-tabs {
	border-bottom: 1px solid #F0F0F0;
	margin-bottom: 1.5rem;
}

.form-body .form-group label {
	font-weight: 600;
	color: #464646;
}

.social-settings h4 {
	color: #680A83;
	font-size: 18px;
	font-weight: 600;
}

.form-body input::-webkit-input-placeholder {
	color: #000000;
}

.form-body input:-ms-input-placeholder {
	color: #000000;
}

.form-body input::placeholder {
	color: #000000;
}

.settings-delete h5 {
	color: #680A83;
	font-size: 18px;
	font-weight: 600;
}

.settings-delete p {
	color: #949494;
	font-size: 12px;
}

.settings-header {
	background: #F9F9F9;
	padding: 15px;
}

.settings-header h5 {
	color: #680A83;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 7px;
}

.settings-header p {
	color: #949494;
	font-size: 12px;
}

.settings-control.full-options {
	padding: 0 0 25px;
}

.settings-control.full-options ul li {
	color: #515151;
	font-weight: 500;
	border-bottom: 0;
	padding: 25px 20px 0;
}

.device-details h5 {
	font-weight: 700;
	color: #420BA1;
	font-size: 16px;
	margin-bottom: .25rem;
}

.device-details p {
	font-weight: 500;
	color: #9B9B9B;
}

.security-settings {
	border: 1px solid #F9F9F9;
}

.success-icon.delete-tab {
	padding: 40px 0;
}

.success-icon.delete-tab p {
	font-size: 20px;
}

.setting-title-head h4 {
	font-size: 18px;
    font-weight: 600;
    color: #0A0A0A;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}
.setting-title-head h4 a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 5px;
}
.account-setting  {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 24px;
}
.account-setting a:hover {
	color: #650681;
}
.account-setting a i {
	font-size: 18px;
}
.account-setting  h5 {
	color: #424242;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0;
}
.setting-profile-card {
	padding: 24px;
	border-bottom: 1px solid #C2C2C2;
}
.last-seen .active-online {
	color: #1BA345;
}
.social-profile-blk {
	margin-bottom: 20px;
}
.social-profile-blk ul li {
	margin-bottom: 15px;
}
.social-profile-blk ul li:last-child {
	margin-bottom: 0px;
}
.social-profile-blk ul li a {
	border: 1px solid #C2C2C2;
	display: block;
	border-radius: 6px;
	padding: 10px;
}
.social-profile-blk ul li h6 {
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 0;
}
.login-security h6 {
	color: #0A0A0A;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 15px;
}
.form-control {
    min-height: 40px;
    font-size: 14px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}
.form-control::placeholder {
	color:#212529;
}
.login-security .pass-group {
	position: relative;
}
.login-security form {
	margin-bottom: 20px;
}
.login-security .pass-group .toggle-passwords,
.login-security .pass-group .toggle-password,
.login-security .pass-group .conform-toggle-password {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    font-weight: 500;
}

.pass-login h4 {
	margin-bottom: 15px;
	color: #0A0A0A;
	font-size: 14px;
	font-weight: 500;
}
.settings-switch ul li {
	background: #F8ECFF;
	padding: 10px;
	margin-bottom: 24px;
	font-size: 16px;
	color: #0A0A0A;
	font-weight: 500;
}
.settings-switch .switch {
	position: relative;
	display: inline-block;
	width: 35px;
	height: 17px;
	margin-bottom: 0;
}

.settings-switch .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.settings-switch .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #EDEDED;
	border: 1px solid #C2C2C2;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-switch .slider:before {
	position: absolute;
	content: "";
	height: 13px;
	width: 13px;
	left: 1px;
	bottom: 1px;
	background-color: #C2C2C2;
	-webkit-transition: .4s;
	transition: .4s;
}

.settings-switch input:checked+.slider {
	background-color: #1BA345;
	border: 1px solid #1BA345;
}
.settings-switch input:checked+.slider::before {
	background-color: #fff;
}

.settings-switch input:focus+.slider {
	box-shadow: 0 0 1px #1BA345;
}

.settings-switch input:checked+.slider:before {
	-webkit-transform: translateX(16px);
	-ms-transform: translateX(16px);
	transform: translateX(16px);
	background-color: #fff;
}

.settings-switch .slider.round {
	border-radius: 34px;
}

.settings-switch .slider.round:before {
	border-radius: 50%;
}
.privacy-detail-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.privacy-detail-blk {
	margin-bottom: 20px;
}
.privacy-content h6 {
	color: #0A0A0A;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 5px;
}
.privacy-content p {
	color: #424242;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0;
}
.privacy-content.chat-content {
	width: 100%;
}
.privacy-content.chat-content h6 {
	margin-bottom: 0;
}
.privacy-content.chat-content h6 a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.logout-group p {
	color: #424242;
	font-size: 16px;
}
.connectetapps {
    margin-bottom: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.connectetappsimg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.connectet-img {
    width: 46px;
    height: 46px;
    background: #eee;
    padding: 10px;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.connectet-content {
    margin-left: 15px;
}
.connectet-content p {
    color: #424242;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 5px;
}
.connectet-content h6 {
	color: #0A0A0A;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
}
.connectetappscontent {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.connectetappscontent .btn-primary {
	color: #680A83!important;
	border: 2px solid #680A83;
	background: #fff !important;
	margin-right: 10px;
}
.connectetappscontent .btn-primary:hover {
	box-shadow: 0 50px 0 0 #680A83 inset;
	color: #fff !important;
}
.device-modal.modal .modal-dialog {
	max-width: 900px;
}
.device-remove a {
	width: 38px;
	height: 38px;
	background: #F5F5F5;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: #424242;
}
.device-remove a:hover {
	background: #ff0100;
	color: #fff;
}
.manage-device-detail {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 30px;
}
.manage-device-detail a:hover img {
	filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(42%) contrast(108%)
}
.manage-histry h6 {
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 5px;
}
.manage-histry p {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0px;
}
.modal-body label span {
    color: #DE3E44;
}
.user-block-group .search_chat {
	position: relative;
	margin-bottom: 14px;
}
.user-block-group .search_chat .form-control {
	padding-left: 35px;
}
.user-block-group {
	margin-bottom: 15px;
}
.user-block-group h5 {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 14px;
	color: #0A0A0A;
}
.recent-block-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	margin-bottom: 15px;
}
.user-block-profile {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.user-block-profile .avatar {
	margin-right: 10px;
}
.user-blocked .btn-primary {
	padding: 8px 20px;
	font-size: 14px;
}
.block-user-name h6 {
	font-size: 15px;
	color: #0A0A0A;
	font-weight: 500;
	margin-bottom: 5px;
}
.block-user-name span {
	color: #424242;
	font-weight: 400;
	font-size: 14px;
}
.user-blocked i {
	font-size: 20px;
	color: #424242;
}
.privacy-content.notify-content h6 {
	margin-bottom: 0;
}
.border-group {
	border-bottom: 1px solid #EDEDED;
	padding-bottom: 20px;
}
.notification-group .notify-check .form-check .custom-check .checkmark {
    top: -10px;
	left: 10px;
}
.message-info-box {
	margin-bottom: 15px;
}
.message-info-box h6 {
	font-size: 16px;
	font-weight: 500;
	color: #0A0A0A;
	margin-bottom: 5px;
}
.message-info-box span {
	font-size: 14px;
	font-weight: 400;
	color: #424242;
}
.message-info-box .check-active {
	color: #53BDEB;
	margin-right: 10px;
	font-size: 20px;
}
.message-info-box .check {
	color: #424242;
	margin-right: 10px;
	font-size: 20px;
}
.message-info-box .sent {
	color: #9E9E9E;
	margin-right: 10px;
	font-size: 20px;
}
.right-sidebar.hide-message-right {
    margin-right: -320px;
}
.select2-results__option {
	font-size: 14px;
}
.select2-container .select2-selection--single {
    border: 1px solid #E0E0E0;
    height: 40px;
	min-width: 118px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #424242;
    font-size: 14px;
    font-weight: normal;
    line-height: 40px;
	font-weight: 400;
	width: 155px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
    right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #424242;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #424242;
    border-width: 2px 0 0 2px;
    padding: 3px;
    margin-top: -5px;
}
.select2-container--default.form-control-sm .select2-selection--single .select2-selection__arrow {
    top: 7px;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: #424242;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 10px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #4B4B9F;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}
.select2-container--open .select2-dropdown--below {
	margin-top: 0px;
}
.select2-container {
	z-index: 1111;
}
.languages-group .select2-container--default .select2-selection--single .select2-selection__rendered {
	width: auto;
}
/*-----------------
  10. Contact
-----------------------*/

.chat.chat-contact {
	background-image: none;
	background: #F8F6FA;
}
.chat.chat-contact .slimscroll {
	padding: 24px;
}
.chat.chat-contact .chat-header {
	background: none;
	border: 0;
	padding: 0;
}
.chat-options.chat-contact-list ul > li > a {
	background: #fff;
}
.chat.chat-contact .chat-header .avatar {
	width: 100px;
	height: 100px;
}
.chat.chat-contact .chat-header .user-details h5 {
	font-size: 18px;
	color: #0A0A0A;
	font-weight: 600;
}
.call-video span {
	font-size: 20px;
	color: #424242;
}
.call-video span + span{
	margin-left: 10px;
}

.fav-title.contact-title h6 {
	font-size: 18px;
	color: #0A0A0A;
}
.personal-info h5 {
	color: #0A0A0A;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 24px;
}
.personal-info ul li {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #424242;
	margin-bottom: 20px;
}
.personal-info ul li:last-child {
	margin-bottom: 0;
}
.personal-info ul li h6 {
	font-size: 14px;
	font-weight: 400;
	width: 50%;
	margin-bottom: 0;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.personal-info ul li h6 i {
	font-size: 20px;
	margin-right: 10px;
}
.personal-info ul li h6 img {
	margin-right: 10px;
}
.personal-info ul li span {
	font-size: 16px;
	font-weight: 500;
}
.personal-info ul li span a {
	color: #53BDEB;
}
.contact-added {
	font-size: 20px;
}
.dropdown-menu.contact-menus span i {
	font-size: 16px;
	color: #424242;
}
.cal-icon {
	position: relative;
}
.cal-icon span {
	position: absolute;
	right: 10px;
	top: 50%;
	-webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.user-block-group .notify-check .form-check .custom-check .checkmark {
    top: -10px;
	left: 10px;
}
.mute-chat-btn .btn-primary:hover img {
	filter: invert(13%) sepia(85%) saturate(7478%) hue-rotate(266deg) brightness(42%) contrast(108%)
}

/*-----------------
  11. Video Call
-----------------------*/

.chat.video-screen {
	background: transparent;
	background-color: #F8F6FA;
}
.chat.video-screen .chat-body {
	background: transparent;
}
.chat.video-screen .chat-header {
	background: transparent;
	border: 0;
}
.call-wrapper {
	position: relative;
	height: calc(100vh - 145px);
}
.added-call-chat {
    position: absolute;
    right: 20px;
    z-index: 1;
}
.added-call-chat ul li .added-call-front {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    color: #ffffff;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.added-call-chat.fullscreen {
    right: inherit;
    left: 20px;
}

.call-view {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
	width: 75%;
}

.user-img {
	display: inline-block;
	position: relative;
}

.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
}

.bottom-message-col .form_status {
	border-radius: 24px;
	background-color: #d9dadd;
	height: 48px;
	padding: 10px 25px;
	color: #000;
	width: 100%;
	font-size: 14px;
}

.bottom-message-col ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.bottom-message-col.chat {
	display: block;
}

.bottom-message-col.chat .chat_form {
	box-shadow: none;
}

.bottom-message-col.chat .chat-footer {
	position: static;
	background-color: transparent;
}

.status_content {
	z-index: 4000;
	position: fixed;
	top: 40%;
	left: 60%;
}

.status_content_h3 {
	z-index: 4000;
	position: fixed;
	top: 65%;
	left: 45%;
}

.call-icons {
	text-align: center;
	position: relative;
}

.call-duration {
	display: inline-block;
	font-size: 30px;
	margin-top: 4px;
	position: absolute;
	left: 0;
}

.call-icons .call-items {
	border-radius: 5px;
	padding: 0;
	margin: 0;
	list-style: none;
	display: inline-block;
}

.submit-section {
	text-align: center;
	margin-top: 40px;
}

.submit-btn {
	border-radius: 50px;
	font-size: 18px;
	font-weight: 600;
	min-width: 200px;
	padding: 10px 20px;
}

.video-screen-inner {
	/* background-image: url(../../assets/img/vidio-call-bg.jpg); */
	background-image: url(/public/assets/img/vidio-call-bg.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: calc(100vh - 130px);
	border-radius: 10px;
	padding: 20px;
	position: relative;
}

.video-screen-inner.blur-bg {
	z-index:1;
}
.video-screen-inner.audio-screen-inner {
	background-image: url(/public/assets/img/audio-bg.jpg);
	border-radius: 40px;
	padding: 0;
}
.video-screen-inner.audio-screen-inner::before {
	content: "";
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0.69));
    width: 100%;
    height: 100%;
    border-radius: 40px;
}
.chat.video-screen .chat-header .chat-options {
	margin-top: 0;
}

.video-screen .chat-options .in-a-call span.icon-call,
.video-screen .chat-options .add-person-call span.icon-call {
	width: 34px;
	height: 30px;
	background-color: #FD6286;
	color: #fff;
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	border-radius: 4px;
}

.video-screen .chat-options .in-a-call span.call-text,
.video-screen .chat-options .add-person-call span.call-text {
	color: #FD6286;
	font-weight: 600;
	margin-left: 8px;
	display: inline-block;
}

.video-screen .chat-options .add-person-call {
	height: auto !important;
	width: auto !important;
}

.video-screen .chat-options .add-person-call span.call-text {
	color: #00A389;
}

.video-screen .chat-options .add-person-call span.icon-call {
	background-color: #00A389;
}

.call-user-avatar {
	width: 130px;
    height: 130px;
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.call-user-avatar .avatar-col {
	border-radius: 10px;
}

.call-user-avatar .avatar-col img {
	max-width: 100%;
	border-radius: 10px;
}

.record-time {
    position: absolute;
    top: 20px;
    z-index: 1;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translate(-50%);
}

.record-time span {
	background-color: rgba(250, 250, 250, 0.3);
	display: inline-block;
	border-radius: 130px;
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	width: 140px;
	height: 50px;
	text-align: center;
	line-height: 50px;
	position: relative;
	padding-left: 15px;
}

.record-time span:before {
	content: "";
	position: absolute;
	left: 17px;
	top: 15px;
	background-color: #fff;
	width: 20px;
	height: 20px;
	border-radius: 50px;
}

.record-time span:after {
	content: "";
	position: absolute;
	left: 22px;
	top: 20px;
	background-color: #FF0000;
	width: 10px;
	height: 10px;
	border-radius: 50px;
	animation: blink-animation 1.5s steps(5, start) infinite;
	-webkit-animation: blink-animation 1.5s steps(5, start) infinite;
}

@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}

@-webkit-keyframes blink-animation {
	to {
		visibility: hidden;
	}
}

.volume-col {
	position: absolute;
	left: 20px;
	bottom: 20px;
}

.volume-col .inner-volume-col {
	background-color: rgba(250, 250, 250, 0.3);
	border-radius: 130px;
	width: 60px;
	height: 150px;
	position: relative;
	z-index: 9;
}

.volume-col #volume {
	position: absolute;
	left: 50%;
	top: 15px;
	margin: 0 auto;
	height: 80px;
	width: 5px;
	background: rgba(196, 196, 196, 0.8);
	border-radius: 15px;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
}

.volume-col #volume .ui-slider-range-min {
	height: 5px;
	width: 100%;
	position: absolute;
	bottom: 0;
	background: #fff;
	border: none;
	border-radius: 10px;
	outline: none;
}

.volume-col #volume .ui-slider-handle {
	width: 20px;
	height: 20px;
	border-radius: 20px;
	background: #FFF;
	position: absolute;
	left: 50%;
	margin-top: -8px;
	cursor: pointer;
	outline: none;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
}

.volume-col #player {
	width: auto;
	height: 100px;
	position: relative;
	margin: 0 auto;
	top: 20px;
}

.volume-col #player i {
	position: absolute;
	margin-top: -6px;
	color: #666;
}

.volume-col .material-icons {
	color: #fff;
	margin-left: 0px;
	font-size: 18px;
	margin-top: 22px;
}

.video-call-action {
	position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 1;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}

.video-call-action ul {
	list-style: none;
	padding: 0;
	margin: 0;
	text-align: center;
}

.video-call-action ul li {
	display: inline-block;
	margin: 0 4px;
}

.video-call-action ul li a {
	width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    color: #ffffff;
}
.video-call-action ul.center-action {
	margin: auto;
}
.video-call-action ul li a.call-end {
	background-color: #DE3E44;
}

.video-call-action ul li a.call-mute {
	background-color: #0057FF;
}

.video-call-action ul li a .material-icons {
	font-size: 24px;
}

.video-group-member {
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

.video-group-member ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.video-group-member ul li {
	line-height: 0;
}

.video-group-member .call-user-avatar {
	position: relative;
	left: inherit;
	width: 80px;
	height: 87px;
}

.call-action-group {
	width: 25px;
	height: 25px;
	border-radius: 50px;
	background-color: rgba(106, 106, 106, 0.3);
	display: flex;
	display: -webkit-flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	color: #fff;
	position: absolute;
	right: 8px;
	bottom: 12px;
}

.call-action-group:hover,
.call-action-group:focus {
	color: #fff;
}

.call-action-group .material-icons {
	font-size: 12px;
}

.video-group-member a.call-action-group.call-mute {
	background-color: #0057FF;
}

.status-text {
	position: absolute;
	max-width: 424px;
	width: 100%;
	margin: 0 auto;
	line-height: 1.5;
	left: 50%;
	bottom: 30px;
	text-align: center;
	transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
}

.status-text p {
	color: #fff;
	margin-bottom: 0;
}

/*-----------------
	12. Join Call
-----------------------*/

.page-content {
	width: 100%;
}
.join-call {
	max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
}
.join-video {
	position: relative;
	margin-bottom: 25px;
	height: 350px;
}
.join-video img {
	border-radius: 20px;
	width: 100%;
	height: 350px;
    object-fit: cover;
}
.join-video.video-hide > img, 
.video-wrap.video-hide > video {
    display: none;
}
.video-avatar {
    color: #FFF;
    background: #5D5D5D;
    font-size: 50px;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.video-avatar.active {
	display: -webkit-box;
	display: -ms-flexbox;
    display: flex;
}
.video-avatar h4 {
	font-weight: 700;
	font-size: 24px;
	color: #FFFFFF;
}
.join-content {
	padding-bottom: 25px;
}
.join-content p {
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 25px;
	color: #000;
}
.join-content label {
	font-size: 18px;
	margin-bottom: 0;
	color: #000;
	font-weight: 700;
}
.icon-mic {
	background: #31AA52;
    border-radius: 10px;
    width: 40px;
    height: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
}
.icon-mic i {
    color: #fff;	
}
.btn-join {
	font-weight: 700;
    font-size: 12px;
    padding: 10px 20px;
    min-width: 111px;
    border-radius: 10px;
}
.btn-infos {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.btn-infos .btn-join {
	margin-right: 18px;
}
.btn-infos .icon-mic {
	margin-right: 15px;
}
.btn-infos span {
	font-size: 14px;
	color: #808184;
	font-weight: 400;
}
.call-icons {
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
}
.call-icons .call-items {
    border-radius: 5px;
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-inline-box;
	display: -ms-inline-flexbox;
    display: inline-flex;
	position: absolute;
    bottom: 60px;
	left: 50%;
	transform: translate(-50%, 0);
}
.call-icons .call-items .call-item {
	display: inline-block;
	text-align: center;
	margin-right: 15px;
}
.call-icons .call-items .call-item:last-child {
	margin-right: 0;
}
.call-icons .call-items .call-item a {
    color: #FFF;
    border: 1px solid #FFF;
	background: rgba(255, 255, 255, 0.07);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
    width: 60px;
    height: 60px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-inline-box;
	display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 20px;
	white-space: nowrap;
}
.call-icons .call-items .call-item a:hover {
	opacity: 0.8;
}
.call-icons .call-items .call-item .mute-video.stop,
.call-icons .call-items .call-item .mute-bt.stop {
	background: #DE3E44;
	border: 1px solid #DE3E44;
}
.video-call-action .mute-bt.stop,
.video-call-action .mute-video.stop {
	background: #DE3E44;
	border: 1px solid #DE3E44;
}
.video-call-action.video-grid .mute-bt.stop,
.video-call-action.video-grid .mute-video.stop {
	color: #fff;
}
.set-icon {
	position: absolute;
	top: 30px;
	right: 30px;
	z-index: 1;
}
.vert-icon, 
a.vert-icon {
	width: 40px;
	height: 40px;
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-inline-box;
	display: -ms-inline-flexbox;
    display: inline-flex;
	font-size: 18px;
	color: #000;
}
.voice-sec1 {
	width: 100%;
	height: 100%;
	top: 0;
	position: absolute;
}
.voice-sec {
	position: absolute;
	top: 30px;
	left: 30px;
	z-index: 1;
}
.voice-icon, a.voice-icon {
	width: 40px;
	height: 40px;
	background: #31AA52;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	font-size: 18px;
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-inline-box;
	display: -ms-inline-flexbox;
    display: inline-flex;
	color: #FFF;
}
.voice-icon:hover, a.voice-icon:hover,
.vert-icon:hover, a.vert-icon:hover {
	opacity: 0.8;
}

/*-----------------
	13. Meeting Call
-----------------------*/

.end-call {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
    justify-content: flex-end;
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.action-icons {
	text-align: center;
}

.more-icon {
	position: absolute;
	top: 20px;
	right: 20px;
}
.more-icon a {
    color: #6B7280;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.more-icon a:first-child {
	padding-left: 0;	
}
.more-icon a:hover {
	opacity: 0.8;
}
.meeting .part-name {
	top: 15px;
    left: 15px;
}
.meeting .more-icon {
	top: 15px;
    right: 15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.vertical-view .vertical-sec .part-name h4,
.horizontal-view .join-video.single-user  .part-name h4 {
	font-size: 14px;
	font-weight: 400;
}
.meeting-wrapper, .meeting {
	width: inherit;
	max-width: inherit;
}
.meet-footer {
	position: fixed;
	bottom: 25px;    
	width: 100%;
	max-width: 950px;
	left: 50%;
    transform: translate(-50%, 0);
	z-index: 3;
}
.show-chat-list, .show-invite-list,
.show-chat-list .user-sidebar-right,
.show-invite-list .invite-sidebar-right {
    transition: all 0.8s ease;
}
.user-sidebar-right, .invite-sidebar-right {
    background: #dedede;
	border-radius: 10px;
    width: 410px;
    position: absolute;
    top: 105px;
    bottom: 117px;
    right: 0;
    margin-right: -410px;
    transition: all 0.8s ease;
    overflow: hidden;
    font-size: 13px;
	z-index: 3;
}
.join-contents {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	box-sizing: border-box;
	width: 100%;
	padding: 24px;
}
.ripple {
	width: 114px;
    height: 114px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.multigrid-view .text-box {
    height: 61px;
    width: 61px;
}
.multigrid-view .ripple {
	width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.meeting .join-video {
    margin: 0 5px 10px;	
	width: 100%;
}
.join-video.user-active {
	background: #415B6A;
    border-radius: 20px;
}
.join-video.single-user {
    width: 23%;	
	flex-grow: 1;
    min-height: 120px;
    box-sizing: border-box;
    margin: 0 5px 10px;
    -webkit-box-pack: justify;
	-ms-flex-pack: justify;
    justify-content: space-between;
    text-align: center;
	height: auto;
	background: #415B6A;
    border-radius: 20px;
	padding: 0;
}
.multigrid-view .join-video.single-user {
    width: calc(20% - 10px);
	flex-grow: 0;
}
.join-video.single-user img {
	height: auto;
}
.text-avatar h4 {
	color: #FFF;
}
.text-avatar {
	color: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.text-box {
    height: 86px;
    width: 86px;	
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 36px;
	font-weight: 700;
	display: -webkit-box;
	display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.settings-menu {
	background: #E4EEFF;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
	border-radius: 10px;
	padding: 0 15px;
	margin: 25px 0 25px -16px  !important;
}
.settings-menu li a {
	padding: 12px 0;
	border-bottom: 1px solid #FFFFFF;
	width: auto;
	height: auto;
	background: #E4EEFF;
	font-weight: 600;
	font-size: 12px;
    color: #000;
}
.settings-menu li a:focus, .settings-menu li a:hover {
    color: #000;
    background-color: #E4EEFF;
}
.settings-menu li:last-child a {
	border: 0;
}
.settings-menu .user-img {
	font-size: 18px;
}
.settings-menu .user-img img {
	width: 36px;
    border-radius: 10px;
}
.overlay-icon {
	background: rgba(0, 0, 0, 0.7);
	border-radius: 10px;
	width: 44px;
	display: -webkit-box;
	display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 42px;
    left: 15px;
	opacity: 0;
	transition: opacity .3s linear;
}
.overlay-icon a {
	color: #FFF;
    padding: 3px 0;
	font-size: 14px;
	text-align: center;
}
.overlay-icon a:hover {
	opacity: 0.8;
}
.join-video.single-user:hover::before, .meeting .join-video:hover::before  {
	opacity: 1;
}
.join-video.single-user:hover .overlay-icon, .join-video:hover .overlay-icon {
	opacity: 1;
}
.more-icon.mic-view .mic-on, .more-icon .mic-off {
	display: none;
}
.more-icon.mic-view .mic-off {
	display: inline-flex;
}
.grid-view .join-video.single-user {
    width: 30%;	
}
.join-contents.multigrid-view {
	max-width: 100%;
	padding: 25px 100px 0;
	justify-content: center;
}
.meeting {	
	position: absolute;
	top: 80px;
	bottom: 107px;
	left: 0;
	right: 0;
}
.meeting-wrapper {
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
	position: absolute;
}
.meeting-list {
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	position: relative;
}
.user-active .more-icon .handraise-on {
	display: none;
}
.user-active .more-icon .handraise-on.active {
	display: block;
	animation: mymove 5s;
}
.user-active .more-icon .handraise-on.active .meeting .join-video.user-active::before {
	animation: mymove 5s infinite;
	opacity: 1;
}
@keyframes mymove {
	from {color: #0074E0;}
	to {color: transparent;}
}
.join-contents.vertical-view {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	align-content: center;
	max-width: 1300px;
}
.join-contents.vertical-view .join-video.user-active {
	flex: 1;
	margin-bottom: 5px;;
}
.join-contents.vertical {
	flex-direction: column;
	height: 200px;
}
.join-contents.vertical-view .join-video.single-user {
    width: 97%;
    flex-grow: 1;
}
.meeting .join-contents.vertical .join-video.user-active {
	flex: 1 0 100%;
}
.meeting .join-contents.vertical .join-video.single-user {
	flex: 0;
}
.meeting .join-contents.vertical .join-video {
	width: auto;
}
.ptro-holder-wrapper {
	top: 80px !important;
	position: relative  !important;
	height: auto !important;
	bottom: 300px;
	z-index: 4;
}
.chat-footer .btn:hover {
	background: #0066c5;
	color: #FFF;
}
.person-view {
	width: 40px;
	height: 40px;
	background: #415B6A;
	border-radius: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
	margin-right: 16px;
	color: #FFF;
	font-weight: 700;
	font-size: 14px;
}
.ptro-wrapper {
    border: 1px solid #DEDEDE;
	z-index: 2 !important;
}
.join-contents.show-whiteboard {
	display: none;
}
.show-record {
	display: none;
	position: absolute;
    right: 26px;
}
.user-sidebar-right .message-body {
	top: 70px;
}
.join-contents.single-view {
	padding-top: 0;
	margin-top: 25px;
	position: relative;
}
.join-contents.single-view .single-user {
    position: absolute;
    right: 8px;
    top: 40px;
	width: 130px;
	height: 130px !important;
	min-height: 130px !important;
}
.join-contents.single-view .single-user img {
	height: 130px !important;	
}

.recession-meet-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	width: 100%;
	padding: 24px 24px 0px;
}
.recession-meet-blk .reccession-head h5 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
}
.recession-meet-blk .reccession-head ul li {
    font-size: 14px;
    font-weight: 500;
    color: #6B7280;
    margin-right: 25px;
}
.recession-meet-blk .reccession-head ul li:last-child {
    margin-right: 0px;
}
.recording-time::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 4px;
    background-color: #FF0000;
    width: 13px;
    height: 13px;
    border-radius: 50px;
}
.recording-time::after {
    content: "";
    position: absolute;
    left: 3px;
    top: 7px;
    background-color: #fff;
    width: 7px;
    height: 7px;
    border-radius: 50px;
    animation: blink-animation 1.5s steps(5, start) infinite;
    -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
}
.recession-meet-blk .partispant-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.recession-meet-blk .partispant-blk .btn-primary {
    background: #fff!important;
    border-radius: 6px;
    color: #680A83 !important;
    border: 2px solid #680A83 !important;
    margin-right: 10px;
    font-weight: 600;
    font-size: 15px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.recession-meet-blk .partispant-blk .btn-primary:hover {
	box-shadow: 0 50px 0 0 #680A83 inset;
    color: #fff!important;
}
.recession-meet-blk .partispant-blk .btn-primary:focus {
	color: #680A83 !important;
}
.recording-time {
    position: relative;
    padding-left: 20px;
}
.video-call-action.video-grid {
    position: initial;
	padding: 0px 24px 24px;
	width: 100%;
}
.video-call-action.video-grid ul li a {
    background-color: #fff;
    color: #424242;
}
.video-call-action.video-grid ul li a.call-end {
    background-color: #DE3E44;
	color: #fff;
}
.join-grid {
	background-color: #F8F6FA;
}
.part-name {
    position: absolute;
    bottom: 20px;
	left: 20px;
    text-align: left;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    padding: 7px 15px;
}
.part-name h4 {
	font-weight: 700;
	font-size: 16px;
	color: #FFF; 
	margin-bottom: 0;
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.audio-call-group {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	text-align: center;
	height: 100%;
	position: relative;
}
.audio-call-group .avatar {
	width: 162px;
	height: 162px;
	margin-bottom: 20px;
}
.audio-call-group h6 {
	font-size: 18px;
	color: #fff;
	font-weight: 600;
}
.audio-call-group span {
	font-size: 14px;
	color: #fff;
	font-weight: 500;
	color: #fff;
}

/*-----------------
  14. Status
-----------------------*/

.chat.status-empty-group {
	background-image: none;
	background-color: #F8F6FA;
}
.status-message-box img {
	margin-bottom: 10px;
}
.status-message-box p {
	font-size: 16px;
	font-weight: 500;
	color: #424242;
}
.user-list-item.status-list-item {
	position: relative;
}
.status-list-item .list-inline-item {
	position: absolute;
	top: 10px;
	right: 10px
}
.status-list-item .list-inline-item .no-bg {
	padding: 5px;
	background: transparent;
	border: 0;
	margin-bottom: 0;
	color: #424242;
}
.status-list-item .list-inline-item .no-bg:hover {
	background: transparent;
}
.status-list-item .list-inline-item .contact-menus a {
	border: 0;
	background: transparent;
	margin-bottom: 0;
	line-height: 0;
}
.sidebar .user-list li a.status-active,
.sidebar .user-list li .list-user-blk.status-active {
    background-color: #F8ECFF;
	border-left: 2px solid #680A83;
}
.user-stories-box {
	border: 0;
	background-color: transparent;
	height: 100%;
	flex-direction: inherit;
	-webkit-flex-direction: inherit;
	align-items: center;
	-webkit-align-items: center;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}
.user-stories-box .inner-popup {
	margin: 0 auto;
	width: 100%;
}
.user-stories-box .inner-popup .carousel-indicators {
	top: 25px;
	margin-left: 20px;
	margin-right: 20px;
}
.user-stories-box .inner-popup .carousel-indicators li {
	border: 0;
	background-color: #C2C2C2;
	width: 100%;
	height: 5px;
	border-radius: 50px;
	opacity: 1;
}
.user-stories-box .inner-popup .carousel-indicators li.active {
	position: relative;
}
.user-stories-box .inner-popup .carousel-indicators li.active::after {
	position: absolute;
	content: "";
	background-color: #fff;
	animation: animate 5s linear;
	width: 100%;
	height: 5px;
	left: 0;
	top: 0;
	border-radius: 50px;
}
.user-stories-box .inner-popup .carousel-inner {
	height: calc(100vh - 110px);
	border-radius: 10px;
}
.user-stories-box .inner-popup .carousel-inner .carousel-item img {
	width: 100%;
	border-radius: 10px;
}
.user-stories-box .inner-popup .status-user-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
	position: absolute;
	z-index: 9;
	left: 20px;
	right: 20px;
	top: 50px;
}
.user-stories-box .inner-popup .status-user-blk .user-details {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.user-stories-box .inner-popup .status-user-blk .user-details .avatar {
	width: 46px;
	height: 46px;
	border-radius: 50px;
	margin-right: 10px;
}
.user-stories-box .inner-popup .status-user-blk .user-details .user-online h5 {
	font-size: 15px;
	font-weight: 500;
	color: #fff;
	margin-bottom: 5px;
}
.user-stories-box .inner-popup .status-user-blk .user-details .user-online span {
	font-size: 14px;
	font-weight: 400;
	color: #EDEDED;
}
.user-stories-box .inner-popup .status-user-blk .chat-user-time .chats-dot a {
	color: #fff;
}

.carousel-indicators [data-bs-target] {
	border: 0;
	background-color: red;
	width: 145px;
	height: 5px;
	border-radius: 50px;
}
@keyframes animate {
    from {
        width: 0;
    }
    to{
        width: 100%;
    }
}
.user-status-group {
	width: 100%;
	padding: 60px;
}
.user-stories-box .inner-popup .carousel-indicators li {
	list-style: none;
}
.status-voice-group ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.status-voice-group ul li+li {
	margin-left: 10px;
}
.status-voice-group ul li:not(.no-bg) i {
	font-size: 20px;
}
.status-voice-group ul li:not(.no-bg) a {
	color: #fff;
}
.status-voice-group ul li .dropdown-item a:hover {
	color: #0A0A0A;
}
.view-status-list {
	position: absolute;
	bottom: 20px;
	left: 50%;
	z-index: 9;
}
.view-status-list a {
	color: #fff;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.view-status-list a i {
	margin-right: 6px;
}
.dropdown-menu.status-drop-list a img,
.dropdown-menu.status-drop-list a i {
	margin-right: 10px;
	color: #0A0A0A;
}
.chat.status-chat-footer .chat-footer {
    position: inherit;
}
.chat.status-chat-footer .chat-footer form {
    z-index: 999;
	background: transparent;
}
.chat.status-chat-footer .chat_form {
	box-shadow: none;
}
.emoj-group ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.emoj-group ul li+li {
	margin-left: 10px;
}
.emoj-group ul li a {
	width: 34px;
	height: 34px;
	background: #fff;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.emoj-group ul li a:hover {
	background-color: #EDEDED;
    border-color: #EDEDED;
}
.emoj-group ul li a i {
	color: #424242;
}
.message-content {
	position: relative;
}
.message-content:hover .emoj-group {
	opacity: 1;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
	
}
.emoj-group {
	position: absolute;
	top: 50%;
	right: -40px;
	opacity: 0;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
	-webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.rig-emoji-group {
	/* position: absolute;
	right: 210px; */
	position: absolute;
    right: unset;
    left: -40px;
}
.wrap-emoji-group {
	position: absolute;
	right: 290px;
	z-index: 9;
}
.status-message-box h6 {
	font-size: 20px;
	color: #0A0A0A;
	margin-bottom: 24px;
}
.status-message-box .avatar {
	width: 92px;
	height: 92px;
	margin-bottom: 24px;
}
.status-message-box .view-link a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.status-message-box .view-link a:hover {
	color: #650681;
}
.status-message-box .view-link p {
	font-size: 16px;
	font-weight: 400;
	color: #424242;
}
.chat-hover {
	position: absolute;
    bottom: 30px;
    right: 10px;
	display: none;
}
.chat-user-list {
	position: relative;
}
.chat-user-list:hover .chat-hover {
	display: block;
}
.chat-user-list:hover .chat-pin,
.chat-hover:hover .chat-pin  {
	margin-right: 15px;
}
.chat-hover span {
	cursor: pointer;
}

/*-----------------
  15. Call
-----------------------*/

.chat.call-log-group {
	background-image: none;
	background-color: #F8F6FA;
}
.chat.call-log-group .chat-header {
	border: 0;
	background: transparent;
}
.chat.call-log-group .chat-body {
	background-image: none;
}
.missed-call-chat {
	color: #DE3E44;
}
.outgoing-call {
	color: #02A2B9;
}
.incoming-call {
	color: #1BA345;
}
.phone-income i {
	color: #1BA345;
	margin-right: 3px;
}
.phone-missed i {
	color: #DE3E44;
	margin-right: 3px;
}
.phone-outgoing i {
	color: #02A2B9;
	margin-right: 3px;
}
.dark-mode-toggle {
	display: none;
}
.dark-mode-toggle.active {
	display: block;
}
/*-----------------
  16. Modal
-----------------------*/

.modal-header {
	padding: 24px;
	color: #484848;
	border-radius: 10px 10px 0 0;
	border-bottom: 0;
	display: flex;
    align-items: center;
    justify-content: space-between;
}
.setting-wall {
	padding: 40px;
}
.set-wall-header {
	padding: 0;
	margin-bottom: 32px;
}
.wall-img {
	margin-bottom: 10px;
	position: relative;
}
.active.wall-img img {
	border: 3px solid #650681;
}
.active .theme-image-content {
    display: block;
}
.theme-image-content {
    display: none;
    position: absolute;
    top: 40px;
    right: 55px;
}
.theme-image-content i {
    font-size: 18px;
    z-index: 99;
    position: relative;
    color: #fff;
    border-radius: 50px;
}
.wall-set-body {
	margin-top: 22px;
}
.wall-set-body .wall-btn {
	justify-content: end;
}
.wallper-card-wrapper {
	padding: 0px 10px 10px 10px;
}
.privacy-detail-blk span a {
    font-size: 16px;
}
.wall-set-body .wall-btn .btn-primary {
	margin: 0 0 0 10px;
}
.wall-img img {
	border-radius: 10px;
	cursor: pointer;
}
.setting-wall .wall-set-body {
	padding: 0;
}
.modal-content {
	border-radius: 23px;;
}
.modal-body {
	border-radius: 10px;
	padding: 0 24px 24px;
}

.modal-body .form-group label {
	font-weight: 500;
	color: #0A0A0A;
}

.modal-header .modal-title {
	font-size: 20px;
	font-weight: 600;
	color: #0A0A0A;
}

.modal-header .modal-title i {
	font-size: 20px;
	color: #420BA1;
	margin-right: 5px;
}

.modal-header .modal-title span.material-icons {
	font-size: 25px;
	color: #420BA1;
	margin-right: 5px;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.modal-header .close:hover {
	opacity: .75;
}
.modal-header .close {
	color: #DE3E44;
	border: 0;
	background-color: #FFE8E9;
	text-shadow: none;
	opacity: 1;
	width: 28px;
	height: 28px;
	border-radius: 25px;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	-webkit-justify-content: center;
	align-items: center;
	-webkit-align-items: center;
	padding: 0;
}
.modal.mute-notify .modal-dialog {
    width: 383px;
}
.newgroup_create {
    padding: 10px 20px;
    line-height: 1.6;
    border-radius: 0.375rem;
    background-color: #680A83;
    box-shadow: none;
    border-color: #680A83;
    margin: 0px auto 15px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
}
.btn-primary {
	color: #fff !important;
	background: #680A83 !important;
	border-color: #680A83!important;
	border: 2px solid #680A83!important;
}
.btn-primary:hover {
	box-shadow: 0 50px 0 0 #fff inset;
	color: #680A83 !important;
}
.btn-secondary {
	color: #424242;
	background: #fff!important;
	border-color: #424242!important;
	border: 2px solid #424242!important;
}
.btn-secondary:hover {
	box-shadow: 0 50px 0 0 #424242 inset;
	color: #fff;
	background: #424242 !important;
}
.success-group-wrap-list {
	padding: 40px;
}
.success-group-wrap-list .modal-body {
	padding: 0;
	text-align: center;
}
.success-group-wrap-list .modal-body .success-header {
	margin-bottom: 24px;
}
.success-group-wrap-list .modal-body .success-header h4{
    font-size: 20px;
	font-weight: 600;
	color: #333;
	margin-bottom: 7px;
}
.success-group-wrap-list .modal-body .success-header p{
    font-size: 16px;
	font-weight: 400;
	color: #424242;
	margin-bottom: 0px;
}
.success-group-wrap-list .modal-header {
	padding: 0;
}
.mute-chat-btn .btn {
	padding: 11px 15px;
	font-size: 15px;
	max-height: 40px;
	border-radius: 10px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}
.mute-chat-btn {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: flex-end;
}
.mute-chat-btn i {
	font-size: 18px;
}
.mute-chat-btn .btn-secondary {
	margin-right: 10px;
}
.notify-check {
	margin-bottom: 24px;
}
.notify-check .form-check .custom-check {
	margin: 20px 0 0;
	padding: 0 0 0 28px;
	color: #8A909A;
	display: inline-block;
	position: relative;
	font-size: 14px;
	cursor: pointer;
	font-weight: 400;
	user-select: none;
}
.notify-check .form-check {
	margin-bottom: 15px;
}
.notify-check .form-check:last-child {
	margin-bottom: 0px;
}
.notify-check .form-check .custom-check input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.notify-check .form-check .custom-check input:checked ~ .checkmark {
	background-color: #680A83;
	border: 1px solid #680A83;
}
.notify-check .form-check .custom-check input:checked ~ .checkmark::after {
	display: block;
}
.notify-check .form-check .custom-check .remember-me {
	color: #424242;
	font-size: 16px;
	font-weight: 400;
}
.notify-check .form-check .custom-check .checkmark {
	position: absolute;
	top: 3px;
	left: 0;
	font-size: 10px;
	width: 18px;
	height: 18px;
	border: 1px solid #E0E0E0;
	background-color: #FFF;
	border-radius: 4px;
	transition: all 0.3s ease-in-out;
}
.notify-check .form-check .custom-check .checkmark::after {
	content: "\f00c";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	position: absolute;
	top: -1px;
	left: 3px;
	display: none;
	color: #FFF;
	font-size: 12px;
}
.block-user-blk img {
	margin-bottom: 15px;
}
.block-user-blk p {
	color: #424242;
}
.change-color {
	color: #DE3E44;
	font-size: 40px;
	margin-bottom: 15px;
}
.space-chat {
	margin-bottom: 14px;
}

/*-----------------
  17. Group
-----------------------*/

.group-description {
	padding: 0px 24px;
}
.group-description .description-sub {
	border-bottom: 1px solid #EDEDED;
	padding-bottom: 15px;
	margin-bottom: 15px;
}
.group-description h5 {
	font-size: 15px;
	font-weight: 500;
	color: #0A0A0A;
	margin-bottom: 10px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.group-description h5 a:hover {
	color: #650681 !important;
}
.group-description p { 
	color: #424242;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 10px;
}
.group-description p.description-date { 
	color: #424242;
	font-size: 12px;
	font-weight: 400;
	margin-bottom: 0px;
}
.encrypted-message p {
	font-size: 12px;
	color: #424242;
	font-weight: 400;
	margin-bottom: 0;
}
.group-view-all {
	margin-top: 10px;
}
.group-view-all a {
	color: #680A83;
	font-size: 14px;
	font-weight: 500;
}
.group-view-all a:hover {
	color: #0A0A0A;
}
.exit-bg-message {
	background: #FFE8E9;
}
.exit-bg-message i {
	color: #DE3E44;
}
.disapper-message {
	background: #E7FAF2;
}
.disapper-message i {
	color: #1BA345;
}
.group-setting-msg {
	background: #F8ECFF;
}
.group-setting-msg i {
	color: #680A83;
}
.group-setting .stared-group span {
	width: 50px;
	height: 50px;
}
.edit-set-details p {
	font-size: 14px;
	font-weight: 400;
	color: #424242;
	margin-bottom: 0;
}
.group-send-msg {
	background: #FEF6DC;
}
.parcipant-check p a {
	color: #680A83;
}
.select-group-chat .dropdown-toggle::after {
	display: none;
}
.select-group-chat .dropdown-toggle i {
	margin-left: 15px;
	font-size: 20px;
}
.select-group-chat .dropdown-toggle:hover {
	color: #0A0A0A;
}
.select-group-chat .dropdown-toggle {
	font-weight: 600;
	color: #0A0A0A;
	font-size: 18px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.select-group-chat .dropdown-menu .dropdown-item {
	font-size: 14px;
	font-weight: 400;
	color: #0A0A0A;
}
.select-group-chat .dropdown-menu li:first-child .dropdown-item:hover {
	border-radius: 5px 5px 0 0;
}
.select-group-chat .dropdown-menu li:last-child .dropdown-item:hover {
	border-radius: 0 0 5px 5px;
}
.dropzone {
	background: #fff;
	border: 1px dashed #C2C2C2;
	border-radius: 5px;
	padding: 10px;
	min-height: 70px;
	text-align: center;
}
.dropzone.dz-clickable .dz-message {
	display: none;
}
.dropzone p {
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 500;
	color: #0A0A0A;
}
.dropzone span {
	color: #424242;
	font-size: 14px;
	font-weight: 400;
}
.uplaod-image img {
	border-radius: 20px;
}
.upload-img-file .modal-body {
	padding: 0;
}
.upload-img-file .modal-content {
	position: relative;
}
.upload-img-file .modal-header {
	position: absolute;
	top: 0;
	z-index: 9;
	left:0;
	right: 0;
}
.upload-image-list {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.upload-image-list li+li {
	margin-left: 10px;
}
.upload-img-file .modal-header .close {
	background-color: transparent;
	color: #fff;
}
.check-star i {
	color: #FEC001;
}
.emoj-group-list,
.emoj-group-list-foot {
	display: none;
	position: absolute;
	top: -50px;
	left: -90px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.emoj-group-list ul,
.emoj-group-list-foot ul {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #fff;
	border-radius: 50px;
	padding: 5px;
}
.emoj-group-list ul li a,
.emoj-group-list-foot ul li a {
	padding: 0;
}
.emoj-group-list ul li.add-emoj a,
.emoj-group-list-foot ul li.add-emoj a {
	background: #EDEDED;
}
.emoj-group-list ul li.add-emoj a i,
.emoj-group-list-foot ul li.add-emoj a i {
	font-size: 20px;
	color: #424242;
}
.chat .slimscroll {
	height: calc(100vh - 65px) !important;
}
.settings-option {
	display: none;
	text-align: right;
}
.settings-option a {
	font-size: 15px;
	font-weight: 500;
	color: #680A83;
}
.empty-dark-img {
	display: none;
}
.bootstrap-datetimepicker-widget span {
	position: relative;
	right: 0;
}
.bootstrap-datetimepicker-widget table td.active, 
.bootstrap-datetimepicker-widget table td.active:hover {
	background: #680A83;
}
.hash-participant {
	display: none;
}
.show-participant {
	display: block;
}
.hash-group {
	display: none;
}
.show-group-add {
	display: block;
}

/*-----------------
  18. Dark Mode
-----------------------*/

.darkmode {
	background: #161819;
}
body.darkmode {
	color: #E0E0E0;
}
.darkmode .sidebar-menu {
	background: #161819;
	border-left: 1px solid rgb(60 62 72);
}
.darkmode .main-wrapper .content .sidebar-group .sidebar {
	border-left: 1px solid rgb(60 62 72);
}
.darkmode .chat-voice-group li+li {
	color: #fff;
}
.darkmode .dark-mode-toggle {
	background: transparent;
	border: 0;
}
.darkmode .dark-mode-toggle i {
	color: #9E9E9E;
}
.darkmode .chat-menus ul li a i {
    color: #9E9E9E;
}
.darkmode .chat-menus ul li a.active {
	background: #5a078b;
}
.darkmode .chat-menus ul li a.active i {
	color: #fff;
}
.darkmode .chat-menus ul li a:hover {
	background: #5a078b;
}
.darkmode .chat-menus ul li a:hover i {
	color: #fff;
}
.darkmode .main-wrapper .content .sidebar-group .sidebar {
	background: #1C1D1E;
}
.darkmode .select-group-chat .dropdown-toggle {
    color: #fff;
}
.darkmode .select-group-chat .dropdown-toggle i {
    color: #9E9E9E;
}
.darkmode .add-section ul li a {
	color: #9E9E9E;
}
.darkmode .all-chats .add-section ul li:hover {
	background: #343637;
}
.darkmode .dropdown-menu {
    background: #161819;
    border: 1px solid #4E50724D;
    box-sizing: border-box;
    box-shadow: 0px 4px 3px 0px #0000001A;
}
.darkmode .add-section ul li .dropdown-menu a.dropdown-item,
.darkmode .dropdown-menu .dropdown-item i {
	color: #E0E0E0;
}
.darkmode .dropdown-menu .dropdown-item:hover, 
.darkmode .dropdown-menu .dropdown-item:focus, 
.darkmode .dropdown-menu .dropdown-item:active {
    background-color: #343637;
}
.darkmode .fav-title h6 {
	color: #fff;
}
.darkmode .user-list li a {
	background: #252728;
}
.darkmode .sidebar .user-list li a,
.darkmode .sidebar .user-list li .list-user-blk {
	border: 1px solid rgba(78, 80, 114, 0.3)
}
.darkmode .sidebar .user-list li .users-list-body h5 {
	color: #E0E0E0;
}
.darkmode .sidebar .user-list li .users-list-body p,
.darkmode .sidebar .user-list li .users-list-body .last-chat-time .text-muted,
.darkmode .chat-pin i {
	color: #9E9E9E !important;
}
.darkmode .sidebar .user-list li a:hover,
.darkmode .sidebar .user-list li .list-user-blk:hover {
	background: rgba(78, 80, 114, 0.3);
}
.darkmode .dropdown-menu .dropdown-item,
.darkmode .dropdown-menu .dropdown-item span {
	color: #E0E0E0;
}
.darkmode .left-chat-title {
	border-bottom: 1px solid rgba(78, 80, 114, 0.3);
}
.darkmode .sidebar-menu,
.darkmode .main-wrapper .content .sidebar-group .sidebar {
	border-right: 1px solid transparent;
}
.darkmode .chat {
    background-image: url(../../../public/assets/img/chat-bg-dark.jpg);
}
.darkmode .chat.chat-contact,
.darkmode .chat.call-log-group,
.darkmode .chat.status-empty-group {
	background-image: none;
	background: #161819;
}
.darkmode .select-message-box h4 {
	color: #E0E0E0;
}
.darkmode .select-message-box p,
.darkmode .review-files p {
	color: #9E9E9E;
}
.darkmode .empty-chat-img {
	display: none;
}
.darkmode .empty-dark-img {
	display: block;
}
.darkmode .chat .chat-header {
	background: #161819;
	border-bottom: 1px solid transparent;
}
.darkmode .modal-content {
	background: #252728;
}
.darkmode .chat .chat-header .user-details h5,
.darkmode .chat .chat-body .messages .chat-profile-name h6,
.darkmode .chat .chat-body .messages .chats .message-content.award-link a,
.darkmode .chat .chat-body .messages .file-download .file-details span.file-name,
.darkmode .pass-login label,
.darkmode .pass-login h4,
.darkmode .block-user-name h6,
.darkmode .group-type-radio,
.darkmode .notify-check .form-check .custom-check .remember-me,
.darkmode .sidebar-body .profile-name,
.darkmode .chat-member-details .member-details ul li span,
.darkmode .theme-content h6 {
	color: #E0E0E0;
}
.darkmode .last-seen,
.darkmode .chat .chat-body .messages .chat-profile-name h6 span,
.darkmode .chat-action-col > a,
.darkmode .block-user-name span,
.darkmode .block-user-blk p,
.darkmode .last-seen-profile span,
.darkmode .theme-content p {
	color: #9E9E9E;
}
.darkmode .chat-member-details .member-details ul li h5,
.darkmode .right-sidebar-head h5 {
	color: #fff;
}
.darkmode .chat-member-details .member-details ul li h6 {
	color: #757575;
}
.darkmode .chat-options.chat-option-profile .list-inline,
.darkmode .chat-member-details .member-details ul,
.darkmode .setting-profile-card {
	border-bottom: 1px solid rgb(78, 80, 114, 0.3);
}
.darkmode .chat .chat-header .chat-options ul > li > a,
.darkmode .chat-options.chat-option-profile .list-inline-item a {
	color: #9E9E9E;
	background: rgba(52, 54, 55, 1);
	border-color: rgba(52, 54, 55, 1);
}
.darkmode .chat .chat-header .chat-options ul > li > a:not(.no-bg):hover {
    border-color: rgba(52, 54, 55, 1);
	background: rgba(52, 54, 55, 1);
}
.darkmode .btn.btn-outline-light:hover {
    background: rgba(52, 54, 55, 1);
}
.darkmode .chat .chat-body .messages .chats .message-content {
	box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.03);
	background: #252728;
	color: #9E9E9E;
}
.darkmode .emoj-group ul li a {
	background: rgba(52, 54, 55, 1);
}
.darkmode .emoj-group ul li a i,
.darkmode .chat .chat-body .messages .file-download .file-details ul li {
	color: #9E9E9E;
}
.darkmode .emoj-group-list ul, 
.darkmode .emoj-group-list-foot ul,
.darkmode .media-doc-blk span {
	background: rgba(52, 54, 55, 1);
}
.darkmode .chat-date {
	background: #313536;
	color: #E0E0E0;
	border: 1px solid #313536;
}
.darkmode .chat-line:before {
	background: rgba(78, 80, 114, 0.3);
}
.darkmode .chat .chat-body .messages .file-download {
	background: #1C1D1E;
}
.darkmode .chat .chat-body .messages .file-download .file-type,
.darkmode .chat .chat-footer form .smile-foot .action-circle {
	background: #252728;
}
.darkmode .chat .chat-footer form .smile-foot .action-circle i,
.darkmode .close-btn-chat, .darkmode .user-close-btn-chat,
.darkmode .form-control-feedback i,
.darkmode .fav-title a:hover,
.darkmode .form-control-feedback {
	color: #9E9E9E;
}
.darkmode .chat_form {
	background: #343637;
	border: 1px solid rgba(78, 80, 114, 0.3);
	box-shadow: none;
	color: #9E9E9E;
}
.darkmode .chat .chat-footer form .form-control::-webkit-input-placeholder {
	color: rgba(158, 158, 158, 1);
	opacity: 1;
}

.darkmode .chat .chat-footer form .form-control::-moz-placeholder {
	color: rgba(158, 158, 158, 1);
	opacity: 1;
}

.darkmode .chat .chat-footer form .form-control:-ms-input-placeholder {
	color: rgba(158, 158, 158, 1);
	opacity: 1;
}

.darkmode .chat .chat-footer form .form-control:-moz-placeholder {
	color: rgba(158, 158, 158, 1);
	opacity: 1;
}
.darkmode .like-chat-grp ul li.comment-chat a {
	background: rgba(255, 0, 10, 0.1);
	border: 1px solid #C8102E;
	color: #C8102E;
}
.darkmode .like-chat-grp ul li.like-chat a {
	color: #FEC001;
	background: rgba(254, 192, 1, 0.1);
	border: 1px solid #FEC001;
}
.darkmode .chat-search input[type="text"], 
.darkmode .user-chat-search input[type="text"],
.darkmode .form-control {
	background: #343637;
	border: 1px solid rgba(78, 80, 114, 0.3);
	box-shadow: none;
	color: #9E9E9E;
}
.darkmode .chat-search input[type="text"]:hover, .darkmode .user-chat-search input[type="text"]:hover, 
.darkmode .chat-search input[type="text"]:focus, .darkmode .user-chat-search input[type="text"]:focus, 
.darkmode .chat-search input[type="text"]:focus-visible, .darkmode .user-chat-search input[type="text"]:focus-visible {
    border-color: rgba(78, 80, 114, 0.3);
}
.darkmode .user-chat-search input[type="text"]::-webkit-input-placeholder,
.darkmode .form-control::-webkit-input-placeholder {
	color: rgba(158, 158, 158, 1);
	opacity: 1;
}
.darkmode .modal-header .modal-title,
.darkmode .user-block-group h5,
.darkmode .setting-title-head h4 {
	color: #fff;
}
.darkmode .btn-secondary {
	color: #C8102E;
    background: rgba(255, 0, 10, 0.1)!important;
    border-color: #C8102E!important;
    border: 2px solid #C8102E!important;
}
.darkmode .btn-secondary:hover {
    box-shadow: 0 50px 0 0 #C8102E inset;
	background: #C8102E!important;
    color: #fff;
}
.darkmode .modal-header .close {
	background: rgba(255, 0, 10, 0.1);
	color: #C8102E;
}
.darkmode .notify-check .form-check .custom-check .checkmark,
.darkmode .checkmark {
	background: #343637;
	border: 1px solid rgba(78, 80, 114, 0.3)
}
.darkmode .right-sidebar .right-sidebar-wrap {
	background: #1C1D1E;
	border-left: 1px solid rgba(78, 80, 114, 0.3)
}
.darkmode .disapper-message {
    background: rgba(52, 168, 83, 0.1);
}
.darkmode .group-setting-msg {
    background: rgba(104, 10, 131, 0.2);
}
.darkmode .exit-bg-message {
    background: rgba(255, 0, 10, 0.1);
}
.darkmode .right-sidebar-head.share-media .about-media-tabs .nav-tabs {
	background: #252728;
	color: #9E9E9E;
}
.darkmode .right-sidebar-head.share-media .about-media-tabs .nav .nav-item {
	color: #9E9E9E;
}
.darkmode .right-sidebar-head.share-media .about-media-tabs .nav .nav-item.active {
	background: rgba(104, 10, 131, 0.2);
	color: #C00CFF;
}
.darkmode .group-comman-theme ul li {
	border: 1px solid rgba(78, 80, 114, 0.3);
	background: #252728;
}
.darkmode .stared-group h6,
.darkmode .document-detail h6,
.darkmode .media-download a,
.darkmode .media-link-detail h6 a,
.darkmode .fav-title a,
.darkmode .message-star h6,
.darkmode .message-info-box h6,
.darkmode .right-sidebar-profile .profile-name span i,
.darkmode .group-description h5,
.darkmode .group-description h5 span a,
.darkmode .participants-list-group h6,
.darkmode .participants-list .users-list-body h5,
.darkmode .personal-info h5,
.darkmode .modal-body .form-group label,
.darkmode .info-title,
.darkmode .info-text {
	color: #E0E0E0;
}
.darkmode .count-group i,
.darkmode .document-detail ul li,
.darkmode .media-link-detail span a,
.darkmode .message-info-box span,
.darkmode .group-description p,
.darkmode .group-description p.description-date,
.darkmode .encrypted-message p,
.darkmode .participants-list .users-list-body p,
.darkmode .edit-set-details p,
.darkmode .social-profile-blk ul li h6 {
    color: #9E9E9E;
}
.darkmode .media-link-detail a:hover,
.darkmode .media-link-detail h6 a:hover,
.darkmode .fav-title a:hover {
    color: #680A83;
}
.darkmode .count-group span {
	background: rgba(104, 10, 131, 0.2);
	color: #C00CFF;
}
.darkmode .star-message {
    background: rgba(254, 192, 1, 0.1);
}
.darkmode .mute-message {
    background: rgba(2, 162, 185, 0.15);
}
.darkmode .block-message {
    background: rgba(255, 0, 10, 0.1);
}
.darkmode .report-message {
    background: rgba(104, 10, 131, 0.2);
}
.darkmode .delete-message {
    background: rgba(255, 0, 10, 0.1);
}
.darkmode .message-info-box .check-active {
    color: #53BDEB;
}
.darkmode .group_header {
	border: 2px solid rgba(158, 158, 158, 1);
}
.darkmode .participants-list ul li {
	border: 1px solid rgba(78, 80, 114, 0.3);
	background: #252728;
}
.darkmode .participants-list .admin-profiles {
	background: #313536;
	color: #9E9E9E;
}
.darkmode .card {
	background: #252728;
}
.darkmode .personal-info ul li,
.darkmode .user-chat-search-btn,
.darkmode .account-setting a i,
.darkmode .settings-control ul li a,
.darkmode  .social-profile-blk ul li h6,
.darkmode .settings-switch ul li,
.darkmode .privacy-content p,
.darkmode .connectet-content p,
.darkmode .logout-group p,
.darkmode .video-call-chat .chat-title span,
.darkmode .dropzone span,
.darkmode .status-message-box p,
.darkmode .status-list-item .list-inline-item .no-bg {
	color: #9E9E9E;
}
.darkmode .privacy-content h6,
.darkmode .connectet-content h6,
.darkmode  .video-call-chat .chat-title h4 {
	color: #E0E0E0;
}
.darkmode .account-setting h5,
.darkmode .login-security h6 {
	color: #fff;
}
.darkmode .settings-card {
	background: #1C1D1E;
}
.darkmode  .social-profile-blk ul li a {
	border: 1px solid rgba(78, 80, 114, 0.3);
}
.darkmode .settings-switch ul li {
	background: #343637;
}
.darkmode .select2-container--default .select2-selection--single {
	background: #313536;
	border: 1px solid rgba(78, 80, 114, 0.3);
}
.darkmode .select2-container--default .select2-selection--single .select2-selection__rendered,
.darkmode .select2-container--default .select2-selection--single .select2-selection__arrow b {
	color: #9E9E9E;
	border-color: #9E9E9E;
}
.darkmode .connectet-img {
	border: 2px solid rgba(78, 80, 114, 0.3);
	background: rgba(158, 158, 158, 0.1);
}
.darkmode .device-remove a {
	background: transparent;
	border: 1px solid #C8102E;
	color:  #C8102E;
}
.darkmode .device-remove a:hover {
	background: #C8102E;
	color: #9E9E9E;
}
.darkmode .right-sidebar .contact-close_call .close_profile, 
.darkmode .status-modal .custom-status-close {
	background: #313536;
	color: #9E9E9E;
}
.darkmode .chat.video-call-chat .chat-body .messages .chats.chats-right .message-content {
	background: #252728;
}
.darkmode .select2-container--default .select2-results>.select2-results__options {
	background: #1C1D1E;
}
.darkmode .select2-dropdown {
	border: 1px solid rgba(78, 80, 114, 0.3);
	border-top: 0;
	background-color: transparent;
}
.darkmode .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #4a4747;
}
.darkmode .status-list-item .list-inline-item .no-bg,
.darkmode .status-list-item .list-inline-item .no-bg:hover,
.darkmode .star-drop .btn-outline-light,
.darkmode .star-drop .btn-outline-light:hover {
	border: 0;
	background: transparent;
}
.darkmode .status-list-item .list-inline-item .contact-menus a {
	border: 0;
}
.darkmode .sidebar .user-list li a.status-active,
.darkmode .sidebar .user-list li .list-user-blk.status-active {
	background: rgba(78, 80, 114, 0.3);
}
.darkmode a,
.darkmode .dropzone p,
.darkmode .star-drop .btn-outline-light,
.darkmode .emoj-group-list ul li.add-emoj a i, 
.darkmode .emoj-group-list-foot ul li.add-emoj a i {
	color: #E0E0E0;
}
.darkmode .dropzone {
	border: 1px dashed rgba(78, 80, 114, 0.3);
	background: #343637;
}
.darkmode .dark-option a.active i {
    color: #fff;
}
.darkmode .emoj-group-list ul li.add-emoj a, 
.darkmode .emoj-group-list-foot ul li.add-emoj a {
    background: rgb(28 29 30);
}
.darkmode .tooltip > .tooltip-inner {
    text-align: left;
    max-width: 500px;
    --bs-tooltip-bg: #7f149e
}
.darkmode .tooltip {
    --bs-tooltip-bg: #7f149e
}
.darkmode .tooltip .arrow {
	display: none;
	background-color: #7f149e
}
.darkmode .tooltip .tooltip-inner {
	background-color: #7f149e
}
.darkmode .chats.forward-chat-msg {
    background: #1c1d1e;
}
.darkmode .replay-msg {
    background: #17181a;
	color: #fff;
}
.darkmode .chat .chat-footer form {
    background-color: #17181a;
}
.darkmode .replay-forms {
    border: 1px solid #32333e;
    background: #252728;
}

.replay-msg {
	background: #fff;
	padding: 10px;
	border-left: 3px solid #680A83;
	border-radius: 0 15px 15px 0;
}
.chat .chat-body .messages .chat-profile-name h6 .inactive-check {
	color: #9E9E9E;
}
.chat .chat-body .messages .chat-profile-name h6 .active-check {
	color: #53BDEB;
}
.forward-msg {
	color: #9E9E9E;
	margin-bottom: 5px;
}
.edit-msg-blk form {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.edit-msg-blk form .msg-input {
	width: 100%;
}
.edit-msg-blk .send-btn {
	width: 40px;
	height: 40px;
	background: #680A83;
	border-radius: 10px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.edit-msg-blk .send-btn:hover {
	background-color: #fff;
    border-color: #680A83;
	color: #680A83;
}
.edit-msg-blk .send-btn:hover i {
	color: #680A83;
}
.edit-msg-blk .send-btn i {
	color: #fff;
}
.social-profile-blk .social-medias {
	position: absolute;
	right: 10px;
	top: 10px;
}
.social-profile-blk .pass-login {
	position: relative;
}
.voice_body {
    padding: 50px 0;
}
.call-box .call-wrapper {
    height: auto;
    text-align: center;
}
.call-user h4 {
    margin-bottom: 20px;
}
.call-user h4 span {
    color: #5A078B;
    font-weight: 600;
}
.call-items {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
    margin: 0 10px;
}
.call-box .call-wrapper .call-items .call-item.call-end {
    background: #F55067;
}
.call-box .call-wrapper .call-items .call-item.call-end:hover {
    background: #ed0828;
}
.call-box .call-wrapper .call-items .call-item.call-start {
	background: #5A078B;
}
.call-box .call-wrapper .call-items .call-item.call-start, 
.call-box .call-wrapper .call-items .call-item.call-end {
    color: #ffffff;
    line-height: 0;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    font-size: 18px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.call-box .call-wrapper .call-avatar {
    border-radius: 100%;
    position: relative;
    border: 7px solid rgba(66, 11, 161, 0.1);
    height: 90px;
    width: 90px;
    margin-bottom: 20px;
    cursor: pointer;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
	margin: 0 10px;
	-webkit-animation: pulse-secondary 2s infinite;
	animation: pulse-secondary 2s infinite;
	-webkit-box-shadow: 0 0 0 rgb(90 7 139 / 69%);
	box-shadow: 0 0 0 rgb(90 7 139 / 69%);
}
@-webkit-keyframes pulse-secondary {
	0% {
		-webkit-box-shadow: 0 0 0 rgb(90 7 139 / 69%);
		box-shadow: 0 0 0 rgb(90 7 139 / 69%);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(16, 229, 153, 0);
		box-shadow: 0 0 0 10px rgba(16, 229, 153, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(16, 229, 153, 0);
		box-shadow: 0 0 0 0 rgba(16, 229, 153, 0);
	}
}
@keyframes pulse-secondary {
	0% {
		-webkit-box-shadow: 0 0 0 rgb(90 7 139 / 69%);
		box-shadow: 0 0 0 rgb(90 7 139 / 69%);
	}
	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(16, 229, 153, 0);
		box-shadow: 0 0 0 10px rgba(16, 229, 153, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(16, 229, 153, 0);
		box-shadow: 0 0 0 0 rgba(16, 229, 153, 0);
	}
}
.notify-check.parti-notify-radio .group-type-radio {
	margin-right: 0;
}
.notify-check.parti-notify-radio .checkmark {
	top: 6px;
}
.replay-forms {
	width: 100%;
    border: 1px solid #E0E0E0;
    background: #fff;
    border-radius: 10px;
}
.replay-forms .form-control {
	border: 0;
	background: transparent;
    box-shadow: none;
}
.uplaod-image {
	height: 400px;
}
.uplaod-image img {
	width: 100%;
	height: 100%;
}
.uplaod-image .chat {
	background: transparent;
	height: auto;
}
.sroll-side-view {
	height: 400px;
	overflow-y: scroll;
    overflow-x: hidden;
}
.sroll-side-view::-webkit-scrollbar {
	width: 5px;
	height: 10px;
	border-radius: 50px !important;
}
.sroll-side-view::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 50px !important;
}
.sroll-side-view::-webkit-scrollbar-thumb {
	background: #680a83;
	border-radius: 50px !important;
}
.sroll-side-view::-webkit-scrollbar-thumb:hover {
	background: #680a83;
}
.multi-load {
	border: 2px solid #c8ccc8;
    box-shadow: inset 0 0 7px grey;
    border-left-color: #5a078b;
    border-top-color: #b8beb8;
    border-right-color: #b8beb8;
    border-bottom-color: #b8beb8;
    text-align: center;
    box-sizing: border-box;
}


/*-----------------
  18. Responsive
-----------------------*/

@media screen and (max-width: 1200px) {
	.profile_wrap {
		width: 320px !important;
	}
	.status_content_h3 {
		font-size: 24px;
	}
	.sidebar-group.mobile-open {
		opacity: 1;
		right: 0;
		visibility: visible;
	}
	.right-sidebar {
		margin-right: -320px;
	}
	.right-sidebar.video-right-sidebar {
		margin-right: -400px;
	}
	.login-left,
	.login-right {
		width: 50%;
	}
	.account-content {
		max-width: 300px;
	}
	.settings-tab .nav-tabs .nav-link {
		margin-right: 20px;
	}
}

@media screen and (max-width: 991.98px) {
	body {
		font-size: 13px;
		padding-left: 65px;
	}
	.main-wrapper .content {
		flex-flow: column wrap;
	}
	.main-wrapper .content .sidebar-group {
		width: 100%;
	}
	.right-sidebar {
		display: none;
		width: 100%;
	}
	.main-wrapper .content .sidebar-group .sidebar {
		width: 100%;
	}
	.right-sidebar .sidebar-body .pl-4,
	.right-sidebar .sidebar-body .pr-4 {
		padding: 0 !important;
	}
	.sidebar-body {
		padding: 10px 15px;
	}
	.header {
		padding: 15px 15px;
		height: 130px;
		overflow: initial;
		background-color: #650681;
		background-image: none;
	}
	.header-top {
		margin-bottom: 20px;
	}
	.header_button {
		bottom: -18px;
	}
	.nav.nav-tabs.chat-tabs .nav-item .nav-link,
	.nav.nav-tabs.chat-tabs .nav-item .nav-link.active {
		font-size: 14px;
		padding-right: 8px;
	}
	.chat .chat-header {
		padding: 15px 15px 10px;
	}
	.chat .chat-body {
		padding: 20px 15px 58px;
	}
	.chat .chat-body .messages .chats {
		margin-bottom: 1rem;
	}
	.status_update {
		display: none;
	}
	.chat .chat-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 10px 15px;
		height: 58px;
		top: inherit;
		padding-left: 75px;
	}
	.contact_profile_icon {
		top: 44px;
		left: 50px;
	}
	.right-sidebar {
		width: 100%;
		padding-right: 0;
	}
	body .sidebar-group .sidebar .list-group-item .users-list-body h5 {
		font-size: 16px;
	}
	.chat .chat-body .messages .chats {
		max-width: 100%;
	}
	.account-page .container-fluid {
		height: 100%;
	}
	.account-page .container-fluid > div {
		align-items: center;
		-webkit-align-items: center;
		height: 100%;
	}
	.left-sidebar.hide-left-sidebar {
		margin-left: -991px;
	}
	.chat.show-chatbar {
		display: block;
		margin-left: 0;
	}
	.chat.hide-chatbar {
		margin-left: -991px;
		display: none;
	}
	.chat.star-chat-group {
		margin-left: 0;
		display: block;
	}
	.right-sidebar.hide-right-sidebar {
		margin-right: -991px;
	}
	.right-sidebar.show-right-sidebar {
		display: block;
		padding-right: 20px;
	}
	.right_side_star.hide-right-sidebar {
		margin-right: -991px;
	}
	.right_side_star.show-right-sidebar {
		display: block;
		padding-right: 20px;
		margin-right: 0;
	}
	.sidebar-menu {
		width: 65px;
		padding: 20px 10px;
	}
	.settings-option {
		display: none;
	}
	.mob-scrn {
		height: 100% !important;
	}
	.sidebar-group.left-sidebar {
		height: 100%;
	}
}

@media screen and (max-width: 1400px) {
	.chat-menus {
		margin-top: 25px;
	}
	.chat-menus ul li+li {
		margin-top: 10px;
	}
	.bottom-menus ul li+li {
		margin-top: 10px;
	}
}

@media screen and (max-width: 1199px) {
	.chat-menus {
		margin-top: 15px;
	}
	.chat-menus ul li+li {
		margin-top: 5px;
	}
	.bottom-menus ul li+li {
		margin-top: 5px;
	}
	.chat-menus ul li a {
		width: 38px;
		height: 38px;
	}
	.bottom-menus .avatar {
		width: 38px;
		height: 38px;
	}
	.menus-col {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.chat.status-chat-footer .chat-footer {
		position: absolute;
	}
}

@media screen and (max-width: 767.98px) {
	body {
		padding-left: 0;
	}
	.left-chat-title {
		padding: 10px 15px;
	}
	.main-wrapper {
		overflow: auto;
	}
	.login-right {
		margin-bottom: 40px;
	}
	.success-pass {
		margin-bottom: 24px;
	}
	.search_chat {
		margin-left: 15px;
		margin-right: 15px;
	}
	.chat .chat-header {
		display: flex;
	}
	.chat .mob-auto {
		max-width: 25%;
	}
	.contact-close_call a.close_profile:hover{
		background-color: #F5F5F5;
		border-color: #F5F5F5;
	}	
	.avatar-group {
		display: none;
	}
	.status-title {
		margin-left: 0 !important;
	}
	.status-modal .modal-dialog {
		padding: 0;
		max-width: 100%;
	}
	.send-btn_status {
		width: 48px;
		height: 48px;
		margin-left: 8px;
		font-size: 20px;
	}
	.status-modal .modal-dialog .modal-content .inner-popup {
		padding: 0 10px;
	}
	.missed-call-widget .card-body,
	.other-call-widget .card-body {
		padding: 30px 8px;
	}
	.avatar-group.group-col {
		padding-top: 0;
		padding-left: 15px;
	}
	.missed-call-widget .card-body h6,
	.other-call-widget .card-body h6 {
		font-size: 13px;
	}
	.sidebar-menu {
		width: 100%;
		top: inherit;
		bottom: 0;
		box-shadow: 0px 0px 5px 2px rgba(66, 11, 161, 0.2);
		padding: 10px 15px;
	}
	.sidebar-menu .logo-col {
		display: none;
	}
	.menus-col {
		height: auto;
		justify-content: space-between;
		-webkit-justify-content: space-between;
	}
	.chat-menus {
		margin-top: 0;
	}
	.chat-menus ul li a span,
	.bottom-menus ul > li > a > span {
		display: none;
	}
	.chat-menus ul li a span.material-icons,
	.bottom-menus ul li a span.material-icons {
		display: block;
	}
	.chat-menus ul li {
		display: inline-block;
	}
	.chat-menus ul li+li,
	.bottom-menus ul li+li {
		margin-left: 10px;
		margin-top: 0;
	}
	.chat-menus ul li a {
		width: 32px;
		height: 32px;
	}
	.chat-menus ul li a img {
		width: 18px;
	}
	.bottom-menus ul li {
		display: none;
	}
	.bottom-menus ul li:last-child {
		display: flex;
		display: -webkit-flex;
	}
	.bottom-menus {
		width: auto;
		display: block;
	}
	.bottom-menus .chat-profile-icon {
		width: 32px;
		height: 32px;
	}
	.bottom-menus .avatar {
		width: 32px;
		height: 32px;
	}
	.bottom-menus ul li {
		float: left;
	}
	.sidebar-body {
		padding-bottom: 60px;
	}
	.chat .chat-body {
		padding: 20px 15px 120px;
	}
	.chat .chat-footer {
		padding-left: 0;
		padding-right: 0;
	}
	.right-sidebar.video-right-sidebar .chat .chat-footer {
		bottom: 48px;
	}
	.chat .chat-body .messages .chat-profile-name h6 {
		font-size: 14px;
	}
	.right-sidebar.show-right-sidebar {
		padding: 0px;
	}
	.report-col {
		margin-top: 15px;
	}
	.chat .chat-body .messages .download-col ul {
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
	}
	.chat-menus ul li.un-reach {
		display: none;
	}
	.chat-menus ul {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.profile-cover-avatar {
		width: 100px;
		height: 100px;
	}
	.avatar-edit {
		width: 38px;
		height: 38px;
	}
	.info-text {
		font-size: 15px;
	}
	.mute-chat-btn .btn {
		font-size: 13px;
		width: 100%;
	}
	.mute-chat-btn {
		flex-wrap: wrap;
	}
	.mute-chat-btn .btn-secondary {
		margin-right: 0;
		margin-bottom: 10px;
	}
	.chat .chat-body .messages .chats .message-content.award-link a {
		word-break: break-all;
	}
	.chat .chat-body .messages .file-download {
		flex-wrap: wrap;
	}
	.chat .chat-body .messages .chats .message-content.award-link img {
		width: 100%;
	}
	.personal-info ul li {
		display: block;
	}
	.personal-info ul li h6 {
		width: 100%;
		margin-bottom: 10px;
	}
	.chat .chat-header .user-details {
		margin-bottom: 10px;
	}
	.chat .chat-header .chat-options ul {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.right-sidebar .right-sidebar-wrap {
		border: 0;
	}
	.chat.video-screen .chat-body {
		margin-bottom: 70px;
	}
	.video-call-action ul li a {
		width: 30px;
		height: 30px;
	}
	.call-user-avatar {
		width: 90px;
		height: 90px;
		position: absolute;
		right: 20px;
		bottom: 70px;
	}
	.audio-call-group .avatar {
		width: 95px;
		height: 95px;
	}
	.recession-meet-blk {
		display: block;
	}
	.recession-meet-blk .reccession-head {
		margin-bottom: 15px;
	}
	.join-video.single-user {
		min-height: auto;
	}
	.part-name {
		bottom: 10px;
		left: 10px;
		padding: 5px 10px;
	}
	.part-name h4 {
		font-size: 12px;
	}
	.more-icon {
		position: absolute;
		top: 10px;
		right: 10px;
	}
	.more-icon a {
		width: 25px;
		height: 25px;
	}
	.join-grid {
		padding-bottom: 60px;
	}
	.register-wrapper .login-inbox .register-auth {
		padding: 50px 30px;
	}
	.user-status-group {
		padding: 15px;
	}
	.user-stories-box .inner-popup .carousel-inner .carousel-item img {
		height: 100vh;
	}
	.search_chat {
		margin-left: 0px;
		margin-right: 0px;
	}
	.chat.status-chat-footer .chat-footer {
		position: absolute;
		bottom: 10px;
	}
	.chat.chat-contact .chat-header .avatar {
		width: 60px;
		height: 60px;
	}
	.login-wrapper .login-inbox {
		min-width: 100%;
	}
}

@media screen and (max-width: 575.98px) {
	.chat-menus ul li+li,
	.bottom-menus ul li+li {
		margin-left: 7px;
	}
	.otp-box .digit-group input {
		margin: 0 7px;
	}
	.login-wrapper .login-inbox .log-auth .login-auth-wrap {
        display: block;
		margin-bottom: 24px;
	}
	.form-remember {
		display: block !important;
	}
	.chat .chat-header {
		display: block;
	}
	.form-remember .form-check {
		margin-bottom: 14px;
	}
	.login-wrapper .login-inbox .social-login li + li {
		margin-top: 12px;
		margin-left: 0;
	}
	.login-wrapper .login-inbox .social-login {
		display: block !important;
		margin-bottom: 24px	;
	}
	.login-wrapper .login-inbox .log-auth .login-auth-wrap .login-content-head p {
		font-size: 14px;
	}
	.login-wrapper .login-inbox .log-auth .login-auth-wrap .login-content-head h3 {
		font-size: 20px;
		text-align: center;
	}
	
	.manage-wrapper-histry {
		margin-bottom: 14px;
	}
	.set-manage-pri {
		display: inline-flex !important;
	}		
	.connectet-content p {
		font-size: 12px;
	}
	.connectetappscontent .btn-primary {
		margin-right: 55px;
	}
	.chat .chat-footer form {
		padding: 10px;
		background-color: #F5F5F5;
		border-radius: 10px;
	}
	.chat .chat-footer form .smile-foot {
		margin-right: 5px;
	}
	.chat .chat-footer form .form-buttons .btn.send-btn {
		margin-left: 8px;
	}
	.left-sidebar.hide-left-sidebar .sidebar-menu {
		display: none;
	}

}
@media screen and (max-width: 400.98px) {
	.setting-device-details {
		display: block;
	}
	.setting-connect {
		display: block;
	}	
	.setting-connect-img {
		margin-bottom: 14px;
	}
	.connectetappscontent {
		justify-content: center;
	}
}

.chat-line-disable:before {
    content: unset;
}

.chat-date-disable {
    background: transparent !important;
    border: unset !important;
	color: #9E9E9E;
}

.darkmode .chat-date-disable {
    background: transparent !important;
    border: unset !important;
	color: #9E9E9E;
}

.selected-user-list {
    background-color: blanchedalmond;
    padding: 7px;
    border-radius: 15px;
	margin-right:5px ;
}

.selected-user-list {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
    color: #0A0A0A;
}

.replay-forms-attachement {
    width: 100%;
	flex: 0 1 auto;
    min-width: 0;
    overflow-x: scroll;
    white-space: nowrap;
}

.file-box{
	position: relative;
    z-index: var(--layer-1);
    box-sizing: border-box;
    display: inline-block;
    flex: none;
    margin: 8px 5px 10px 5px;
    overflow: hidden;
    vertical-align: middle;
    border: 1px solid var(--media-editor-thumb-border);
    border-radius: 5px;
}

.clear-file-box{
	position: absolute;
    top: 1px;
    right: 1px;
    z-index: 100;
    /* color: #e9edef; */
    opacity: 0;
    transition: right 75ms ease-out, top 75ms ease-out;
}

.file-box-bg{
	overflow: hidden;
    /* background-color: #101a20; */
}

.file-box-view{
	position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    width: 52px;
    height: 52px;
}

.file-box-view img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}

.file-box-view::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
}

.file-box:hover {
    cursor: pointer;
}

.file-box.file-box-active {
    margin: 6px 3px 8px 3px;
    border: 3px solid #00a884;
}

.file-box:hover .clear-file-box {
    opacity: 1;
}

.attachment-list-element{
	overflow: hidden;
    background-color: var(--panel-background-deeper);
}

.attachment-list-file {
	border: 3px solid #00a884;
	position: relative;
    top: 0;
    left: 0;
    z-index: 2;
    width: 52px;
    height: 52px;
}


.image-preview {

	text-align: center;
	display: flex;
	justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    overflow: hidden;
    margin: auto;
	width: auto; /* Adjust as needed */
	height: calc(100vh - 255px) !important; /* calc(100vh - 65px -170px) !important; */
}
.image-preview img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
}

.image-preview .bx.bxs-file {
   font-size: 30px;
}

.file-box-view .bx {
	font-size: 30px;
 }

 .file-box-view div{
	height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .file-box-plus {
	border: 1px solid #d1d7db;
 }

 .file-box-plus .bx {
	color: #000;
 }

.file-select-summery {
    /* display: flex; */
    /* display: -webkit-flex; */
    align-items: flex-end;
    -webkit-align-items: end;
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: #F5F5F5;
    height: calc(100vh - 65px) !important;
	z-index: 99;
}




.contact-close_file {
    position: absolute;
    left: 20px;
    top: 20px;
}

.contact-close_file .close-replay i.bx.bx-x {
    font-size: 24px;
}

.form-buttons .send-btn{
	padding: 0;
	width: 40px;
    height: 40px;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #fff !important;
    background-color: #680A83;
    margin-left: 10px;
}

.form-buttons .send-btn .btn:last-child {
    margin-right: 0;
}